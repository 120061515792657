import React, {Component} from 'react';
import {Row, Col} from "react-bootstrap";
import Util from "../../util/Util";
import {Action, Guest, PollType} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';
import "./pollTeamRadar.scss";
import TeamRadar from "../teamRadar/TeamRadar";
const moment = require('moment');

type Props = {
    locale: any,
    poll: PollType | null;
    user: Guest | null;
    room: string | null;
    guestList: any,
}
type State = {}

class PollTeamRadar extends Component<Props, State> {

    state: State = {};

    submitVoteRadar = (key: any, vote: any)=> {
        if(this.props.poll !== null && this.props.user !== null){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.poll.config.id}/votes/${this.props.user.id}`).update({
                    avatar: this.props.user.avatar,
                    name: this.props.user.name,
                    timestamp: moment().valueOf()
                }
            ).then(()=>{
                if(this.props.poll !== null && this.props.user !== null){
                    firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.poll.config.id}/votes/${this.props.user.id}/vote/${key}`).update(vote
                    ).then();
                }
            });
        }
    };

    render() {
        const {
            props: {poll, locale, user, guestList, room}
        } = this;

        if(!poll || (poll && poll.config.selectedDeckName !== "TEAM_RADAR") || !user){
            return null;
        }

       /* const userHasVoted =
            poll.votes && user.id &&
            poll.votes[user.id] &&
            poll.votes[user.id].vote &&
            Array.isArray(poll.votes[user.id].vote) &&
            poll.votes[user.id].vote.filter((v: any) => v !== null).length > 0;*/
        let hisVoteRadar = Util.findNestedValue(poll,"votes", user.id !== null ? user.id : "", "vote");
        return (
                <div className="poll-team-radar-wrapper">
                    <Row className = {"voting-radar-deck-wrapper"} noGutters>
                        {poll && poll.config.options.filter(o => o.checked && o.label !=="").map( (card: Action, index: number) =>
                            <div key={index} className={`voting-card ${(hisVoteRadar && hisVoteRadar[card.id] && hisVoteRadar[card.id].score > 0) ? "slider-voted":""}` } >
                                { card.label !== "" &&
                                    <label>
                                        {Util.decodeCharacters(card.label)}&nbsp;
                                        {(hisVoteRadar && hisVoteRadar[card.id] && hisVoteRadar[card.id].score > 0) ?
                                            <i style={{color: "#28a745", float: "right"}} className="fas fa-check-circle"/>:
                                            <i style={{color: "#fa5462", float: "right"}} className="fas fa-exclamation-circle"/>}
                                    </label>
                                }
                                <input
                                    type="range"
                                    min="0"
                                    max="5"
                                    value={hisVoteRadar && hisVoteRadar[card.id] ? hisVoteRadar[card.id].score : 0}
                                    onChange={(event: any) => this.submitVoteRadar(card.id,{label: card.label, sortValue: card.sortValue, score: event.target.value})}/>
                            </div>
                        )}
                    </Row>
                    <Row>
                        <Col>
                            <TeamRadar
                                user={user}
                                locale={locale}
                                room={room}
                                guestList={guestList}
                                poll={poll} />
                        </Col>
                    </Row>
                </div>
        );
    }
}

export default PollTeamRadar;
