import React, {Component} from 'react';
import GuestList from "../../shared/guestList/GuestList";
import {Container, Row, Col} from "react-bootstrap";
import Toolbar from "../../shared/toolbar/Toolbar";
import firebase from 'firebase/app';
import 'firebase/database';
import {Guest, LocaleType, SortBy} from "../../../types/Types";
import SideBar from "../../shared/sideBar/SideBar";
import VotingModal from "../../shared/voting/VotingModal";
import LinkSharing from "../../shared/linkSharing/LinkSharing";
import MoodModal from "../../shared/moodModal/MoodModal";
import NotificationCenter from "../../shared/notificationCenter/NotificationCenter";
import PollList from "../../shared/pollList/PollList";
const moment = require('moment');

type Props = {
    roomId: string | null;
    userId: string | null;
};

type State = {
    locale: LocaleType;
    user: Guest | null
    guestList: any,
    loading: boolean,
    sortedBy: SortBy,
    runningPoll: any | null,
    isGuestVotingModalShow: boolean,
    isLinkSharingModalShow: boolean,
    isMoodModalShow: boolean,
    moodTrend: Array<any>,
    timer: {isTimerRunning: boolean, totalSeconds: number, timeStamp: number} | null;
    isModalVotingShow: boolean;
    isSidebarOpen: boolean;
    showChat: boolean;
    roomName: string;
}

class Room extends Component<Props, State> {
    private moodSnapshotInterval: any;

    state: State ={
        locale: localStorage.getItem("teamprove.locale") as LocaleType || "EN",
        guestList: [],
        user: null,
        loading: true,
        sortedBy: localStorage.getItem("teamprove.sortRoomBy") as SortBy || "CREATION",
        runningPoll: null,
        isGuestVotingModalShow: false,
        isLinkSharingModalShow: false,
        isMoodModalShow: true,
        moodTrend: [],
        timer: null,
        isModalVotingShow: false,
        isSidebarOpen: false,
        showChat: false,
        roomName: ""
    };

    componentWillUnmount(): void {
        const self = this;
        clearInterval(self.moodSnapshotInterval);
    }

    removeUser = (room: string, user: string)=>{
        firebase.database().ref(`rooms/${room}/users/`)
            .update({[user]: null })
            .then(()=>
                window.location.href = `/`
            );
        firebase.analytics().logEvent("remove_user");
    };

    setLogIn = (room: string, user: string)=>{
        firebase.database().ref(`rooms/${room}/users/${user}`)
            .update({isLogin: true })
    };


    componentDidMount(): void {
        switch (this.state.locale) {
            case "DE": require('moment/locale/de');
                moment.locale('de');
                break;
            case "EN": require('moment/locale/en-sg');
                moment.locale('en-sg');
                break;
            case "ES": require('moment/locale/es');
                moment.locale('es');
                break;
        }
        const self = this;

        firebase.database().ref(`rooms/${this.props.roomId}/config`)
            .once("value").then((snapshot: any) => {
                if(snapshot && snapshot.val()){
                   this.setState({roomName: snapshot.val().room || this.props.roomId});
                }
            });

        firebase.database().ref(`rooms/${this.props.roomId}/users/${this.props.userId}/`)
            .on('value', (snapshot: any) => {
            if(!snapshot.val()){
                window.location.href = `/join/${this.props.roomId}`;
            }else if(!snapshot.val().isLogin && !snapshot.val().isAdmin){
                if(this.props.roomId && this.props.userId){
                    this.removeUser(this.props.roomId, this.props.userId)
                }

                if(snapshot.val().isRemovedByAdmin){
                    window.location.href = "/";
                }
            }else if(!snapshot.val().isLogin && snapshot.val().isAdmin){
                if(this.props.roomId && this.props.userId){
                    this.setLogIn(this.props.roomId, this.props.userId)
                }
            }

            let userData = snapshot.val() ? {
                ...snapshot.val(),
                id:this.props.userId,
            }: null;

            self.setState({user: userData, loading: false});

            if(snapshot.val().isAdmin && this.state.guestList.length === 0){
                this.setState({isLinkSharingModalShow: true});
            }
            if(snapshot.val().isAdmin){
                this.moodSnapshotInterval = setInterval(self.updateMoodSnapshot, 30000);
            }
        });

        firebase.database().ref(`rooms/${this.props.roomId}/users`).on('value', (snapshot: any) => {
            const usersKeys = snapshot && snapshot.val() ? Object.keys(snapshot.val()): [];
            const guestList = usersKeys.map((user)=> {

                if(this.state.user && user === this.state.user.id){
                    return {...snapshot.val()[user], id: user}
                }
                return {...snapshot.val()[user], id: user};

            });
            self.setState({guestList: guestList});
            if(snapshot && snapshot.val()  && Object.keys(snapshot.val()).length > 1 ){
                self.setState({isLinkSharingModalShow: false});
            }
        });

        firebase.database().ref(`rooms/${this.props.roomId}/polls`).on('child_added', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                if(snapshot.val().config.pollStatus === "RUNNING"){
                    this.setState({runningPoll: snapshot.val(), isGuestVotingModalShow: true })
                }
                if (snapshot.val().config.pollStatus === "FINISHED"){
                    this.setState({runningPoll: null})
                }
            }
        });

        firebase.database().ref(`rooms/${this.props.roomId}/polls`).on('child_changed', (snapshot: any) => {
            if(snapshot && snapshot.val() && this.state.runningPoll !== null){
                if(snapshot.val().config.id ===  this.state.runningPoll.config.id && snapshot.val().config.pollStatus !== "FINISHED" ){
                    this.setState({runningPoll: snapshot.val()})
                }

                if (snapshot.val().config.pollStatus === "FINISHED"){
                    this.setState({runningPoll: null})
                }
            }
        });

        firebase.database().ref(`rooms/${this.props.roomId}/mood`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                let moodTrend = snapshot.val();
                this.setState({moodTrend: Object.keys(moodTrend).map((m: any) => moodTrend[m])});
            }
        });


        firebase.database().ref(`rooms/${this.props.roomId}/config/timer`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                let timer = snapshot.val();
                this.setState({timer:  timer});
            }
        });
    }

    updateMoodSnapshot= ()=>{
        let totalUsers = this.state.guestList ? Object.keys(this.state.guestList).length: 0;
        if(totalUsers > 1){
            const roundUpTo = (roundTo: number ) => (x: number) => Math.ceil(x / roundTo) * roundTo;
            const roundUpToHalfMinute = roundUpTo(1000 * 30 );
            let guestList = this.state.guestList;
            let mood5 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "5").filter(m => m === true).length || 0 ;
            let mood4 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "4").filter(m => m === true).length || 0 ;
            let mood3 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "3").filter(m => m === true).length || 0 ;
            let mood2 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "2").filter(m => m === true).length || 0 ;
            let mood1 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "1").filter(m => m === true).length || 0 ;
            firebase.database()
                .ref(`rooms/${this.props.roomId}/mood/${roundUpToHalfMinute(moment().valueOf())}`)
                .update({moods: {"m1": mood1, "m2": mood2, "m3": mood3, "m4":mood4, "m5": mood5}, timestamp: roundUpToHalfMinute(moment().valueOf()), totalUsers: totalUsers}
                ).then();
        }
    };

    toggleVotingModal = ()=> {
        this.setState(prevState => ({
            isGuestVotingModalShow: !prevState.isGuestVotingModalShow
        }));
    };

    setLocale = (locale: LocaleType ) =>{
        this.setState( {locale: locale});
        localStorage.setItem("teamprove.locale", locale);
        switch (locale) {
            case "DE": require('moment/locale/de');
                moment.locale('de');
                break;
            case "EN": require('moment/locale/en-sg');
                moment.locale('en-sg');
                break;
            case "ES": require('moment/locale/es');
                moment.locale('es');
                break;
        }
    };

    setSortBy = (sortBy: SortBy) => {
        this.setState({sortedBy: sortBy});
        localStorage.setItem("teamprove.sortRoomBy", sortBy);
    };

    handleOnLinkSharingHide = ()=>{
        this.setState({isLinkSharingModalShow: false, isMoodModalShow: false});

    };

    toggleAdminVotingModal = () => {
        this.setState(prevState => ({isModalVotingShow: !prevState.isModalVotingShow}));
    };

    handleShowChat = (show: boolean)=>{
        this.setState({showChat: show})
    };

    render() {
        const {
            props:{roomId},
            state: {locale, user, guestList, sortedBy, isGuestVotingModalShow, isSidebarOpen,
                runningPoll, moodTrend, isLinkSharingModalShow, isMoodModalShow, timer, isModalVotingShow, showChat, roomName}
        } = this;

        if(!user || !roomId){
            return <div className={"team-loader"}
            >
                <img src={require('../../../assets/logoCondensed.svg').default} alt=""/>
            </div>;
        }

        return (
            <Container fluid className="app-wrapper h-100">
                <Toolbar
                    room={roomId}
                    user={user}
                    locale={locale}
                    setLocale={this.setLocale}
                    guestList={guestList}
                    moodTrend={moodTrend}
                    setSortBy={this.setSortBy}
                    sortedBy={sortedBy}
                    timer={timer}
                    roomName={roomName}
                />

                <Row style={{height: "calc(100% - 68px)"}}>
                    <Col className={"view-wrapper h-100"}>
                        <SideBar
                            room={roomId}
                            user={user}
                            locale={locale}
                            guestList={guestList}
                            poll={runningPoll}
                            showChat={this.handleShowChat}
                            toggleVotingModal={this.toggleAdminVotingModal}
                            notifyIsSideBarOpen={(isOpen: boolean)=> this.setState({isSidebarOpen: isOpen })}
                        />
                        <GuestList
                            room={roomId}
                            user={user}
                            locale={locale}
                            guestList={guestList}
                            sortedBy={sortedBy}
                            moodTrend={moodTrend}
                            isSidebarOpen={isSidebarOpen}
                            showChat={showChat}
                            toggleChat={this.handleShowChat}
                        />
                        <NotificationCenter room={roomId} user={user} locale={locale} guestList={guestList} toggleVotingModal={!user.isAdmin ?this.toggleVotingModal: this.toggleAdminVotingModal}/>
                    </Col>
                </Row>

                {!user.isAdmin && runningPoll !== null &&
                runningPoll.config.pollStatus === "RUNNING" &&
                <VotingModal
                    locale={locale}
                    show={isGuestVotingModalShow}
                    onHide={this.toggleVotingModal}
                    user={user}
                    poll={runningPoll}
                    guestList={guestList}
                    room={roomId}/>}

                {user.isAdmin && runningPoll ===  null &&
                    <LinkSharing
                        show={isLinkSharingModalShow}
                        locale={locale}
                        onHide={ this.handleOnLinkSharingHide }
                        room={roomId}
                        roomName={roomName}
                        user={user}/>
                }

                {(!user.isAdmin || (user.isAdmin && !user.mood && isLinkSharingModalShow === false))  && runningPoll === null &&
                    <MoodModal
                        onHide={()=>this.setState({isMoodModalShow: false})}
                        locale={locale}
                        show={isMoodModalShow}
                        user ={user}
                        room={roomId}/>
                }

                {   user.isAdmin && isModalVotingShow &&
                    <PollList
                        user={user}
                        room={roomId}
                        locale={locale}
                        show={true}
                        onHide={()=> this.setState({isModalVotingShow: false})}
                        guestList={guestList}
                        runningPoll={runningPoll}/>
                }
            </Container>
        );
    }
}

export default Room;
