import React, {Component} from "react"
import "./lineChart.scss";

type Props = {
    data: Array<any>,
    color: string,
    svgHeight: number,
    svgWidth: number,
    maxY: number,
    fill: string,
    chartType: "AREA" | "LINE",
    label: string
}

class LineChart extends Component<Props, {}> {

    getMinX = ()=> {
        const {data} = this.props;
        return data[0].x;
    };

    getMaxX= ()=> {
        const {data} = this.props;
        return data.length-1;
    };

    getMaxY= ()=> {
        return this.props.maxY
    };

    getSvgX = (x: any) => {
        const {svgWidth} = this.props;
        return (x / this.getMaxX() * svgWidth);
    };

    getSvgY = (y: any) => {
        const {svgHeight} = this.props;
        return svgHeight - (y / this.getMaxY() * svgHeight);
    };

     line = (pointA: any, pointB: any) => {
        const lengthX = pointB.x - pointA.x;
        const lengthY = pointB.y - pointA.y;
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX)
        }
    };

     controlPoint = (current: any, previous:any, next: any, reverse?: any) => {
        // When 'current' is the first or last point of the array
        // 'previous' or 'next' don't exist.
        // Replace with 'current'
        const p = previous || current;
        const n = next || current;
        // The smoothing ratio
        const smoothing = 0;
        // Properties of the opposed-line
        const o = this.line(p, n);
        // If is end-control-point, add PI to the angle to go backward
        const angle = o.angle + (reverse ? Math.PI : 0);
        const length = o.length * smoothing;
        // The control point position is relative to the current point
        const x = current.x + Math.cos(angle) * length;
        const y = current.y + Math.sin(angle) * length;
        return {x:x, y: y};
    };

    bezierCommand = (point: any, i:any, a:any) => {
        // start control point
        const cps = this.controlPoint(a[i - 1], a[i - 2], point);
        // end control point
        const cpe = this.controlPoint(point, a[i - 1], a[i + 1], true);
        return `C ${this.getSvgX(cps.x)},${this.getSvgY(cps.y)} ${this.getSvgX(cpe.x)},${this.getSvgY(cpe.y)} ${this.getSvgX(point.x)},${this.getSvgY(point.y)}`
    };

    render() {
        const {data, color, fill, chartType } = this.props;

        if(data.length === 0){
            return null;
        }
        let d = data.reduce((acc, point, i, a) => i === 0
            ? `M ${this.getSvgX(0)},${this.getSvgY(0)}`
            : `${acc} ${this.bezierCommand(point, i, a)}`
            , '');

        if(chartType === "AREA") {
            d += `L ${this.getSvgX(data[data.length - 1].x)} ${this.getSvgY(0)+1} `;
            d += `L ${this.getSvgX(0)} ${this.getSvgY(0)+1}`
        }
        return <path d={d}  style={{
            stroke: color,
            fill: fill,
            strokeDasharray: chartType === "AREA"? "*" : "4 4"}}
        />
    }
}

export default LineChart;
