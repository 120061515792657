import React, {Component} from 'react';
import {Button, Col, OverlayTrigger, Row} from "react-bootstrap";
import {Popover} from "react-bootstrap";
import {Guest, LocaleType} from "../../../types/Types";
import Translator from "../../util/Locale";
import "./moodWidget.scss";

type Props = {
    locale: LocaleType
    onMoodChange: Function;
    user: Guest;
};

type State = {

};

const moods = [
    {
        value: '5.svg',
        image: { avatar: true, src: require('../../../assets/mood/5.svg').default },
    },
    {
        value: '4.svg',
        image: { avatar: true, src: require('../../../assets/mood/4.svg').default },
    },
    {
        value: '3.svg',
        image: { avatar: true, src: require('../../../assets/mood/3.svg').default },
    },
    {
        value: '2.svg',
        image: { avatar: true, src: require('../../../assets/mood/2.svg').default },
    },
    {
        value: '1.svg',
        image: { avatar: true, src: require('../../../assets/mood/1.svg').default },
    },
];

class MoodWidget extends Component<Props, State> {
    render() {

        const {
            props: {locale, onMoodChange, user}
        } = this;

        return (
            <>
                <div className={"mood-wrapper d-none d-md-block d-lg-block d-xl-block"}>
                    <Row noGutters>
                        <Col className={"mood-options no-padding"}>
                            {moods.map((mood: any, index: number) =>
                                <div key={index}
                                     className={`mood-item ${mood.value === user.mood ? "active" : ""}`}
                                     onClick={() => onMoodChange(mood.value)}>
                                    <img src={mood.image.src} alt="" draggable={false}/>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row noGutters>
                        <Col className="mood-label">
                            {Translator.translate("How are you feeling?", locale, "TOOLBAR")}
                        </Col>
                    </Row>
                </div>
                <div className={"mood-over-layer-wrapper d-block d-sm-block d-md-none"}>
                    <OverlayTrigger
                        trigger="click"
                        placement={"bottom"}
                        rootClose={true}
                        rootCloseEvent={'mousedown'}
                        overlay={
                            <Popover
                                id={`popover-positioned-bottom`}
                                className={"timer-controls"} >
                                <Popover.Title as="h3">{Translator.translate("How are you feeling?", locale, "TOOLBAR")}</Popover.Title>
                                <Popover.Content>
                                    <div className={"popup-mood-wrapper"}>
                                        <Row noGutters>
                                            <Col className={"mood-options no-padding"}>
                                                {moods.map((mood: any, index: number) =>
                                                    <div key={index}
                                                         className={`mood-item ${mood.value === user.mood ? "active" : ""}`}
                                                         onClick={() => onMoodChange(mood.value)}>
                                                        <img src={mood.image.src} alt="" draggable={false}/>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <Button className={`mood-btn ${!user.mood ? "no-mood" : ""}`}>
                            { user.mood &&
                                <img src={require(`../../../assets/mood/${user.mood}`).default} alt="" draggable={false}/>
                            }
                            {
                                !user.mood &&
                                <>
                                    <i className="fas fa-meh-blank"/>
                                    < i className="fas fa-question"/>
                                </>
                            }
                        </Button>
                    </OverlayTrigger>
                </div>
            </>
        );
    }
}

export default MoodWidget;
