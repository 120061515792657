import React, {Component} from 'react';
import {Guest, LocaleType, PollType} from "../../../types/Types";
import './sideBar.scss';
import Deck from "../deck/Deck";
import {Col, Row} from "react-bootstrap";
import Translator from "../../util/Locale";
import firebase from 'firebase/app';
import 'firebase/database';
import ReactTooltip from 'react-tooltip';

type Props = {
    room: string | null;
    locale: LocaleType;
    user: Guest;
    guestList: Array<Guest>;
    poll: PollType | null;
    toggleVotingModal: Function;
    notifyIsSideBarOpen: Function;
    showChat: Function;
}

type State = {
    isCollapsed: boolean;
}

class SideBar extends Component<Props, State> {
    state: State = {
        isCollapsed: true,
    };

    handleHamburgerToggle = () => {
        this.setState(prevState => ({isCollapsed: !prevState.isCollapsed}
        ), () => this.props.notifyIsSideBarOpen(this.state.isCollapsed));
    };

    toggleSecretCards = () => {
        if (this.props.user.isAdmin) {
            Object.keys(this.props.guestList).forEach((participant: any) => {
                let id = this.props.guestList[participant].id;
                firebase.database().ref(`rooms/${this.props.room}/users/${id}`).update({isCardSecret: !this.props.user.isCardSecret}).then();
            });
            firebase.analytics().logEvent("hide_active-cards");
        }
    };

    removeAllCards = () => {
        if (this.props.user.isAdmin) {
            Object.keys(this.props.guestList).forEach((participant: any) => {
                let id = this.props.guestList[participant].id;
                firebase.database().ref(`rooms/${this.props.room}/users/${id}`).update({activeCard: null}).then();
            });
            firebase.analytics().logEvent("remove_all_cards");
        }
    };

    handleShowChat =() => {
        this.props.showChat(true);
    };

    render() {
        const {
            props: {room, locale, user},
            state: {isCollapsed}
        } = this;

        return (
            <div className={`sidebar-wrapper ${!isCollapsed ? "open" : "closed"}`}>
                <div className="sidebar-header">
                    <div onClick={this.handleHamburgerToggle}
                         className={`hamburger-toggle ${!isCollapsed ? "closed" : ""}`}>
                        <span className="icon"/>
                    </div>
                </div>

                <Row noGutters className="sidebar-actions">

                    <div className={`owner-action-item ${isCollapsed ? "condensed" : ""}`}
                         onClick={this.handleShowChat}>
                        {!isCollapsed &&
                        <>
                            <img src={require("../../../assets/cards/send.svg").default} alt=""/>
                            <span className="label">
                                {Translator.translate("Send message", locale, "SIDEBAR")}
                            </span>
                        </>}

                        {isCollapsed &&
                        <>
                            <img src={require("../../../assets/cards/send.svg").default} alt=""
                                 data-tip={Translator.translate("Send message", locale, "SIDEBAR")}
                            />
                            <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"}/>
                        </>
                        }
                    </div>


                    {user.isAdmin &&
                        <div className={`owner-action-item ${isCollapsed ? "condensed" : ""}`}
                             onClick={this.removeAllCards}>
                            {!isCollapsed &&
                                <>
                                    <img src={require("../../../assets/cards/clean.svg").default} alt=""/>
                                    <span className="label">
                                        {Translator.translate("Clear all cards", locale, "TOOLBAR")}
                                    </span>
                                </>
                            }

                            {isCollapsed &&
                                <>
                                    <img src={require("../../../assets/cards/clean.svg").default}
                                         alt=""
                                         data-tip={Translator.translate("Clear all cards", locale, "TOOLBAR")}
                                    />
                                    <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"}/>
                                </>
                            }
                        </div>

                    }
                    {user.isAdmin &&
                        <div className={`owner-action-item ${isCollapsed ? "condensed" : ""}`}
                             onClick={() => this.props.toggleVotingModal()}>
                            {!isCollapsed &&
                                <>
                                    <img src={require("../../../assets/cards/voting.svg").default} alt=""/>
                                    <span className="label">
                                        {Translator.translate("Create a poll", locale, "VOTING")}
                                    </span>
                                </>
                            }

                            {isCollapsed &&
                                <>
                                    <img src={require("../../../assets/cards/voting.svg").default} alt=""
                                         data-tip={Translator.translate("Create a poll", locale, "VOTING")}
                                    />
                                    <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"}/>
                                </>
                            }
                        </div>
                    }

                    {user.isAdmin &&
                    <div className={`owner-action-item ${isCollapsed ? "condensed" : " "}`}
                         onClick={this.toggleSecretCards}>
                        {!user.isCardSecret && !isCollapsed &&
                            <>
                                <img src={require("../../../assets/cards/exposed.svg").default} alt=""/>
                                <span className={"label"}>
                                    {Translator.translate("All cards are visible", locale, "TOOLBAR")}
                                </span>
                            </>
                        }
                        {user.isCardSecret && !isCollapsed &&
                            <>
                                <img src={require("../../../assets/cards/incognito.svg").default} alt=""/>
                                <span className={"label"}>
                                    {Translator.translate("All cards are hidden", locale, "TOOLBAR")}
                                </span>
                            </>
                        }

                        {!user.isCardSecret && isCollapsed &&
                            <>
                                <img src={require("../../../assets/cards/exposed.svg").default} alt=""
                                     data-tip={Translator.translate("All cards are visible", locale, "TOOLBAR")}
                                />
                                <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"}/>
                            </>
                        }
                        {user.isCardSecret && isCollapsed &&
                            <>
                                <img src={require("../../../assets/cards/incognito.svg").default} alt="" className={"active"}
                                     data-tip={Translator.translate("All cards are hidden", locale, "TOOLBAR")}
                                />
                                <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"}/>
                            </>
                        }
                    </div>
                    }

                    <Col>
                        <Deck room={room} user={user} locale={locale} isCollapsed={isCollapsed}/>
                    </Col>
                </Row>
               {/* <InfoSharingModal
                    locale={locale}
                    user={user}
                    room={room}
                    show={isModalMessageShow}
                    onHide={() => this.setState({isModalMessageShow: false})}/>*/}
            </div>
        );
    }
}

export default SideBar;
