import React, {Component} from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {SidebarContent, SidebarFooter, SidebarHeader} from "react-pro-sidebar/dist";
import "./aside.scss";
import {NavLink} from "react-router-dom";

type Props = {
    collapsed: boolean,
    toggled: boolean,
    handleToggleSidebar: Function,
    handleCollapsedChange: Function,
    dashboard: string;
}

class Aside extends Component<Props, {}> {

    handleToggleSidebar = (value: any) => {
        this.props.handleToggleSidebar(value);
    };

    handleCollapseSidebar = ()=> {
        this.props.handleCollapsedChange(!this.props.collapsed);
    };

    render() {
        const {
            props:{ collapsed, toggled, dashboard}
        }= this;

        return (
            <ProSidebar
                collapsed={collapsed}
                breakPoint="md"
                toggled={toggled}
                onToggle={this.handleToggleSidebar}
            >
                <SidebarHeader>
                    <div
                        style={{
                            padding: '0 24px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            fontSize: 14,
                            letterSpacing: '1px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <img
                            src={require("../../../../assets/logoCondensed.svg").default}
                            alt=""/>
                            {!collapsed && <span>&nbsp;&nbsp;Teamprove</span>}
                    </div>
                </SidebarHeader>

                <SidebarContent>
                    <Menu iconShape="circle">
                        <SubMenu
                            title={"Dashboards"}
                            defaultOpen={true}
                            icon={<i className="fas fa-building"/>}>
                            <MenuItem icon={<i className="fas fa-person-booth"/>} active={(dashboard === "rooms")}>
                                Rooms
                                <NavLink to={"/admin/rooms"} />
                            </MenuItem>
                            <MenuItem icon={<i className="fas fa-comment-alt"/>} active={(dashboard === "feedback" )}>
                                Feedback
                                <NavLink to={"/admin/feedback"} />
                            </MenuItem>
                            <MenuItem icon={<i className="far fa-life-ring"/>} active={(dashboard === "recovery" )}>
                                Session Recovery
                                <NavLink to={"/admin/recovery"} />
                            </MenuItem>
                        </SubMenu>

                    </Menu>
                </SidebarContent>

                <SidebarFooter>
                    <div className="sidebar-btn-wrapper">
                        <span className="sidebar-btn" onClick={this.handleCollapseSidebar}>
                            <i className="fas fa-chevron-left"/>
                        </span>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        );
    }
}

export default Aside;
