import React, {Component, useContext} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import {
    Container,
    Button,
    Form,
    Navbar,
    DropdownButton,
    ButtonGroup,
    Dropdown,
    Nav,
    Alert,
    InputGroup, Accordion, Card, AccordionContext
} from "react-bootstrap";
import Translator from "../../util/Locale";
import "./login.scss";
import {LocaleType} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';
import * as moment from 'moment';
import Util from "../../util/Util";
import InfoBanner from "./InfoBanner";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';


type Props = {
    setCredentials: Function
    room: string;
};
type State = {
    user: string;
    room: string;
    avatar: string;
    isInvalidRoom: boolean;
    isInvalidUser: boolean;
    locale: LocaleType;
    isAdmin: boolean | null;
    loading: boolean;
    email: string;
    isNewsletterChecked: boolean;
    contact: string;
    recoveryMessage: string;
    isRecoverySent: boolean;
};

let browserLocale = navigator.language.toUpperCase().substr(0, 2);
let locale = (browserLocale === "DE" || browserLocale === "EN" || browserLocale === "ES") ? browserLocale : "EN";

function CustomToggle({ children, eventKey, callback }: any) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
    return (
        <span
            style={{cursor: "pointer"}}
            onClick={decoratedOnClick}>
            {children}
            <span className={"float-right"}>
                {isCurrentEventKey ? <i className="fas fa-chevron-up"/>: <i className="fas fa-chevron-down"/>}
            </span>
        </span>
    );
}

export default class Login extends Component<Props, State> {
    state: State = {
        user: "",
        room: (this.props.room && this.props.room.trim().length >= 10) ? Util.decodeCharacters(this.props.room) : "",
        avatar: localStorage.getItem("teamprove.avatar") || "1.svg",
        isInvalidUser: false,
        locale: localStorage.getItem("teamprove.locale") as LocaleType || locale,
        isAdmin: null,
        isInvalidRoom: false,
        loading: false,
        email: "",
        isNewsletterChecked: false,
        contact: "",
        recoveryMessage: "",
        isRecoverySent: false
    };

    sendSessionRecoveryRequest = () => {
        const showFormAgain = () => setTimeout( ()=> {
            this.setState({isRecoverySent: false})
        }, 5550);


        if(this.state.contact !== ""){
            firebase.database().ref(`recovery/`).push({
                contact: Util.encodeCharacters(this.state.contact),
                recoveryMessage: Util.encodeCharacters(this.state.recoveryMessage),
                timestamp: moment.now()
            }).then(()=>{
                this.setState({
                    contact: "",
                    recoveryMessage: "",
                    isRecoverySent: true,
                },showFormAgain)
            })
        }
    };

    isNewSession = () => {
        const room = Util.encodeCharacters(this.state.room);
        const user = Util.encodeCharacters(this.state.user);
        if (room !== "") {
            //Check if room already exists
            firebase.database().ref(`rooms/${room}`).once("value").then(
                (snapshotRoom: any) => {
                    //if room does not exit then he is admin
                    if (snapshotRoom && snapshotRoom.val() === null) {
                        this.setState({isAdmin: true});
                    } else {
                        //if room exist
                        firebase.database().ref(`rooms/${room}/users/${user}`).once("value").then(
                            (snapshotUser) => {
                                //if room and user already exist then get their admin credential
                                if (snapshotUser && snapshotUser.val()) {
                                    this.setState({isAdmin: snapshotUser.val().isAdmin});
                                } else {
                                    //if room exists but user is new then he is not admin
                                    this.setState({isAdmin: null});
                                }
                            }
                        )
                    }
                }
            );
        }
    };

    handleUpdateEmail= (event: any) => {
        const {value} = event.target;
        this.setState({email: value.trim()});
    };

    handleUpdateUserName = (event: any) => {
        const {value} = event.target;
        if (value !== this.state.user) {
            this.setState({user: value, isInvalidUser: false});
        }
    };

    handleUpdateContact  = (event: any) => {
        const {value} = event.target;
        this.setState({contact: value});
    };

    handleOnRecoveryMessageChange = (e: any)=> {
        let recoveryMessage = e.target.value;
        this.setState({recoveryMessage: recoveryMessage});
    };

    findPattern = (word: string): string => {
        //http://localhost:3000/sharing/1234567890
        //https://meet.teamprove.de/sharing/0123456789
        //https://meets-4a28a.web.app/sharing/1234567890
        const url = new URL(window.location.href);
        let keys = word
            .replace("https://", "")
            .replace("http://", "").split("/");

        if(keys.length !== 3){ return word; }
        if(keys.length === 3 && keys[0].replace(":3000", "") === url.hostname && keys[1] === "sharing"){
            return keys[2]? Util.decodeCharacters(keys[2]).replace(/%20/g, " ") : word
        }
        return word;
    };

    handleUpdateRoom = (event: any) => {
        const {value} = event.target;
        if (this.findPattern(value) !== this.state.room) {
            this.setState({room: this.findPattern(value), isInvalidRoom: false});
        }
    };

    handleRoomOnBlur = () => {
        if (this.state.room && this.state.room.trim() ==="") {
            this.setState({isInvalidRoom: true});
        }
    };

    handleUserOnBlur = () => {
        if (this.state.user && this.state.user.trim() === "") {
            this.setState({isInvalidUser: true, user: this.state.user.trim()});
        }
        this.isNewSession();
    };

    handleKeyPressEnter =  (e: any) => {
        if(e.key === 'Enter' && this.state.room.length >= 10 && this.state.avatar !== "" && this.state.user !==""){
            this.handleLogIn();
        }
    };

    handleOnLocaleChange = (locale: LocaleType) => {
        this.setState({
            locale: locale
        }, () => localStorage.setItem("teamprove.locale", locale));
    };

    renderAvatar = () => {
        let avatar = [];
        for (let i = 0; i < 302; i++) {
            avatar.push(
                <div key={i}
                     className={`avatar-item ${this.state.avatar === `${i}.svg` ? "active" : ""}`}
                     onClick={() => this.setState({avatar: `${i}.svg`}, () => localStorage.setItem("teamprove.avatar", `${i}.svg`))}>
                    <img src={require(`../../../assets/avatar/${i}.svg`).default} alt=""/>
                </div>
            )
        }
        return avatar;
    };

    handleLogIn = () => {
        this.isNewSession();
        const self = this;
        const {locale, avatar} = self.state;
        const room = Util.encodeCharacters(this.state.room);
        const user = Util.encodeCharacters(this.state.user);
        firebase.database().ref(`rooms/`).push(
            {
                config: {
                    creator: user,
                    room: room,
                    timestamp: moment.now()
                },
            }
        ).then((snapRoom) => {
            firebase.database().ref(`rooms/${snapRoom.key}/users/`).push(
                {
                    name: user.trim(),
                    avatar: avatar,
                    isAdmin: true,
                    lastUpdate: moment.now(),
                    creation: moment.now(),
                    isLogin: true,
                }
            ).then((snap) => {
                firebase.analytics().logEvent("create_new_session");
                localStorage.setItem("teamprove.locale", locale);
                this.props.setCredentials(snapRoom.key, snap.key);
            });

        });
    };

    render() {
        const {
            state: {
                room,
                user,
                isInvalidRoom,
                isInvalidUser,
                locale,
                loading,
                email,
                isNewsletterChecked,
                recoveryMessage,
                isRecoverySent,
                contact},
        } = this;


        return loading ? <div className={"team-loader"}>
            <img src={require('../../../assets/logoCondensed.svg').default} alt=""/>
        </div> : (
            <Container
                fluid
                className="main-login-wrapper"
            >
                <Navbar className="login-navbar">
                    <Navbar.Brand href={"https://www.teamprove.de/"} target={"_blank"} rel="noopener noreferrer">
                        <img
                            alt=""
                            src={require("../../../assets/logoCondensed.svg").default}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Nav className="mr-auto">
                        <Nav.Link href="/home">Home</Nav.Link>
                    </Nav>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <div className={"share-fb"}  onClick={()=> window.open("https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmeet.teamprove.de%2F&amp;src=sdkpreparse", '_blank' )}>
                                <div
                                    className="fb-share-button"
                                    data-href="https://meet.teamprove.de/"
                                    data-layout="button_count"
                                    data-size="small">
                                    <a target="_blank"
                                       rel="noopener noreferrer"
                                       href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmeet.teamprove.de%2F&amp;src=sdkpreparse"
                                       className="fb-xfbml-parse-ignore">
                                        {Translator.translate("Share", locale, "LOGIN")}
                                    </a>
                                </div>
                            </div>
                        </Nav>
                        <Nav>
                            <a className={"share-twitter share-btn"} href="https://twitter.com/share?url=https://meet.teamprove.de/&amp;text=Teamprove meet - Better Remote Meetings #TeamproveMeet" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter"/>
                                <span className={"d-none d-sm-block"}>
                                    {Translator.translate("Share", locale, "LOGIN")}
                                </span>
                            </a>
                        </Nav>

                        <Nav>
                            <a target={"_blank"} rel="noopener noreferrer" className={"share-linkedin share-btn"} href={`https://www.linkedin.com/shareArticle?mini=true&url=${"https://meet.teamprove.de/"}&title=${"Teamprove meet - Better Remote Meetings"}`}>
                                <i className="fab fa-linkedin-in"/>
                                <span className={"d-none d-sm-block"}>
                                    {Translator.translate("Share", locale, "LOGIN")}
                                </span>
                            </a>
                        </Nav>
                        <Nav>
                            <DropdownButton
                                alignRight
                                as={ButtonGroup}
                                id={"locale"}
                                title={locale}
                                className={"btn-locale"}
                            >
                                <Dropdown.Item eventKey="1"
                                               onClick={() => this.handleOnLocaleChange("DE")}>
                                    <img className={"flag"}
                                         src={require(`../../../assets/flag/DE.svg`).default} alt=""/>
                                    {Translator.translate("German", locale, "LOGIN")}
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item eventKey="2"
                                               onClick={() => this.handleOnLocaleChange("EN")}>
                                    <img className={"flag"}
                                         src={require(`../../../assets/flag/EN.svg`).default} alt=""/>
                                    {Translator.translate("English", locale, "LOGIN")}
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item eventKey="3"
                                               onClick={() => this.handleOnLocaleChange("ES")}>
                                    <img className={"flag"}
                                         src={require(`../../../assets/flag/ES.svg`).default} alt=""/>
                                    {Translator.translate("Spanish", locale, "LOGIN")}
                                </Dropdown.Item>
                            </DropdownButton>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                <Row style={{minHeight: "700px",   margin: "auto", width: "100%"}}>
                    <Col style={{display: "flex"}}>
                        <Container style={{ margin: "auto"}}>
                            <Row style={{ minHeight: "100%", padding: "30px 0 15px"}}>
                                <Col className="flex">
                                    <div className="portlet-wrapper">
                                        <div className="login-portlet">
                                            <Row noGutters>
                                                <Col>
                                                    <div className="logo">
                                                        <img
                                                            src={require("../../../assets/logo.svg").default}
                                                            alt=""
                                                            className={"d-none d-sm-block"}
                                                        />
                                                        <img
                                                            src={require("../../../assets/logo.svg").default}
                                                            alt=""
                                                            style={{
                                                                width: "100%",
                                                                height: "35px",
                                                                objectFit: "contain"

                                                            }}
                                                            className={"d-block d-sm-none"}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <InfoBanner locale={locale}/>
                                            <Row>
                                                <Col>
                                                    <h4 style={{
                                                        textAlign: "center",
                                                        color: "#f39820",
                                                        marginTop: "15px"
                                                    }}>
                                                        {Translator.translate("Create a new session", locale, "LOGIN")}
                                                    </h4>
                                                    <hr/>
                                                </Col>
                                            </Row>

                                            <Row noGutters>
                                                <Col className="form-wrapper">
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Label column={false}>
                                                                <h5>
                                                                    {Translator.translate("Type in your name", locale, "LOGIN")}
                                                                </h5>
                                                            </Form.Label>
                                                            <FormControl
                                                                placeholder={Translator.translate("User", locale, "LOGIN")}
                                                                aria-label="Large"
                                                                aria-describedby="inputGroup-sizing-sm"
                                                                value={user}
                                                                required
                                                                name="session"
                                                                type="text"
                                                                onKeyUp={this.isNewSession}
                                                                onChange={this.handleUpdateUserName}
                                                                onBlur={this.handleUserOnBlur}
                                                                autoComplete="off"
                                                                isInvalid={isInvalidUser}
                                                            />
                                                            <Form.Text className="text-muted">
                                                            <span>
                                                                <span
                                                                    className={`float-right ${isInvalidUser ? "invalid" : ""}`}>
                                                                    {isInvalidUser && Translator.translate("Type in a valid user name", locale, "LOGIN")}
                                                                </span>
                                                            </span>
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label column={false}>
                                                                <h5>
                                                                    {Translator.translate("Your session name:", locale, "LOGIN")}
                                                                </h5>
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    placeholder={Translator.translate("Room", locale, "LOGIN")}
                                                                    value={room}
                                                                    required
                                                                    name="room"
                                                                    type="text"
                                                                    onKeyUp={this.isNewSession}
                                                                    onChange={this.handleUpdateRoom}
                                                                    onBlur={this.handleRoomOnBlur}
                                                                    onKeyPress={this.handleKeyPressEnter}
                                                                    isInvalid={isInvalidRoom}
                                                                    autoComplete="off"
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label column={false}>
                                                                <h5>
                                                                    {Translator.translate("Pick your avatar", locale, "LOGIN")}
                                                                </h5>
                                                            </Form.Label>
                                                            <div className="avatar-picker">
                                                                {this.renderAvatar()}
                                                            </div>
                                                        </Form.Group>
                                                        <Button
                                                            onClick={this.handleLogIn}
                                                            className="btn btn-primary float-right"
                                                            disabled={room.trim() === "" || user.trim() === ""}
                                                            id={"submit"}
                                                        >
                                                            <i className="fas fa-plus"/> {Translator.translate("Create session", locale, "LOGIN")}
                                                        </Button>
                                                        <div className="clearfix"/>
                                                        <Form.Group className={"float-right"}
                                                                    style={{margin: "0 !important"}}>
                                                            <Form.Text className="text-muted"
                                                                       style={{minHeight: "12px"}}>
                                                                {(room.trim() === "" || user.trim() === "") &&
                                                                <span>{Translator.translate("Fill in the form", locale, "LOGIN")}</span>
                                                                }
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className={"recovery-wrapper"}>
                                                <Col>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <CustomToggle eventKey="0">
                                                                    <i className="far fa-life-ring"/>&nbsp;&nbsp;&nbsp;
                                                                    {Translator.translate("Have you lost access to your session?", locale, "LOGIN")}
                                                                </CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    {!isRecoverySent &&
                                                                        <Form>
                                                                            <Form.Group>
                                                                                <Form.Label column={false}>
                                                                                    <h6>
                                                                                        {Translator.translate("Introduce your e-mail and we'll get back to you", locale, "LOGIN")}
                                                                                    </h6>
                                                                                </Form.Label>
                                                                                <InputGroup className="mb-3">
                                                                                    <FormControl
                                                                                        value={contact}
                                                                                        required
                                                                                        name="recoveryContact"
                                                                                        type="email"
                                                                                        placeholder={Translator.translate("e-mail address", locale, "LOGIN")}
                                                                                        onChange={this.handleUpdateContact}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                </InputGroup>
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    rows={3}
                                                                                    onChange={this.handleOnRecoveryMessageChange}
                                                                                    value={recoveryMessage}
                                                                                    placeholder={Translator.translate("Additional information...", locale, "LOGIN")}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group className={"custom-form-row"}>
                                                                                <Button
                                                                                    variant="primary"
                                                                                    disabled={ contact=== "" }
                                                                                    onClick={this.sendSessionRecoveryRequest}>
                                                                                    {Translator.translate("Recover Session", locale, "LOGIN")}
                                                                                </Button>
                                                                            </Form.Group>
                                                                        </Form>
                                                                    }

                                                                    {isRecoverySent &&
                                                                        <Row>
                                                                            <Col>

                                                                                <div className={"countDownWrapper"}>
                                                                                    <div className={"countDown"} />
                                                                                </div>
                                                                                <Alert variant={"success"} className={"alert-feedback"}>
                                                                                    <h5>
                                                                                        {Translator.translate("Your request has been submitted successfully!", locale, "FEEDBACK")}
                                                                                    </h5>
                                                                                </Alert>

                                                                            </Col>
                                                                        </Row>
                                                                    }


                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                </Col>
                                            </Row>
                                        </div>
                                        {locale === "DE" &&
                                            <div className="newsletter-wrapper">
                                                <Row>
                                                    <Col>
                                                        <Form
                                                            action="https://teamprove.us17.list-manage.com/subscribe/post?u=21ce644186132be410f9320bb&amp;id=eab9168407"
                                                            method="post" id="mc-embedded-subscribe-form"
                                                            name="mc-embedded-subscribe-form" className="validate"
                                                            target="_blank" noValidate>
                                                            <div id="mc_embed_signup_scroll">
                                                                <h3><i className="fas fa-envelope-open-text"/>
                                                                    &nbsp;&nbsp;{Translator.translate("newsletter", locale, "LOGIN")}
                                                                </h3>
                                                                <p>
                                                                    {Translator.translate("Get notified about the next update", locale, "LOGIN")}
                                                                </p>
                                                                <Form.Group>
                                                                    <FormControl
                                                                        aria-label="Large"
                                                                        placeholder={"Mail-Adresse"}
                                                                        aria-describedby="inputGroup-sizing-sm"
                                                                        value={email}
                                                                        required
                                                                        name="EMAIL"
                                                                        id="mce-EMAIL"
                                                                        type="email"
                                                                        onChange={this.handleUpdateEmail}
                                                                        autoComplete="off"
                                                                        isInvalid={isInvalidUser}
                                                                    />
                                                                </Form.Group>


                                                                <div id="mergeRow-gdpr" style={{maxHeight: (email !== "" ? "500px" : 0), opacity: (email !== "" ? "1" : "0"), padding: (email !== "" ? "15px" : "0 15px")}}
                                                                     className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
                                                                    <div className="content__gdpr">
                                                                        <label>{Translator.translate("teamprove-newsletter", locale, "LOGIN")}</label>
                                                                        <p>
                                                                            {Translator.translate("teamprove-terms", locale, "LOGIN")}
                                                                        </p>
                                                                        <fieldset
                                                                            className="mc_fieldset gdprRequired mc-field-group"
                                                                            name="interestgroup_field">
                                                                            <label className="checkbox subfield" htmlFor="gdpr_38044">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="gdpr_38044"
                                                                                    name="gdpr[38044]"
                                                                                    value="Y"
                                                                                    checked={isNewsletterChecked}
                                                                                    onChange={()=> this.setState(prevState => ({isNewsletterChecked: !prevState.isNewsletterChecked}))}
                                                                                    required
                                                                                    className="av-checkbox gdpr"/>
                                                                                <span>&nbsp;{Translator.translate("subscribe-check", locale, "LOGIN")}</span>
                                                                            </label>
                                                                        </fieldset>
                                                                    </div>
                                                                    <div className="content__gdprLegal">
                                                                        {Translator.translate("mailchimp-terms-description", locale, "LOGIN")}
                                                                        <p>
                                                                            <a href="https://mailchimp.com/legal/"
                                                                               target="_blank"
                                                                               rel="noopener noreferrer"
                                                                            >{Translator.translate("mailchimp-link", locale, "LOGIN")}
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div id="mce-responses" className="clear">
                                                                    <div className="response" id="mce-error-response"
                                                                         style={{display: "none"}}/>
                                                                    <div className="response" id="mce-success-response"
                                                                         style={{display: "none"}}/>
                                                                </div>
                                                                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                                                    <input type="text"
                                                                           name="b_21ce644186132be410f9320bb_eab9168407"
                                                                           tabIndex={-1} value="" readOnly/>
                                                                </div>
                                                                <div>
                                                                    <Button type="submit"
                                                                            value="Subscribe"
                                                                            name="subscribe"
                                                                            id="mc-embedded-subscribe"
                                                                            disabled={email=== "" || !isNewsletterChecked}
                                                                            className="button">
                                                                        {Translator.translate("Subscribe", locale, "LOGIN")}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style = {{minHeight: "90px" , display: "block", marginBottom: "30px"}}>
                                <Col style = {{ display: "block"}}>
                                    <div className={"row data-protection"}>
                                        <Col xs={12} sm={4} md={4}>
                                            <a href="https://www.teamprove.de/impressum" target={"_blank"}
                                               rel="noopener noreferrer">
                                                {Translator.translate("Impressum", locale, "LOGIN")}
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={4} md={4}>
                                            <a href="https://www.teamprove.de/datenschutz" target={"_blank"}
                                               rel="noopener noreferrer">
                                                {Translator.translate("Data Protection", locale, "LOGIN")}
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={4} md={4}>
                                            <a href="/home" target={"_blank"} rel="noopener noreferrer">
                                                {Translator.translate("How does Teamprove Meet work?", locale, "HOME")}
                                            </a>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }
}
