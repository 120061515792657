import React, {Component} from 'react';
import {Badge, Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import Translator from "../../util/Locale";
import Util from "../../util/Util";
import {Guest, PollType} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';
import "./PollCollectVote.scss";
import CollectVote from "../collectVote/CollectVote";
const moment = require('moment');

type Props = {
    locale: any,
    poll: PollType | null;
    user: Guest | null;
    room: string | null;
    guestList: any,
}
type State = {
    voteInput: string
}

class PollCollectVote extends Component<Props, State> {
    private inputVoteRef = React.createRef<any>();
    private inputLength = 140;

    state: State = {
        voteInput: ""
    };

    submitVote = (proposal: any) => {

        if( this.inputVoteRef && this.inputVoteRef.current){
            this.inputVoteRef.current.focus()
        }

        const {
            props: {user}
        } = this;

        const poll = this.props.poll;

        const userHasVoted = poll && user &&
            poll.votes && user.id &&
            poll.votes[user.id] &&
            poll.votes[user.id].vote;


        if(poll !== null && user && this.props.user !== null){
            firebase.database().ref(`rooms/${this.props.room}/polls/${poll.config.id}/votes/${this.props.user.id}`).update({
                    vote: userHasVoted? poll.votes[user.id].vote: null,
                    proposal: Util.encodeCharacters(proposal),
                    avatar: this.props.user.avatar,
                    name: this.props.user.name,
                    timestamp: moment().valueOf()
                }
            ).then();

        }
    };

    handleKeyPressCloudVote = (e: any) => {
        if(e.key === 'Enter'){
            this.submitVote(this.state.voteInput);
        }
    };

    render() {
        const {
            state: {voteInput},
            props: {poll, locale, user, guestList, room}
        } = this;

        if(!poll || (poll && poll.config.selectedDeckName !== "COLLECT_VOTE") || !user){
            return null;
        }

        const userHasProvideProposal =
            poll.votes && user.id &&
            poll.votes[user.id] &&
            poll.votes[user.id].proposal;

        let totalVoters  = guestList.length > poll.config.totalVoters? guestList.length: (poll.config.totalVoters || 0);
        //let allHaveProposed = poll.votes && Object.keys(poll.votes).length === totalVoters;

        return (
                    <div className="poll-cloud-wrapper">
                        {poll && !poll.config.isVotingVisible &&
                            <Row noGutters style={{marginBottom: "20px"}}>
                                <Col>
                                    <InputGroup>
                                        <FormControl
                                            type={"input"}
                                            as={"textarea"}
                                            value={voteInput}
                                            onChange={(e: any) => this.setState({voteInput: e.target.value.substr(0, this.inputLength)})}
                                            onKeyPress={this.handleKeyPressCloudVote}
                                            ref={this.inputVoteRef}
                                            placeholder={Translator.translate("Write one or more words to answer the question", locale, "VOTING")}
                                            style={{paddingLeft: "45px"}}
                                        />

                                        <InputGroup.Append
                                            style={{position: "absolute", zIndex: 100, top: "10px", left: "10px"}}>
                                            <Badge
                                                variant={((this.inputLength - voteInput.length) > 0) ? "success" : "danger"}>
                                                {this.inputLength - voteInput.length}
                                            </Badge>
                                        </InputGroup.Append>
                                        <InputGroup.Append>
                                            <Button
                                                variant={"primary"}
                                                onClick={() => this.submitVote(voteInput)}
                                                disabled={voteInput.trim() === ""}
                                                className={"d-none d-sm-none d-md-block"}
                                            >
                                                {userHasProvideProposal ?
                                                    <span>{Translator.translate("Change answer", locale, "VOTING")}</span> :
                                                    <span>{Translator.translate("Submit", locale, "VOTING")}</span>
                                                }
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                        }
                        { poll && !poll.config.isVotingVisible &&
                        <Row noGutters className={"d-block d-sm-block d-md-none sm-100"} style={{marginBottom: "15px"}}>
                            <Col>
                                <Button
                                    variant={"primary"}
                                    onClick={()=> this.submitVote(voteInput)}
                                    disabled={voteInput.trim() === ""}

                                    style={{
                                        width: "100%",
                                        marginTop: "5px"
                                    }}
                                >
                                    {userHasProvideProposal ?
                                        <span>{Translator.translate("Change answer", locale, "VOTING")}</span>:
                                        <span>{Translator.translate("Submit", locale, "VOTING")}</span>
                                    }
                                </Button>
                            </Col>
                        </Row>

                        }


                        <CollectVote poll={poll} user={user} locale={locale} guestList={guestList} room={room} />
                    </div>

        );
    }
}

export default PollCollectVote;
