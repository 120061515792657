import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import firebase from 'firebase/app';
import "../dashboard/portletTable.scss";
import ModalDelete from "../dashboard/ModalDelete";
import moment from 'moment';
import Util from "../../../util/Util";
import Notification from "../dashboard/Notification";

const ActionCell = (row: any)=> {
    const  handleOnDownload = (user: any, room: string, roomName: string, locale: "EN" | "ES" | "DE")=>{
        Util.downloadRecoveryLink(user, room, roomName, locale );
    };

    return (
        <div className={"cell-action-wrapper"}>
            <Button
                variant={"success"}
                onClick={()=>handleOnDownload({id: row.original.admin && row.original.admin.length > 0 ? row.original.admin[0]: "-" }
                , row.original.id, row.original.room, "DE"  )}>
                Recovery Link
            </Button>
            <Button
                variant={"primary"}
                onClick={()=> row.original.component.setState({
                    showModalReset: true,
                    record: {
                        id: row.original.id,
                        name: row.original.id
                    }
                })}>
                Remove Moods
            </Button>
            <Button
                variant={"danger"}
                onClick={()=> row.original.component.setState({
                    showModalDelete: true,
                    record: {
                        id: row.original.id,
                        name: row.original.id
                    }
                })}>
                Delete
            </Button>
        </div>
    );
};

const StandardCell = (cell: any)=>{
    return (
        <div className={`standard-cell`}>
            {cell.value}
        </div>
    )
};

const DateCell = (cell: any)=>{
    return (
        <div className={`standard-cell`}>
            {moment(cell.value).format("DD.MM.YYYY")} <br/> {moment(cell.value).fromNow()}
        </div>
    )
};

const AdminCell = (cell: any)=>{
    return (
        <div className={`standard-cell`}>
            {cell.value}
        </div>
    )
};

const columns = [
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-person-booth"/>&nbsp; Id</div>,
        accessor: 'id',
        Cell: StandardCell,
        minWidth: 150,
    },
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-person-booth"/>&nbsp; Room</div>,
        accessor: 'room',
        Cell: StandardCell

    },
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-users"/>&nbsp; Users</div>,
        accessor: 'totalUsers',
        minWidth: 110,
        width: 110,
        Cell: StandardCell
    },
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-crown"/>&nbsp; Admins</div>,
        accessor: 'hasAdmin',
        Cell: AdminCell,
        width: 110,
    },
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-calendar-alt"/>&nbsp; Creation</div>,
        accessor: 'creation',
        Cell: DateCell,
        width: 140,
    },
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-clock"/>&nbsp; Last Update</div>,
        accessor: 'lastUpdate',
        Cell: DateCell,
        width: 140
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ActionCell,
        width: 360
    }

];

type State = {
    searchField: string,
    filteredItems: Array<any>,
    loading: boolean,
    selectAll: boolean,
    selection: Array<any>,
    loadingData: boolean,
    showModalDelete: boolean,
    showModalReset: boolean,
    feedback: Array<any>;
    records: Array<any>;
    record: {
        name: string,
        id: string,
    };
};

type Props = {

};

class Rooms extends Component<Props, State> {

    state: State = {
        searchField: "",
        feedback: [],
        filteredItems: [],
        loading: false,
        selectAll: false,
        selection: [],
        records: [],
        showModalReset: false,
        loadingData: true,
        showModalDelete: false,
        record: {
            name: "",
            id: ""
        }
    };

    componentDidMount(): void {
        const commentsRef = firebase.database().ref(`rooms/`);
        commentsRef.on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({
                    loadingData: false,
                    records: this.mapItems(snapshot.val())
                });
            }else{
                this.setState({
                    loadingData: false
                });
            }
        });
    }

    findCreationTimeFromAdmin = (users: any)=>{
        let creation = "--";
        if(users){
            Object.keys(users).forEach(
                (u: any) => {
                    if(users[u].isAdmin && users[u].creation){
                        creation = users[u].creation;
                    }
                }
            );
            return creation;
        }
        return creation;
    };

    findLastUpdate = (users: any)=> {
        if(users) {
            let updates = Object.keys(users).map(
                (u: any) => {
                    return users[u].lastUpdate
                }
            );
            return Math.max(...updates);
        }
        return "--";
    };

    mapItems = (items: any): Array<any> => {
        return Object.keys(items).map((item: any)=> {
            return {
                id: item,
                room: items[item].config && items[item].config.room ? Util.decodeCharacters(items[item].config.room): item,
                totalUsers: Object.keys(items[item].users ? items[item].users : {}).length,
                hasAdmin: Object.keys(items[item].users ? items[item].users : {}).filter( (a: any)=> items[item].users[a].isAdmin ).length,
                creation: items[item] && items[item].config && items[item].config.timestamp ? items[item].config.timestamp: this.findCreationTimeFromAdmin(items[item].users),
                lastUpdate: this.findLastUpdate(items[item].users),
                component: this,
                admin: Object.keys(items[item].users ? items[item].users : {}).filter( (a: any)=> items[item].users[a].isAdmin )
            }
        });
    };

    deleteRecord = (id: string, name: string) => {
        this.setState({showModalDelete: false});
        firebase.database().ref(`rooms`).update({[id]: null}).then(()=>{
            this.pushFeedback({
                type: "success",
                title: "Room deleted",
                text: `Room ${name} was deleted!`,
                id: moment.now(),
            })
        });
    };

    resetRecord = (id: string, name: string) => {
        this.setState({showModalReset: false});

        firebase.database().ref(`rooms/${id}`).update({mood: null }).then(()=>{
            this.pushFeedback({
                type: "success",
                title: "Room's Moods deleted",
                text: `Room's Moods ${name} was deleted!`,
                id: moment.now(),
            })
        });
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    render() {
        const {
            state: { loadingData, feedback, showModalDelete, record , records, showModalReset}
        }= this;


        if(loadingData){
            return (
                <div className={"team-loader"}>
                    <img src={require('../../../../assets/logoCondensed.svg').default} alt=""/>
                </div>
            )
        }
        return (
            <Container fluid className={"portlet-wrapper"} style={{
                maxWidth: "1600px"
            }}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="fas fa-person-booth"/>&nbsp;
                            <span>Rooms</span>
                        </h4>
                    </Col>
                </Row>
                <Notification messages={feedback}/>
                <Row noGutters>
                    <Col>
                        {loadingData?
                            <div>loading </div>:
                            <ReactTable
                                data={records}
                                columns={columns}
                                defaultSorted={[
                                    {
                                        id: 'creation',
                                        desc: true
                                    }
                                ]}
                            />
                        }
                    </Col>
                </Row>
                <ModalDelete
                    showModal={showModalDelete}
                    onHide={()=> this.setState({showModalDelete: false})}
                    onDelete={()=> this.deleteRecord(record.id, record.name )}
                    title={"Delete Room?"}
                    actionText={"Delete"}
                    message={
                        <span>
                            {`You are about to DELETE the following Room FOREVER!!:`} <b>{record.name}</b>
                        </span>
                    }/>
                <ModalDelete
                    showModal={showModalReset}
                    onHide={()=> this.setState({showModalReset: false})}
                    onDelete={()=> this.resetRecord(record.id, record.name )}
                    title={"Reset Room' mood?"}
                    actionText={"Reset"}
                    message={
                        <span>
                            {`You are about to RESET the MOODs of the following room:`}  <b>{record.name}</b>
                        </span>
                    }/>
            </Container>
        );
    }
}

export default Rooms;
