import React, {Component} from 'react';
import Translator from "../../util/Locale";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Guest, LocaleType} from "../../../types/Types";
import "./moodModal.scss";
import firebase from 'firebase/app';
import 'firebase/database';

type Props = {
    show: boolean;
    locale: LocaleType;
    onHide: Function;
    room: string | null;
    user: Guest | null;
};
type State = {
    mood: string | null;
};

class MoodModal extends Component<Props, State> {

    state: State = {
        mood: null
    };

    handleOnHide = () => {
        this.props.onHide();
    };

    setMood = (mood: string) => {
        if( this.props.user && mood !== this.props.user.mood  ){
            localStorage.setItem("teamprove.mood", mood);
        }else{
            localStorage.removeItem("teamprove.mood");
        }

        if(this.props.user){
            firebase.database()
                .ref(`rooms/${this.props.room}/users/${this.props.user.id}`)
                .update({mood: mood === this.props.user.mood ? null: mood }
            ).then(()=> {});
            firebase.analytics().logEvent("set_mood", {"mood": mood});
            this.props.onHide();
        }
    };

    componentDidMount(): void {
        if (this.props.user && this.props.user.mood) {
            this.props.onHide();
        }
    }

    render() {
        const {
            props: {show, locale, user},
        } = this;

        if (user === null || (user && user.mood)) {
            return null;
        }

        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"moodModal-wrapper"}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("How are you feeling?", locale, "TOOLBAR")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row noGutters>
                            <Col>
                                <Row noGutters className={"score-wrapper"}>
                                    <Col>
                                        <div className={`score-tile`} onClick={() => this.setMood("5.svg")}>
                                            <img src={require("../../../assets/feedback/excellent.svg").default} alt=""
                                                 draggable={false}/>
                                            <img
                                                className={`tile-selector`}
                                                src={require("../../../assets/feedback/selector.svg").default}
                                                alt=""
                                                draggable={false}
                                            />
                                            <span>
                                                   {Translator.translate("exited", locale, "MOOD_CHART")}
                                               </span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={`score-tile`} onClick={() => this.setMood("4.svg")}>
                                            <img src={require("../../../assets/feedback/good.svg").default} alt=""
                                                 draggable={false}/>
                                            <img
                                                className={`tile-selector`}
                                                src={require("../../../assets/feedback/selector.svg").default}
                                                alt=""
                                                draggable={false}
                                            />
                                            <span>
                                                   {Translator.translate("happy", locale, "MOOD_CHART")}
                                               </span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={`score-tile`} onClick={() => this.setMood("3.svg")}>
                                            <img src={require("../../../assets/feedback/regular.svg").default} alt=""
                                                 draggable={false}/>
                                            <img
                                                className={`tile-selector`}
                                                src={require("../../../assets/feedback/selector.svg").default}
                                                alt=""
                                                draggable={false}
                                            />
                                            <span>
                                                   {Translator.translate("puzzled", locale, "MOOD_CHART")}
                                               </span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={`score-tile`} onClick={() => this.setMood("2.svg")}>
                                            <img src={require("../../../assets/feedback/sufficient.svg").default} alt=""
                                                 draggable={false}/>
                                            <img
                                                className={`tile-selector`}
                                                src={require("../../../assets/feedback/selector.svg").default}
                                                alt=""
                                                draggable={false}
                                            />
                                            <span>
                                                   {Translator.translate("bored", locale, "MOOD_CHART")}
                                               </span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={`score-tile`} onClick={() => this.setMood("1.svg")}>
                                            <img src={require("../../../assets/feedback/bad.svg").default} alt=""
                                                 draggable={false}/>
                                            <img
                                                className={`tile-selector`}
                                                src={require("../../../assets/feedback/selector.svg").default}
                                                alt=""
                                                draggable={false}
                                            />
                                            <span>
                                                   {Translator.translate("angry", locale, "MOOD_CHART")}
                                               </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

export default MoodModal;



