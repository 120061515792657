import React, {Component} from 'react';
import {Guest, LocaleType, PollType} from "../../../types/Types";
import "./teamRadar.scss";
import ReactResizeDetector from 'react-resize-detector';
import Translator from "../../util/Locale";
import {Col, Row} from "react-bootstrap";

type Props = {
    poll: PollType | null;
    user: Guest | null;
    locale: LocaleType;
    guestList: any,
    room?: string | null;
};
type State = {
    loading: boolean,
    width: number,
    height: number,
};

class TeamRadar extends Component<Props, State> {
    state: State = {
        loading: false,
        height: 450,
        width: 450
    };



    componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): void {
    }

    componentDidMount(): void {
        //this.setState({loading: true});
    }

    handleComponentResizing = (width: any, height: any)=>{
        this.setState({width: width, height: height});
    };

    getVoteFormat = (vote: any) => {
        if(vote){
            let voteKeys= Object.keys(vote  || {} ) || [];
            let canonicalVote: any = {};
            voteKeys.forEach(key => {
                canonicalVote[vote[key].label] = (vote[key].score / 5);
            });
            return canonicalVote;
        }
        return null;
    }

     groupByAttr(array: Array<any>) {
        // take each item of an array
        return array.reduce((acc, data) => {
            // take the values of that item
            Object.entries(data).forEach(([year, value]) => {
                // and map all the values to years
                acc[year] = acc[year] || []
                acc[year].push(value)
            })
            return acc
        }, {})
    }

    average(object: any) {
        let averages: any = {};
        for (let key in object) {
            let divisor = object[key].filter((o: any)=> o !== 0 && o !== null).length;
            if(divisor > 0){
                averages[key] = object[key].reduce((sum: number, value: number) => sum + value) / divisor
            }else{
                averages[key] = 0;
            }
        }
        return averages;
    }

    media(object: any){
        let media: any = {};
        Object.keys(object).forEach((k: any)=>{
            let set = object[k].filter((o: any)=> o !== 0 && o !== null);
            let max = Math.max(...set);
            let min = Math.min(...set);
            media[k] = ((max-min)/2)+min;
        })
        return media;
    }

    max(object: any){
        let max: any = {};
        Object.keys(object).forEach((k: any)=>{
            let set = object[k].filter((o: any)=> o !== 0 && o !== null);
            max[k] = Math.max(...set);
        })
        return max;
    }

    min(object: any){
        let min: any = {};
        Object.keys(object).forEach((k: any)=>{
            let set = object[k].filter((o: any)=> o !== 0 && o !== null);
            min[k] = Math.min(...set);
        })
        return min;
    }

    render() {
        const {
            props: { poll, locale, user, guestList },
            state: { loading, width, height }
        } = this;

        let dataPoints: any = [];
        let minMaxPoints: any = [];
        let isVisibleOnlyToAdmin = poll && !poll.config.isRealtimePreview && poll.config.pollStatus === "RUNNING" && user && user.isAdmin;

        if(!poll || !user){
            return null;
        }else if(poll && poll.votes){
            let allVotes = Object.keys(poll.votes).map((v: any)=>
                 this.getVoteFormat(poll.votes[v].vote)
            ).filter((o)=> o !== null);



            if(poll && !poll.config.isRealtimePreview && poll.config.pollStatus === "RUNNING" && !user.isAdmin){
                if(poll.votes && user.id && poll.votes[user.id] && poll.votes[user.id].vote){
                    let myVote = this.getVoteFormat(poll.votes[user.id]?.vote);
                    dataPoints.push(myVote);
                }
            }else{
                if(allVotes.length > 0){
                    minMaxPoints.push(this.max(this.groupByAttr(allVotes)));
                    minMaxPoints.push(this.min(this.groupByAttr(allVotes)));

                    dataPoints.push(this.min(this.groupByAttr(allVotes)));
                    dataPoints.push(this.average(this.groupByAttr(allVotes)));
                    Object.keys(poll.votes || {}).forEach((v:any)=>{
                        let vote = this.getVoteFormat(poll.votes[v].vote);
                        if(vote){
                            dataPoints.push(vote);
                        }
                    });
                }
            }
        }

        const numberOfScales = 5;
        const lines = () => (col:any) => (
            <line
                key={`line-${col.key}`}
                x1={polarToX(col.angle, ((width > height ? height: width) / 2))}
                y1={polarToY(col.angle, ((width > height ? height: width) / 2))}
                x2= {0}
                y2= {0}
                strokeWidth="1"
                stroke="black" />
        );

        const scale = (value: any) => (
            <circle
                key={`scale-${value}`}
                cx={0}
                cy={0}
                r={((value / numberOfScales) * (width > height ? height: width)) / 2}
                fill="white"
                stroke="black"
                strokeWidth="1.5"
            />
        );
        const polarToX = (angle: any, distance: any) => Math.cos(angle - Math.PI / 2) * distance;
        const polarToY = (angle: any, distance: any) => Math.sin(angle - Math.PI / 2) * distance;
        const pathDefinition = (points: any) => {
            let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4);
            for (let i = 1; i < points.length; i++) {
                d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4);
            }
            return d + 'z';
        };

        const shapeMinMax = () => {
            if(minMaxPoints.length < 2){
                return null;
            }

            let outerPoints = Object.keys(minMaxPoints[0]).map((key, i, all) => {
                return {
                    value: isFinite(minMaxPoints[0][key])  ? minMaxPoints[0][key]:0,
                    angle: (Math.PI * 2 * i) / all.length
                };
            });
            outerPoints.push(outerPoints[0]);

            let innerPoints = Object.keys(minMaxPoints[1]).map((key, i, all) => {
                return {
                    value: isFinite(minMaxPoints[1][key])  ? minMaxPoints[1][key]:0,
                    angle: (Math.PI * 2 * i) / all.length
                };
            });

            innerPoints.push(innerPoints[0]);
            let columns = [...outerPoints, ...innerPoints];
            let points: any = [];
            columns.forEach((shape:any) => {
                points.push( [
                    polarToX(shape.angle, (shape.value * (width > height ? height: width)) / 2),
                    polarToY(shape.angle, (shape.value * (width > height ? height: width)) / 2)
                ]);
            });
            return(
                <g key={`group-min-maxxx`}>
                    <path
                        d={pathDefinition( points )}
                        fill="#f39820"
                        strokeWidth={"1"}
                        fillOpacity=".9"
                        fillRule="evenodd"
                    />

                </g>
            );
        };

        const shape = (columns: any) => (chartData:any, i: number) => {
            const data = chartData;
            return (

                    <path
                        key={`shape-${i}`}
                        d={pathDefinition(
                            columns.map((col:any) => {
                                const value = data[col.key];
                                let x = polarToX(col.angle, (value * (width > height ? height: width)) / 2);
                                let y = polarToY(col.angle, (value * (width > height ? height: width)) / 2);
                                return [
                                    isFinite(x) ? x: 0,
                                    isFinite(y) ? y: 0
                                ];
                            })
                        )}
                        stroke={i === 1 ? "black": `#e6b472`}
                        fill={i === 0 ? (poll && !poll.config.isRealtimePreview && poll.config.pollStatus === "RUNNING" && !user.isAdmin) ? "#28a745": "#e6b472": "transparent"}
                        //strokeDasharray={i === 0 ||  i === 1 ? "0" : "5 5" }
                        strokeWidth={i === 0 ? "1": i === 1 ? "2": "1.5"}
                        fillOpacity=".9"
                        fillRule="evenodd"
                    />
            );
        };

        const transformCoordinateSystem = (angle: number, x: number, y: number) => {
            switch (angle.toFixed(1)){
                case "0.0":  y -= 40; //TOP
                    break;
                case "1.3": x += 50; //RIGHT
                    y -= 10
                    break;
                case "1.6": x += 60; //4
                    break;
                case "2.1": y += 20; //BOTTOM
                    x += 60;
                    break;
                case "2.5": y += 40; //BOTTOM
                    x += 30;
                    break;
                case "3.1": y += 40; //4
                    break;
                case "3.8": y += 40;
                    x -= 30;
                    break;
                case "4.2": y += 20; //BOTTOM
                    x -= 60;
                    break;
                case "4.7": x -= 60; //4
                    break;
                case "5.0": x -= 50; //LEFT
                    y -= 10;
                    break;

            }
            return {x,y}
        }

        const caption = () => (col:any) => {
            let x = polarToX(col.angle, ((width > height ? height : width) / 2) * 0.95) - ((col.key.length * 8) / 2);
            let y = polarToY(col.angle, ((width > height ? height : width) / 2) * 0.95);
            let xy = transformCoordinateSystem(col.angle, x, y);
            x = xy.x;
            y = xy.y;

            let score = "";
            if(dataPoints && dataPoints.length >1){
                Object.keys(dataPoints[1]).forEach((d:any) => {
                    if(col.key === d){
                        if(isFinite(dataPoints[1][d])){
                            score = ((dataPoints[1][d] || 0)*5).toFixed(1);
                        }else{
                            score = "0.0"
                        }

                    }
                })
            }

            let myVote = poll.votes && user.id && poll.votes[user.id] ? poll.votes[user.id].vote: null;
            let isSummited = false;

            if(myVote){
                myVote = Object.keys(myVote).map((o: any) => {
                    if(myVote[o].score > 0){
                        return myVote[o].label
                    }
                    return null;
                }).filter((o: any)=> o !== null);
                isSummited = (myVote.includes(col.key));
            }

            return (
                <g key={`caption-of-${col.key}`}>
                    <text
                        x={x}
                        y={y}
                        dy={10 / 2}
                        fill={isSummited ? "#28a745": "#444"}

                        fontWeight={isSummited ? "bold": "400"}
                    >
                        {col.key} {isSummited && "✓"}

                    </text>
                    <text
                        x={x}
                        y={y}
                        dy={10 / 2}
                        fill={"black"}
                        fontWeight={"bold"}
                        transform="translate(-1 18)"
                    >
                        {score !=="" && `${score}`}
                    </text>
                </g>
        )};

        const RadarChart = (width: any, height: any) => {
            const groups = [];
            const scales = [];
            for (let i = numberOfScales; i > 0; i--) {
                scales.push(scale(i));
            }
            groups.push(<g key={`scales`}>{scales}</g>);

            const middleOfChartX = (width/2).toFixed(4);
            const middleOfChartY = (height/2).toFixed(4);
            const captions = Object.keys(dataPoints[0]);
            const columns = captions.map((key, i, all) => {
                return {
                    key,
                    angle: (Math.PI * 2 * i) / all.length
                };
            });

            groups.push(<g key={`group-lines`}>{columns.map(lines())}</g>);
            groups.push(<g key={`groups-min-max}`}>{ shapeMinMax()}</g>);
            groups.push(<g key={`groups}`}>{dataPoints.map(shape(columns)).reverse()}</g>);
            groups.push(<g key={`group-captions`}>{columns.map(caption())}</g>);

            return (
                <svg
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={width - 80}
                    height={height - 80}
                    viewBox={`0 0 ${width} ${height}`}
                >
                    <g transform={`translate(${middleOfChartX},${middleOfChartY})`}>{groups}</g>
                </svg>
            );
        };

        if(poll && !poll.votes && poll.config.pollStatus === "FINISHED"){
            return  <div style={{padding: "10px"}}>
                {Translator.translate(`No participation so far`, locale, "VOTING")}
            </div>;
        }

        if(dataPoints.length === 0){
            return null;
        }

        //CALCULATE NUMBER OF VOTES
        let options =  (poll && poll.config && poll.config.options) || {};
        let rawVotes = (poll && poll.votes) || {};

        let totalKeyPerVote = options ? Object.keys(options).filter((v: any ) => options[v].label !== "").length: 0;
        let guestVotes = Object.keys(rawVotes).map((v:any) =>  {
            let set  = rawVotes[v].vote || {};
            return Object.keys(set).map((s:any) =>  set[s].score > 0 ? set[s].score: null).filter(n => n !== null).length;
        } );
        let totalVotes = guestVotes.filter(n => n ===totalKeyPerVote).length || 0 ;
        let totalGuest = guestList ? guestList.length: 0;

        return (
            <>
                {!loading && dataPoints.length > 0 && poll && poll.config.pollStatus === "RUNNING" &&
                    <>
                        <br/>
                        <Row noGutters>
                            <Col className="voting-info">
                                {user && poll.config.pollStatus === "RUNNING" && <h5>{Translator.translate("Results", locale, "VOTING")}</h5>}
                                <span>{Translator.translate(`Vote${totalVotes > 1 ? "s":""}`, locale, "VOTING")}: <b>{totalVotes} / {totalVotes > totalGuest ? totalVotes: totalGuest} </b></span>
                            </Col>
                        </Row>
                    </>
                }

                <ReactResizeDetector handleWidth key={"team-radar-wrapper-3"}
                                     onResize={(w: any, h: any)=> this.handleComponentResizing(Math.floor(w), Math.floor(h) )}>
                    {() =>
                        <div style={{ width: "100%"}} key="1" className={"team-radar-wrapper"}>
                            { loading &&
                                <div className={"team-loader small"}>
                                    <img src={require('../../../assets/logoCondensed.svg').default} width={20} height={20} alt=""/>
                                </div>
                            }
                            {!loading &&
                                <div className={`${isVisibleOnlyToAdmin? "vote-anonymous": ""} `}>

                                    {isVisibleOnlyToAdmin &&
                                        <div className="hidden-message">
                                            {Translator.translate("Only you can see this preview", locale, "VOTING")}
                                        </div>
                                    }
                                    {poll && !poll.config.isRealtimePreview && poll.config.pollStatus === "RUNNING" && !user.isAdmin &&
                                        <div className="hidden-message">
                                            {Translator.translate("entered_values_radar", locale, "VOTING")}
                                        </div>
                                    }
                                    {poll && poll.config.isRealtimePreview && poll.config.pollStatus === "RUNNING" && !user.isAdmin &&
                                        <div className="hidden-message">
                                            {Translator.translate("averaged_values_radar", locale, "VOTING")}
                                        </div>
                                    }
                                    { dataPoints.length > 0 && RadarChart(width + 100, height + 100)}
                                </div>
                            }
                        </div>
                    }
                </ReactResizeDetector>
            </>
        );
    }
}

export default TeamRadar;
