import React, {Component} from 'react';
import {Container, Row, Col, Button, Form, FormControl } from "react-bootstrap";
import "./join.scss";
import firebase from 'firebase/app';
import 'firebase/database';
import Util from "../../util/Util";
import Translator from "../../util/Locale";
import * as moment from 'moment';
import {LocaleType} from "../../../types/Types";

type Props = {
    setCredentials: Function
    room: string;
};

type State = {
    userName: string;
    loading: boolean;
    sessionData: any | null;
    avatar: string;
    locale: LocaleType;
    isInvalidUser: boolean;
    mood: string | null;
};

let browserLocale = navigator.language.toUpperCase().substr(0, 2);
let locale: LocaleType = (browserLocale === "DE" || browserLocale === "EN" || browserLocale === "ES") ? browserLocale : "EN";

class Join extends Component<Props, State> {
    state: State = {
        userName: localStorage.getItem("teamprove.userName") || "",
        loading: true,
        sessionData: null,
        avatar: localStorage.getItem("teamprove.avatar") || "",
        locale: localStorage.getItem("teamprove.locale") as LocaleType || locale,
        isInvalidUser: false,
        mood: localStorage.getItem("teamprove.mood") || null
    };

    componentDidMount(): void {
        this.sessionExits();
    }

    sessionExits = () => {
        const room = Util.encodeCharacters(this.props.room);
        if (room !== "") {
            this.setState({loading: true});
            //Check if room already exists
            firebase.database().ref(`rooms/${room}`).once("value").then(
                (snapshotRoom: any) => {
                    //if room does not exit then he is admin
                    if (snapshotRoom && !snapshotRoom.val()) {
                        window.location.href = `/`;
                    } else {
                        if(this.state.userName !== "" && this.state.avatar !=="" && this.props.room !== ""){
                            this.handleLogIn();
                        }else{
                            this.setState({loading: false, sessionData: snapshotRoom.val()});
                            this.setState({avatar: localStorage.getItem("teamprove.avatar") || "1.svg"});
                        }
                    }
                }
            );
        }else {
            window.location.href = `/`;
        }
    };

    handleUpdateUserName = (event: any) => {
        const {value} = event.target;
        if (value !== this.state.userName) {
            this.setState({userName: value});
        }
    };

    renderAvatar = () => {
        let avatar = [];
        for (let i = 0 ; i <  302; i++) {
            avatar.push(
                <div key={i}
                     className={`avatar-item ${this.state.avatar === `${i}.svg` ? "active" : ""}`}
                     onClick={() => this.setState({avatar: `${i}.svg`}, () => localStorage.setItem("teamprove.avatar", `${i}.svg`))}>
                    <img src={require(`../../../assets/avatar/${i}.svg`).default} alt=""/>
                </div>
            )
        }
        return avatar;
    };

    handleUserOnBlur = () => {
        if (this.state.userName && this.state.userName.trim() === "") {
            this.setState({isInvalidUser: true, userName: this.state.userName.trim()});
        }
    };

    handleLogIn = () => {
        const {avatar, mood} = this.state;
        const room = this.props.room;
        const user = Util.encodeCharacters(this.state.userName.trim());

       let userData: any = {
           name: user,
           avatar: avatar,
           lastUpdate: moment.now(),
           isLogin: true,
       };

       if(mood){
           userData.mood = mood;
       }

       let userID = localStorage.getItem('teamprove.userId') || null;
       if(!userID){
           firebase.database().ref(`rooms/${room}/users/`).push({...userData, creation: moment.now()}).then((snap)=>{
               if(snap && snap.key){
                   localStorage.setItem("teamprove.userName", this.state.userName.trim());
                   localStorage.setItem('teamprove.userId', snap.key);
                   localStorage.setItem("teamprove.avatar", this.state.avatar);
                   firebase.analytics().logEvent("user_join_session", {room: room });
                   this.props.setCredentials(room, snap.key);
               }
           });
       }else{
           firebase.database().ref(`rooms/${room}/users/${userID}`).update(userData).then(()=>{
                   localStorage.setItem("teamprove.userName", this.state.userName.trim());
                   localStorage.setItem("teamprove.avatar", this.state.avatar);
                   firebase.analytics().logEvent("user_rejoin_session", {room: room });
                   this.props.setCredentials(room, userID);
           });
       }
    };

    render() {
        const {
            state:{ sessionData, userName, loading, isInvalidUser }
        } = this;

        const { creator = "", room = null } = sessionData && sessionData.config ? sessionData.config: {};

        if(loading){
            return (
                <div className={"team-loader"}
                    style={{backgroundImage: `url(${require("../../../assets/icon/pattern2.svg").default}) !important`}}
                >
                    <img src={require('../../../assets/logoCondensed.svg').default} alt=""/>
                </div>
            )
        }

        return (
            <Container
                fluid
                className={"join-screen-wrapper"}
                style={{backgroundImage: `url(${require("../../../assets/icon/pattern2.svg").default})`}}
            >
                <Row>
                    <Col>
                        <div className={"join-login-wrapper"}>
                            <h2>{creator} {Translator.translate("has invited you to the meeting", locale, "LOGIN")}</h2>
                            <h2><span className={"ownerName"}>"{room || this.props.room}"</span></h2>


                            <Row noGutters>
                                <Col className="form-wrapper">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label column={false}>
                                                <h5>
                                                    {Translator.translate("Type in your name", locale, "LOGIN")}
                                                </h5>
                                            </Form.Label>
                                            <FormControl
                                                placeholder={Translator.translate("User", locale, "LOGIN")}
                                                aria-label="Large"
                                                aria-describedby="inputGroup-sizing-sm"
                                                value={userName}
                                                required
                                                name="session"
                                                type="text"
                                                onChange={this.handleUpdateUserName}
                                                onBlur={this.handleUserOnBlur}
                                                autoComplete="off"
                                                isInvalid={isInvalidUser}
                                            />
                                            <Form.Text className="text-muted">
                                                <span>
                                                    <span
                                                        className={`float-right ${isInvalidUser ? "invalid" : ""}`}>
                                                        {isInvalidUser && Translator.translate("Type in a valid user name", locale, "LOGIN")}
                                                    </span>
                                                </span>
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label column={false}>
                                                <h5>
                                                    {Translator.translate("Pick your avatar", locale, "LOGIN")}
                                                </h5>
                                            </Form.Label>
                                            <div className="avatar-picker">
                                                {this.renderAvatar()}
                                            </div>
                                        </Form.Group>
                                        <Button
                                            onClick={this.handleLogIn}
                                            className="btn btn-primary float-right"
                                            disabled={userName.trim() === ""}
                                            id={"submit"}
                                        >
                                            <i className="fas fa-sign-in-alt"/> {Translator.translate("Join Session", locale, "LOGIN")}
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Join;
