import React, {Component} from "react"

type Props = {
    data: {x: number, y: number, poll: any},
    svgHeight: number,
    svgWidth: number,
    maxY: number,
    maxX: number,
    color: string,
    toggleModal: Function,
}


type State = {
    isHover: boolean;
    strokeColor: string;
    strokeWidth: number;
}

class PollLine extends Component<Props, State> {

    state: State ={
        isHover: false,
        strokeColor: this.props.color,
        strokeWidth: 1,
    };

    getMaxY= ()=> {
        return this.props.maxY
    };

    getSvgX = (x: any) => {
        const {svgWidth} = this.props;
        return (x / this.props.maxX * svgWidth);
    };

    getSvgY = (y: any) => {
        const {svgHeight} = this.props;
        return svgHeight - (y / this.getMaxY() * svgHeight);
    };


    render() {
        const {data, color, svgHeight,toggleModal} = this.props;
        const { strokeColor, strokeWidth } = this.state;

        return (
            <>
                <line x1={this.getSvgX(data.x)} y1={0} x2={this.getSvgX(data.x)} y2={svgHeight}
                      style={{ stroke: "transparent", cursor: "pointer" }}
                      strokeWidth="10"
                      onMouseOver={()=> this.setState({strokeColor: "#8952a0", strokeWidth: 3})}
                      onMouseOut={()=> this.setState({strokeColor: color, strokeWidth: 1})}
                      onClick={() => toggleModal(data.poll)}
                />
                <line x1={this.getSvgX(data.x)} y1={0} x2={this.getSvgX(data.x)} y2={svgHeight}
                      style={{ stroke: strokeColor, cursor: "pointer" }}
                      className={"poll-line"}
                      strokeWidth={strokeWidth}
                      onMouseOver={()=> this.setState({strokeColor: "#8952a0", strokeWidth: 3})}
                      onMouseOut={()=> this.setState({strokeColor: color, strokeWidth: 1})}
                      onClick={() => toggleModal(data.poll)}
                />
                <rect
                    x={this.getSvgX(data.x)-10}
                    y={-3}
                    rx="5"
                    ry="5"
                    width="20"
                    height="20"
                    style={{
                        fill: strokeWidth === 3 ? strokeColor :"white",
                        stroke: strokeColor,
                        strokeWidth: strokeWidth,
                        cursor: "pointer"
                    }}
                      onMouseOver={()=> this.setState({strokeColor: "#8952a0", strokeWidth: 3})}
                      onMouseOut={()=> this.setState({strokeColor: color, strokeWidth: 1})}
                      onClick={() => toggleModal(data.poll)}
                />

                <image
                    x={this.getSvgX(data.x)-15}
                    y={-6}
                    href={require("../../../../assets/cards/voting.svg").default}
                    height="25"
                    onMouseOver={()=> this.setState({strokeColor: "#8952a0", strokeWidth: 3})}
                    onMouseOut={()=> this.setState({strokeColor: color, strokeWidth: 1})}
                    onClick={() => toggleModal(data.poll)}
                    style={{cursor: "pointer"}}
                    width="30"/>

            </>
        );
    }
}

export default PollLine;
