import React, {Component} from 'react';
import {Badge, Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import Translator from "../../util/Locale";
import Util from "../../util/Util";
import {Guest, PollType} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';
import Cloud from "../cloud/Cloud";
import "./pollCloud.scss";
const moment = require('moment');

type Props = {
    locale: any,
    poll: PollType | null;
    user: Guest | null;
    room: string | null;
    guestList: any,
}
type State = {
    cloudTag: string
}

class PollCloud extends Component<Props, State> {
    private inputCloudTag = React.createRef<any>();
    state: State = {
        cloudTag: ""
    };

    submitCloudVote = (vote: any)=> {
        this.setState({ cloudTag: ""});

        if( this.inputCloudTag && this.inputCloudTag.current){
            this.inputCloudTag.current.focus()
        }

        const poll = this.props.poll;
        if(poll !== null && this.props.user !== null){
            const prevPollVotes =
                poll.votes &&
                poll.votes[this.props.user.id] &&
                poll.votes[this.props.user.id].vote ?
                    poll.votes[this.props.user.id].vote.filter((v: any) => v !== null).map((v: any) => v.toString()) : [];

            if(!prevPollVotes.find(((w: any) => vote.toString().toLowerCase() === w.toLowerCase() ))){
                firebase.database().ref(`rooms/${this.props.room}/polls/${poll.config.id}/votes/${this.props.user.id}`).update({
                        vote:  [...prevPollVotes , Util.encodeCharacters(vote) ],
                        avatar: this.props.user.avatar,
                        name: this.props.user.name,
                        timestamp: moment().valueOf()
                    }
                ).then();
            }
        }
    };

    handleKeyPressCloudVote = (e: any) => {
        if(e.key === 'Enter'){
            this.submitCloudVote(this.state.cloudTag);
        }
    };

    removeTagVote= (vote: string)=> {
        const poll = this.props.poll;
        if(poll !== null && this.props.user !== null){
            let prevPollVotes =
                poll.votes &&
                poll.votes[this.props.user.id] &&
                poll.votes[this.props.user.id].vote ?
                    poll.votes[this.props.user.id].vote.filter((v: any) => v !== null) : [];

            prevPollVotes = prevPollVotes.filter((v: any)=> v !== vote);
            firebase.database().ref(`rooms/${this.props.room}/polls/${poll.config.id}/votes/${this.props.user.id}`).update({
                    vote:  [...prevPollVotes],
                    avatar: this.props.user.avatar,
                    name: this.props.user.name,
                    timestamp: moment().valueOf()
                }
            ).then();
        }
    };

    render() {
        const {
            state: {cloudTag},
            props: {poll, locale, user, guestList}
        } = this;

        if(!poll || (poll && poll.config.selectedDeckName !== "CLOUD") || !user){
            return null;
        }

        const userHasVoted =
            poll.votes && user.id &&
            poll.votes[user.id] &&
            poll.votes[user.id].vote &&
            Array.isArray(poll.votes[user.id].vote) &&
            poll.votes[user.id].vote.filter((v: any) => v !== null).length > 0;

        return (

                    <div className="poll-cloud-wrapper">
                        <Row noGutters>
                            <Col>
                                <InputGroup>
                                    <FormControl
                                        type={"text"}
                                        value={cloudTag}
                                        onChange={(e: any)=> this.setState({cloudTag: e.target.value.substr(0, 20)})}
                                        onKeyPress={this.handleKeyPressCloudVote}
                                        ref={this.inputCloudTag}
                                        placeholder={Translator.translate("Write one or more words to answer the question", locale, "VOTING")}
                                        style={{paddingLeft: "35px"}}
                                    />
                                    <InputGroup.Append style={{position: "absolute", zIndex: 100, top: "10px", left: "10px"}}>
                                        <Badge variant={ ((20 - cloudTag.length) > 0) ? "success": "danger"}>
                                            {20 - cloudTag.length}
                                        </Badge>
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button
                                            variant={"primary"}
                                            onClick={()=> this.submitCloudVote(cloudTag)}
                                            disabled={cloudTag.trim() === ""}
                                        >
                                            {userHasVoted ?
                                                <span>{Translator.translate("Submit another answer", locale, "VOTING")}</span>:
                                                <span>{Translator.translate("Submit", locale, "VOTING")}</span>
                                            }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Row>

                        {userHasVoted &&
                            <Row noGutters>
                                <Col>
                                    <h5 style={{marginTop: "10px", marginBottom: "0"}}>
                                        {poll.votes[user.id].vote.filter((v: any) => v !== null).map((t: any)=>
                                            <Badge key={t} variant={"secondary"} className={"badge-cloud"}>
                                                {Util.decodeCharacters(t)}&nbsp;&nbsp;
                                                <i
                                                    className="fas fa-times-circle"
                                                    style={{cursor: "pointer"}}
                                                    onClick={()=> this.removeTagVote(t)}
                                                />
                                            </Badge>
                                        )}
                                    </h5>
                                </Col>
                            </Row>
                        }
                        <Cloud poll={poll} user={user} locale={locale} guestList={guestList} />

                    </div>

        );
    }
}

export default PollCloud;
