import React, {Component} from 'react';
import { Route, Switch  } from "react-router-dom";
import Login from "../screens/login/Login";
import Room from "../screens/room/Room";
import Home from "../screens/home/Home";
import Main from "../screens/mainAdmin/Main";
import Join from "../screens/join/Join";
import Webcam from "../screens/experiments/Webcam";

type Props = {
    setCredentials: Function;
}

class Routing extends Component<Props, {}> {

    login = (props: any) => {
       let {match: {params: { room }}} = props;
        return <Login setCredentials={this.props.setCredentials} room={room? room: ""}/>;
    };

    room = (props: any) => {
        let {match: {params: { room, user }}} = props;
        if(room && user ){
            return  <Room userId={user} roomId={room} />;
        }
        return <>Ops!</>
    };

    home = () => {
        return  <Home/>;
    };

    join = (props: any) => {
        let {match: {params: { room }}} = props;
        return  <Join setCredentials={this.props.setCredentials} room={room? room: ""}/>;
    };

    experiments = (props: any)=>{
        let {match: {params: { room, user }}} = props;
        if(room && user ){
            return  <Webcam user={user} room={room} />;
        }
        return <div>Room and/or User id are missing</div>
    };

    admin = (props: any) => {
        let {match: {params: { dashboard }}} = props;
        if(dashboard){
            return  <Main dashboard={dashboard}/>
        }
        return <div>Oops, sorry, you don't belong here</div>;
    };

    render() {
        return (
            <Switch>
                <Route exact path="/admin/:dashboard" component={this.admin} strict={true} />
                <Route path="/goLife/:room/:user" component={this.experiments} />
                <Route path="/room/:room/:user" component={this.room} />
                <Route path="/join/:room" component={this.join} />
                <Route path="/home" component={this.home} />
                <Route path="/" component={this.login} />
                <Route path="/*" component={this.login} />
            </Switch>
        );
    }
}

export default Routing;
