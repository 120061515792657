import React, {Component} from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    Container, ListGroup, InputGroup, FormControl,
} from "react-bootstrap";
import Translator from "../../util/Locale";
import {Guest, LocaleType} from "../../../types/Types";
import './newAdminModal.scss';
import firebase from 'firebase/app';
import 'firebase/database';

type Props = {
    onHide: Function,
    onSubmit: Function,
    onQuit: Function
    show: boolean,
    locale: LocaleType,
    isTriggerByUser: boolean,
    guestList: any;
    room: string;
    user: Guest;
};

type State = {
    filterField: string
}

class NewAdminModal extends Component<Props, State> {

    state: State = {
        filterField: ""
    };

    handleOnFilterChange = (event: any) => {
        this.setState({filterField: event.target.value});
    };

    handleOnHide = () => {
        this.props.onHide();
    };

    handleOnAssignNewAdmin = (user: string) => {
        firebase.database().ref(`rooms/${this.props.room}/users/${user}`).update({isAdmin: true}
        ).then();
        firebase.analytics().logEvent("add_admin-role");
    };

    handleQuitLead = (user: string) => {
        firebase.database().ref(`rooms/${this.props.room}/users/${user}`).update({isAdmin: true}
        ).then();
        firebase.database().ref(`rooms/${this.props.room}/users/${this.props.user.id}`).update({isAdmin: false}
        ).then();
        firebase.analytics().logEvent("quit-admin-role");
        this.props.onHide();
    };


    handleRemoveAdminRole = (user: string) => {
        firebase.database().ref(`rooms/${this.props.room}/users/${user}`).update({isAdmin: false}
        ).then();
        firebase.analytics().logEvent("remove_admin-role");
    };

    render() {
        const {
            props: {locale, show, guestList, user },
            state:{  filterField}
        } = this;

        const{ id } = user;

        const list = Object.keys(guestList)
            .filter((key: any) => key.toLowerCase().includes(filterField.toLowerCase()));

        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"new-admin-wrapper"}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("Assign new admin to this session", locale, "FEEDBACK")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row noGutters>
                            <Col style={{padding: "10px", borderBottom: "1px solid rgba(0,0,0,.125)"}}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroup-sizing-default">
                                            <i className="fas fa-search"/>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        type="text"
                                        value={filterField}
                                        onChange={this.handleOnFilterChange}
                                        placeholder={Translator.translate("Search user", locale, "FEEDBACK")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup variant="flush" className={"user-container"}>
                                    {list.map((key: any, i: number)=>
                                        <ListGroup.Item key={key}>
                                            <div className={"list-item-user"}>
                                                <div className={"num"}>{i+1}</div>
                                                {guestList[key] && guestList[key].avatar &&
                                                    <div className={`avatar-item`}>
                                                        <img src={require(`../../../assets/avatar/${guestList[key].avatar}`).default} alt=""/>
                                                    </div>
                                                }
                                                <div className={"user"}>{guestList[key].name}</div>
                                                {id !== key && !guestList[key].isAdmin &&
                                                    <Button
                                                        onClick={() => this.handleOnAssignNewAdmin(guestList[key].id)}
                                                        variant="outline-secondary"
                                                        className={"btn-2"}
                                                    >
                                                   <span className={"d-block d-sm-block d-md-block d-lg-none"}>
                                                         <i className="fas fa-user-plus"/>&nbsp;
                                                    </span>
                                                        <span className={"d-none d-sm-none d-md-none d-lg-block"}>
                                                         <i className="fas fa-user-plus"/>&nbsp;
                                                            {Translator.translate("Select this user", locale, "FEEDBACK")}
                                                    </span>
                                                    </Button>
                                                }
                                                {id !== key && !guestList[key].isAdmin &&
                                                    <Button
                                                        onClick={() => this.handleQuitLead(guestList[key].id)}
                                                        variant="outline-secondary"
                                                        className={"btn-3"}
                                                    >
                                                       <span className={"d-block d-sm-block d-md-block d-lg-none"}>
                                                             <i className="fas fa-crown"/>&nbsp;
                                                        </span>
                                                        <span className={"d-none d-sm-none d-md-none d-lg-block"}>
                                                             <i className="fas fa-crown"/>&nbsp;
                                                            {Translator.translate("Transfer moderator role", locale, "FEEDBACK")}
                                                        </span>
                                                    </Button>
                                                }
                                                {id !== key && guestList[key].isAdmin && guestList.filter((guest: any)=> guest.isAdmin).length > 1 &&
                                                    <Button
                                                        onClick={() => this.handleRemoveAdminRole(guestList[key].id)}
                                                        variant="outline-secondary"
                                                        className={"btn-2"}
                                                    >
                                                           <span className={"d-block d-sm-block d-md-block d-lg-none"}>
                                                                 <i className="fas fa-minus-circle"/>&nbsp;
                                                            </span>
                                                        <span className={"d-none d-sm-none d-md-none d-lg-block"}>
                                                                <i className="fas fa-minus-circle"/>&nbsp;
                                                            {Translator.translate("Remove moderator role", locale, "FEEDBACK")}
                                                            </span>
                                                    </Button>
                                                }
                                            </div>
                                        </ListGroup.Item>
                                    )}

                                    {list.length === 0 &&
                                    <ListGroup.Item >
                                            {Translator.translate("No results for", locale, "FEEDBACK")} {`"${filterField}"`}
                                    </ListGroup.Item>
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

export default NewAdminModal;
