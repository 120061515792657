import React, {Component} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import "./infoBanner.scss";
import {LocaleType} from "../../../types/Types";
import Translator from "../../util/Locale";


type State = {
  isVisible: boolean
};

type Props = {
    locale: LocaleType
}

const bannerVersion = 1;

class InfoBanner extends Component<Props, State> {

    state: State = {
        isVisible: localStorage.getItem(`teamprove.loginBanner-v-${bannerVersion}`)!=="false"
    };

    handleOnClose = () => {
        this.setState({
            isVisible: false
        });
        localStorage.setItem(`teamprove.loginBanner-v-${bannerVersion}`, "false");
    };

    render() {

        const {props: {locale}} = this;

        if(!this.state.isVisible){
            return null;
        }
        return (
            <Row noGutters style={{
                borderBottom: "1px solid #e6e6e6",
                background: "whitesmoke",
                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            }}>
                <Col>
                    <Button
                        onClick={this.handleOnClose}
                        variant={"light"}
                        className={"btn-banner"}>
                        <i className="fas fa-times"/>
                    </Button>
                    <div className={"info-banner-wrapper"}>
                        <Row>
                            <Col style={{
                                maxWidth: "80px"
                            }}>
                                <img src={require("../../../assets/icon/package.svg").default} alt=""/>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <h5>The Times They Are a-Changin'</h5>
                                        <p>
                                            {Translator.translate("banner-message", locale, "LOGIN")}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default InfoBanner;
