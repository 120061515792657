import React, {Component} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import './app.scss';
import Routing from "./Routing";
import "@fortawesome/fontawesome-free/css/all.css";
import {Config} from "../../config/Config";
import 'firebase/database';
import 'firebase/analytics';
import firebase from 'firebase/app'

firebase.initializeApp(Config.getFirebaseKeys());

const url = new URL(window.location.href);
const hostname = url.hostname;

if(hostname.startsWith("meet.teamprove.de")){
    firebase.analytics();
}

class App extends Component<{}, {}> {

    setCredentials = (room: string, user: string) => window.location.href = `/room/${room}/${user}`;

    render(){
        return (
            <Router>
                <Routing setCredentials={this.setCredentials}/>
            </Router>
        );
    }
}

export default App;
