import React, {Component} from 'react';
import {Guest, LocaleType, NotificationType, PollType} from "../../../types/Types";
import { Col, Row, Toast} from "react-bootstrap";
import firebase from 'firebase/app';
import 'firebase/database';
import "./notificationCenter.scss";
import Util from "../../util/Util";
import Translator from "../../util/Locale";
import Button from "react-bootstrap/esm/Button";
import VotingResultModal from "../votingResult/VotingResultModal";
const moment = require('moment');

type Props = {
    room: string | null;
    locale: LocaleType;
    user: Guest;
    guestList: Array<Guest>;
    toggleVotingModal: Function;
}

type State = {
    isCollapsed: boolean;
    messages: Array<NotificationType>;
    isNewMessage: boolean;
    poll: PollType | null;
    isPollResultModalShow: boolean;
    messagesQueueSize: number,
}


class NotificationCenter extends Component<Props, State> {
    private fakePoll = React.createRef<HTMLDivElement>() || null;

    state: State = {
        isCollapsed: true,
        messages: [],
        isNewMessage: false,
        poll: null,
        isPollResultModalShow: false,
        messagesQueueSize: 0,
    };

    componentDidMount(): void {
        const self = this;

        firebase.database().ref(`rooms/${this.props.room}/messages`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                const messages: any = snapshot && snapshot.val() ? Object.keys(snapshot.val())
                    .map( (m: any) => snapshot.val()[m])
                    .filter((m: any)=> m.type !== "TEXT" ): [];

                if(this.state.messagesQueueSize <  messages.length){
                    if(self.props.user){
                        //Alert for new message
                        this.setState({isNewMessage: true});
                        if(!this.state.isCollapsed){
                            setTimeout( () => self.setState({isNewMessage: false}), 3000);
                        }
                    }
                }

                this.setState({
                    messagesQueueSize: messages.length,
                    messages: messages
                });
            }
        });

        firebase.database().ref(`rooms/${this.props.room}/messages`).on('child_changed', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                if(self.props.user && !self.props.user.isAdmin){
                    this.setState({isNewMessage: true});
                    if(!this.state.isCollapsed){
                        setTimeout( () => self.setState({isNewMessage: false}), 3000);
                    }
                }

            }
        });

    }

    handleHamburgerToggle = () => {
        this.setState(prevState => ({isCollapsed: !prevState.isCollapsed, isNewMessage: false }),
            ()=> this.scrollToBottom(false)
        );
    };

    showPollResultModal = (poll: PollType | null) => {
        this.setState({
            poll: poll,
            isPollResultModalShow: true
        })
    };

    scrollToBottom = (mode: any) => {
        setTimeout(()=> {
            if(this.fakePoll.current && this.fakePoll.current.scrollIntoView){
                this.fakePoll.current.scrollIntoView(mode);
            }
        }, 100);
    };


    renderPollMessage = (m: NotificationType) =>{
        const {
            props: {toggleVotingModal, locale},
        } = this;

        const poll = m.data || null;
        const pollStatus = poll && poll.config.pollStatus ? poll.config.pollStatus: "FINISHED";
        const timestamp = poll && poll.config.id ? poll.config.id: moment().valueOf();

        let rowVotes = Object.keys(poll.votes || {} ).map(vote => poll.votes[vote] );
        let groupVotes: any = {};
        let winner = 0;

        if(poll.config.selectedDeckName !== "CLOUD" && poll.config.selectedDeckName !== "COLLECT_VOTE"){
            rowVotes = rowVotes.sort(( a: any, b: any ) => a.vote.sortValue - b.vote.sortValue );
            groupVotes = rowVotes.reduce((groups, item) => {
                const group = (groups[item.vote.id] || []);
                group.push(item);
                groups[item.vote.id] = group;
                return groups;
            }, {});
            winner = Object.keys(groupVotes).length > 0 ? Math.max(...Object.keys(groupVotes).map(g => groupVotes[g].length )): 0;
        }

      return(
          <Toast key={m.timestamp} className={`${m.type.toLowerCase()}-toast-item`}>
              <Toast.Header closeButton={false} >
                  <strong className="mr-auto">
                      <i className="fas fa-box"/>&nbsp;&nbsp;
                  </strong>
                  <small>
                      { pollStatus !== "RUNNING"?
                          `${locale !== "DE" ?  Translator.translate("Finished", locale, "NOTIFICATION"): ""} ${moment(timestamp).fromNow()} ${ locale === "DE" ? Translator.translate("Finished", locale, "NOTIFICATION"): ""}`:
                          Translator.translate("Running...", locale, "NOTIFICATION")
                      }
                      &nbsp;&nbsp;<i className={`fas fa-circle ${pollStatus === "RUNNING" ? "green": "red"}`}/>
                  </small>
              </Toast.Header>
              <Toast.Body>
                  <Row noGutters>
                      <Col>
                          {poll && <div className={"question"}>{Util.decodeCharacters(poll.config.question)}</div>}
                          {poll && poll.config.selectedDeckName !== "CLOUD" && poll.config.selectedDeckName !== "TEAM_RADAR" &&
                                <div className="vote-winner-wrapper">
                                  { !poll.config.overRuleVote &&
                                      Object.keys(groupVotes).map((g: any, index: number )=>
                                          {
                                              return groupVotes[g].length !== winner ? null: groupVotes[g][0].vote.id ?
                                                    <img key={index} src={Util.imageActionCardDispatcher(groupVotes[g][0].vote.id)} alt=""/>:
                                                    <div key={index} className={"direct-result"}>{Util.decodeCharacters(groupVotes[g][0].vote)}</div>
                                          }
                                      )
                                  }
                                  { poll.config.overRuleVote && poll.config.overRuleVote.id &&
                                    <img src={Util.imageActionCardDispatcher(poll.config.overRuleVote.id)} alt=""/>
                                  }
                                    { poll.config.overRuleVote && !poll.config.overRuleVote.id &&
                                        <div className={"direct-result"}>{Util.decodeCharacters(poll.config.overRuleVote)}</div>
                                    }
                                </div>
                          }
                      </Col>
                      <Col style={{maxWidth: "100px", display: "flex"}}>
                          <Button className={"float-right btn-see-voting"} variant={pollStatus === "RUNNING" ? "success": "outline-secondary" } size="sm" onClick={ pollStatus === "RUNNING" ? ()=> toggleVotingModal() :  ()=> this.showPollResultModal(poll ? poll: null)  }>
                              {   pollStatus === "FINISHED" &&
                                Translator.translate("See results", locale, "NOTIFICATION")
                              }
                              {   pollStatus === "RUNNING" &&
                                Translator.translate("Vote now!", locale, "NOTIFICATION")
                              }
                          </Button>
                      </Col>
                  </Row>
              </Toast.Body>
          </Toast>
      );
    };

    render() {
        const {
            props: {locale, guestList, user },
            state: { isCollapsed, messages, isNewMessage, poll, isPollResultModalShow}
        } = this;


        if(messages.length === 0){
            return null;
        }
        const polls = messages.filter(m => m.type ==="POLL");
        return (
            <>
                <div className={`notification-center-wrapper ${!isCollapsed? "open": "closed"}` }>
                    <div className="sidebar-header">
                        <img
                            className={`${isNewMessage ? "bell2" : ""}`}
                            onClick={this.handleHamburgerToggle}
                            src={require("../../../assets/icon/notification.svg").default}
                            alt=""/>
                        <span>{Translator.translate("Notification Center", locale, "NOTIFICATION")}</span>
                        <div onClick={this.handleHamburgerToggle} className={`hamburger-toggle ${!isCollapsed? "closed": ""}`}>
                            <span className="icon"/>
                        </div>
                    </div>
                    <Row noGutters className="notification-wrapper">
                        <Col>
                            { polls.length > 0 &&
                                polls.map( (m: NotificationType) =>
                                    this.renderPollMessage(m)
                                )
                            }

                            { !isCollapsed &&
                                <div ref={this.fakePoll} className={"toast fake-toast"}  style={{padding: 0, margin: 0, maxHeight: 0, border: "none"}}/>
                            }
                        </Col>
                    </Row>
                </div>

                {poll && <VotingResultModal
                    onHide={()=> this.setState({isPollResultModalShow: false})}
                    show={isPollResultModalShow}
                    guestList={guestList}
                    locale={locale}
                    poll={poll}
                    user={user}/>}

            </>
        );
    }
}

export default NotificationCenter;
