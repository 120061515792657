import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { Button, Col, Container, Row} from "react-bootstrap";
import firebase from 'firebase/app';
import "../dashboard/portletTable.scss";
import ModalDelete from "../dashboard/ModalDelete";
import moment from 'moment';
import Util from "../../../util/Util";
import Notification from "../dashboard/Notification";

const ActionCell = (row: any)=> {
    return (
        <div className={"cell-action-wrapper"}>
            <Button
                variant={"danger"}
                onClick={()=> row.original.component.setState({
                    showModalDelete: true,
                    record: {
                        id: row.original.id,
                        name: ""
                    }
                })}>
                Delete
            </Button>
        </div>
    );
};

const StandardCell = (cell: any)=>{
    return (
        <div className={`standard-cell`}>
            {cell.value}
        </div>
    )
};

const DateCell = (cell: any)=>{
    return (
        <div className={`standard-cell`}>
            {moment(cell.value).format("DD.MM.YYYY")} <br/> {moment(cell.value).fromNow()}
        </div>
    )
};

const columns = [
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-calendar-alt"/>&nbsp; Date</div>,
        accessor: 'date',
        width: 200,
        minWidth: 200,
        Cell: DateCell

    },
    {
        Header: <div className={"table-th-icon"}><i className="fas fa-user"/>&nbsp; Contact</div>,
        accessor: 'contact',
        Cell: StandardCell
    },
    {
        Header: <div className={"table-th-icon"}><i className="far fa-comment-dots"/>&nbsp; Comment</div>,
        accessor: 'recoveryMessage',
        Cell: StandardCell
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ActionCell,
        width: 150
    }

];

type State = {
    searchField: string,
    loading: boolean,
    selectAll: boolean,
    selection: Array<any>,
    loadingData: boolean,
    showModalDelete: boolean,
    feedback: Array<any>;
    records: Array<any>;
    record: {
        name: string,
        id: string,
    };
};

type Props = {

};

class Recovery extends Component<Props, State> {

    state: State = {
        searchField: "",
        feedback: [],
        loading: false,
        selectAll: false,
        selection: [],
        records: [],
        loadingData: true,
        showModalDelete: false,
        record: {
            name: "",
            id: ""
        }
    };

    componentDidMount(): void {
        const commentsRef = firebase.database().ref(`recovery`);
        commentsRef.on('value', (snapshot: any) => {
            if(snapshot && snapshot.val() ){
                this.setState({
                    loadingData: false,
                    records: this.mapItems(snapshot.val())
                });
            }else{
                this.setState({
                    loadingData: false
                });
            }
        });
    }

    mapItems = (items: any): Array<any> => {
        return Object.keys(items).map((item: any)=> {
            return {
                id: item,
                date: parseInt(items[item].timestamp.toString()),
                contact: Util.decodeCharacters(items[item].contact),
                recoveryMessage: Util.decodeCharacters(items[item].recoveryMessage),
                component: this
            }
        });
    };

    deleteRecord = (id: string) => {
        this.setState({showModalDelete: false});
        firebase.database().ref(`recovery/`).update({[id]: null}).then(()=>{
            this.pushFeedback({
                type: "success",
                title: "Ticket deleted",
                text: `Ticket was deleted!`,
                id: moment.now(),
            })
        });
    };

    pushFeedback = ( feedbackData : any)=>{
        let feedBack = this.state.feedback;
        this.setState({feedback: [feedbackData , ...feedBack]})
    };

    render() {
        const {
            state: { loadingData, feedback, showModalDelete, record , records}
        }= this;


        if(loadingData){
            return (
                <div className={"team-loader"}>
                    <img src={require('../../../../assets/logoCondensed.svg').default} alt=""/>
                </div>
            )
        }
        return (
            <Container fluid className={"portlet-wrapper"} style={{
                maxWidth: "1600px"
            }}>
                <Row noGutters>
                    <Col className={"portlet-header"}>
                        <h4>
                            <i className="far fa-life-ring"/>&nbsp;
                            <span>Session Recovery</span>
                        </h4>
                    </Col>
                </Row>
                <Notification messages={feedback}/>
                <Row noGutters>
                    <Col>
                        {loadingData?
                            <div>loading </div>:
                            <ReactTable
                                data={records}
                                columns={columns}
                                defaultSorted={[
                                    {
                                        id: 'date',
                                        desc: true
                                    }
                                ]}
                            />
                        }
                    </Col>
                </Row>
                <ModalDelete
                    showModal={showModalDelete}
                    onHide={()=> this.setState({showModalDelete: false})}
                    onDelete={()=> this.deleteRecord(record.id)}
                    title={"Delete ticket?"}
                    actionText={"Delete"}
                    message={
                        <span>
                            {`You are about to delete the ticket with id:`} <b>{record.id}</b>
                        </span>
                    }/>
            </Container>
        );
    }
}

export default Recovery;

