import React, {Component} from 'react';
import {Guest, LocaleType, PollType} from "../../../types/Types";
import {Button, Row, Col} from "react-bootstrap";
import Util from "../../util/Util";
import "./collectVote.scss";
import Translator from "../../util/Locale";
import ReactTooltip from "react-tooltip";
import firebase from "firebase";

type Props = {
    poll: PollType | null;
    user: Guest | null;
    locale: LocaleType;
    guestList: any,
    room?: string | null;
};
type State = {

};

class CollectVote extends Component<Props, State> {

    componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): void {

    }

    componentDidMount(): void {
        this.setState({loading: true});

    }

    getCleanVote(poll: any,  user: any, target: string): any {
        let votes = {...poll.votes};

        //REMOVE PREVIOUS VOTE
        Object.keys(votes).forEach((key: string) => {
            if(votes[key].vote){
                votes[key].vote = votes[key].vote.filter((v: any)=> v.user !== user.id);
            }
        });

        //ADD NEW VOTE
        if(!votes[target].vote){
            votes[target].vote = [];
        }
        votes[target].vote.push({
            user: user.id,
            avatar: user.avatar,
            name: user.name
        });
        return votes;
    }

    submitVote(target: string):void {
        const {
            props: {poll, user, room},
        } = this;

        if(room && poll && user){
            firebase.database()
                .ref(`rooms/${this.props.room}/polls/${poll.config.id}/votes/`)
                .update( this.getCleanVote(poll,  user, target)).then();
        }
    }

    proposeIsSelected(target: any): boolean {
        let user = this.props.user? this.props.user.id: null;
        let poll = this.props.poll || null;
        if(!user || !target ||  !poll  || !poll.votes[target].vote){
            return false;
        }
        return poll.votes[target].vote.some((v: any) => v.user === user );
    }

    getVoteCount(): number {
        let user = this.props.user? this.props.user.id: null;
        let poll = this.props.poll || null;
        let totalVotes: any = [];

        if(!user || !poll || !poll.votes ){
            return 0;
        }
        Object.keys(poll.votes).forEach((v:any)=> {
            if(poll && poll.votes[v].vote){
                totalVotes = [...totalVotes,  ...poll.votes[v].vote]
            }
        });
        return totalVotes.length;
    }

    getSortedVotes(): any {
        const { props: {poll, guestList, user}} = this;
        if(poll){
            let totalVoters  = guestList.length > poll.config.totalVoters? guestList.length: (poll.config.totalVoters || 0);
            const allHaveVoted = this.getVoteCount() >= totalVoters;

            if(allHaveVoted || poll.config.pollStatus === "FINISHED"){
                return Object.keys(poll.votes).sort((a: string, b:string) => {
                    let A1 = poll && poll.votes[a] && poll.votes[a].vote ? poll.votes[a].vote.length : 0;
                    let B1 = poll && poll.votes[b] && poll.votes[b].vote ?poll.votes[b].vote.length : 0;
                    return B1 - A1;
                })
            }else{
                let totalVoters  = guestList.length > poll.config.totalVoters? guestList.length: (poll.config.totalVoters || 0);
                let allHaveProposed = poll.votes && Object.keys(poll.votes).length === totalVoters;
                if(allHaveProposed || poll.config.isVotingVisible || (user && user.isAdmin)){
                    return Object.keys(poll.votes);
                }else{
                    return Object.keys(poll.votes).filter((v)=> user && v === user.id )
                }
            }
        }
       return [];
    }



    render() {
        const {
            props: {poll, locale, user, guestList},
        } = this;

        if(!user || !poll || poll.config.selectedDeckName !== "COLLECT_VOTE"){
            return null;
        }

        if(!poll.votes && poll.config.pollStatus === "FINISHED"){
            return  <div style={{padding: "10px"}}>
                {Translator.translate(`No input was given`, locale, "VOTING")}
            </div>;
        }

        let totalVoters  = guestList.length > poll.config.totalVoters? guestList.length: (poll.config.totalVoters || 0);
        let allHaveProposed = poll.votes && Object.keys(poll.votes).length === totalVoters;
        return (
                <div style={{width: "100%"}}>
                    {poll.votes &&
                        <>
                            {poll.config.pollStatus === "RUNNING" &&
                                <Row noGutters>
                                    <Col className="voting-info">
                                        { !poll.config.isVotingVisible ?
                                            <>
                                                <h5>{Translator.translate("Ideas", locale, "VOTING")}</h5>
                                                <span>{Translator.translate(`Idea${totalVoters > 1 ? "s":""}`, locale, "VOTING")}: <b>{Object.keys(poll.votes).length}/{totalVoters} </b></span>
                                            </>:

                                            <>
                                                <h5>{Translator.translate("Results", locale, "VOTING")}</h5>
                                                <span>{Translator.translate(`Vote${totalVoters > 1 ? "s":""}`, locale, "VOTING")}: <b>{this.getVoteCount()}/{totalVoters} </b></span>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            }

                            {((!poll.config.isVotingAnonymous && Object.keys(poll.votes).includes(user.id)) || poll.config.isVotingAnonymous) && this.getSortedVotes().length > 0 &&
                                <div style={{
                                    width: "100%"
                                }} className={"collect-vote-wrapper"}>

                                    {poll.config.isVotingAnonymous && user.isAdmin && !allHaveProposed && !poll.config.isVotingVisible && poll.config.pollStatus === "RUNNING" &&
                                        <div className="hidden-message-collect">
                                            {Translator.translate("Only you can see this preview", locale, "VOTING")}
                                        </div>
                                    }

                                    {this.getSortedVotes().map((target: any, index: number) =>
                                        <div className={"vote-wrapper"} key={target}>
                                            <Row noGutters>
                                                <Col>
                                                    <div className={"vote-option"} style={{marginTop: index === 0 && poll.config.isVotingAnonymous && user.isAdmin && !allHaveProposed && !poll.config.isVotingVisible && poll.config.pollStatus === "RUNNING"? "30px": "0"}}>
                                                        {!poll.config.isVotingAnonymous &&
                                                            <img
                                                                className="voter-avatar"
                                                                src={require(`../../../assets/avatar/${poll.votes[target].avatar}`).default}
                                                                alt={poll.votes[target].name}
                                                                draggable={false}
                                                                data-tip={poll.votes[target].name}
                                                            />
                                                        }
                                                        <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"} />
                                                        <div className="vote-content">
                                                            <span>{Util.decodeCharacters(poll.votes[target].proposal)}</span>
                                                        </div>
                                                        {   poll.config.pollStatus === "RUNNING" &&
                                                            <div className="vote-action d-none d-sm-none d-md-block">
                                                                {( poll.config.showVoting || poll.config.isVotingVisible ) && !poll.config.showFinialVotingResults &&
                                                                    <Button
                                                                            variant={this.proposeIsSelected(target) ? "primary": "secondary"}
                                                                        onClick={()=> this.submitVote(target)}
                                                                    >
                                                                        {  this.proposeIsSelected(target)?
                                                                            <span>{Translator.translate("Voted", locale, "VOTING")}</span>:
                                                                            <span>{Translator.translate("Vote", locale, "VOTING")}</span>
                                                                        }
                                                                    </Button>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>
                                            {poll.config.pollStatus === "RUNNING" &&
                                                <Row noGutters className={"d-block d-sm-block d-md-none sm-100"}>
                                                    <Col>
                                                        <div className="vote-action">
                                                            {( poll.config.showVoting || poll.config.isVotingVisible ) && !poll.config.showFinialVotingResults &&
                                                                <Button
                                                                    variant={this.proposeIsSelected(target) ? "primary": "secondary"}
                                                                    onClick={()=> this.submitVote(target)}
                                                                    style={{width: "100%"}}
                                                                >
                                                                    { this.proposeIsSelected(target)?
                                                                        <span>{Translator.translate("Voted", locale, "VOTING")}</span>:
                                                                        <span>{Translator.translate("Vote", locale, "VOTING")}</span>
                                                                    }
                                                                </Button>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }

                                            { (poll.config.showFinialVotingResults || poll.config.pollStatus === "FINISHED" ) &&
                                                <div className={"vote-counting"}>
                                                    {
                                                        poll.votes[target] && poll.votes[target].vote &&
                                                        <div style={{
                                                            width: `${poll.votes[target].vote.length /this.getVoteCount() * 100}%`,
                                                            transition: "width 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)"
                                                        }}>
                                                            <div className={"vote-percentage"}>{(poll.votes[target].vote.length /this.getVoteCount() * 100).toFixed(0)}%</div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            }
                        </>
                    }
                </div>
        );
    }
}

export default CollectVote;
