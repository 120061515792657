import {LocaleType} from "../../types/Types";

type DictionaryType = "LOGIN" | "TOOLBAR" | "DECK" | "LIST" | "HOME" | "FEEDBACK" | "VOTING" | "MOOD_CHART" | "SIDEBAR" | "NOTIFICATION" | "CHAT"  ;

export default class Translator {

    static getChatDictionary = ()=> {
        return {
            "Chat": {
                "DE":{
                    translation: "Chat"
                },
                "ES":{
                    translation: "Chat"
                }
            },
            "new message": {
                "DE":{
                    translation: "neue Nachricht"
                },
                "ES":{
                    translation: "mensaje nuevo"
                }
            },
            "new messages": {
                "DE":{
                    translation: "neue Nachrichten"
                },
                "ES":{
                    translation: "mensaje nuevo"
                }
            },
            "Your message...": {
                "DE":{
                    translation: "Deine Nachricht..."
                },
                "ES":{
                    translation: "Tu mensaje..."
                }
            },

            "Press ENTER to send": {
                "DE":{
                    translation: "Mit ENTER senden"
                },
                "ES":{
                    translation: "ENTER para enviar"
                }
            },
        }
    };

    static getLoginDictionary = () => {
      return {
          "Share": {
              "DE":{
                  translation: "Teilen"
              },
              "ES":{
                  translation: "Compartir"
              },
              "EN":{
                  translation: "Share"
              }
          },
          "Type in your name": {
              "DE":{
                  translation: "Dein Name:"
              },
              "ES":{
                  translation: "Ingresa tu nombre:"
              },
              "EN":{
                  translation: "Type in your name:"
              }
          },
          "Type in your session name": {
              "DE":{
                  translation: "Dein Sitzungsname"
              },
              "ES":{
                  translation: "Ingresa el nombre de tu sesión"
              }
          },
          "Pick your avatar": {
              "DE":{
                  translation: "Wähle dein Avatar:"
              },
              "ES":{
                  translation: "Selecciona tu avatar:"
              },
              "EN":{
                  translation: "Pick your avatar:"
              }
          },
          "Access": {
              "DE":{
                  translation: "Login"
              },
              "ES":{
                  translation: "Acceder"
              }
          },
          "Create session": {
              "DE":{
                  translation: "Neue Sitzung"
              },
              "ES":{
                  translation: "Nueva sesión"
              },
              "EN":{
                  translation: "New session"
              }
          },
          "Create a new session": {
              "DE":{
                  translation: "Eine neue Sitzung erstellen"
              },
              "ES":{
                  translation: "Crear una nueva sesión"
              },
              "EN":{
                  translation: "Create a new session"
              }
          },

          "Room": {
              "DE":{
                  translation: "Sitzung"
              },
              "ES":{
                  translation: "Sesión"
              }
          },
          "User": {
              "DE":{
                  translation: "Benutzer"
              },
              "ES":{
                  translation: "Usuario"
              }
          },
          "German": {
              "DE":{
                  translation: "Deutsch"
              },
              "ES":{
                  translation: "Alemán"
              }
          },
          "English": {
              "DE":{
                  translation: "Englisch"
              },
              "ES":{
                  translation: "Inglés"
              }
          },
          "Spanish": {
              "DE":{
                  translation: "Spanisch"
              },
              "ES":{
                  translation: "Español"
              }
          },

           "You will join this room as a participant":{
               "DE":{
                   translation: "Du wirst als Teilnehmer einer bestehenden Sitzung teilnehmen"
               },
               "ES":{
                   translation: "Tu te unirás como participante en esta sesión"
               }
           },
           "You will be the owner of the room":{
               "DE":{
                   translation: "Du wirst der Moderator dieser Sitzung sein"
               },
               "ES":{
                   translation: "Tu serás el administrador de esta sesión"
               }
           },

           "length achieved": {
               "DE":{
                   translation: "Länge erreicht"
               },
               "ES":{
                   translation: "Longitud alcanzada"
               }
           },

           "Minimum 10 characters": {
               "DE":{
                   translation: "Mindestens 10 Zeichen"
               },
               "ES":{
                   translation: "Mínimo 10 caracteres"
               }
           },

          "Fill in the form": {
              "DE":{
                  translation: "Fülle alle Felder aus"
              },
              "ES":{
                  translation: "Rellena el formulario"
              }
          },
          "Imprint":{
              "DE":{
                  translation: "Impressum"
              },
              "ES":{
                  translation: "Aviso legal"
              }
          },
          "Data Protection":{
              "DE":{
                  translation: "Datenschutz"
              },
              "ES":{
                  translation: "Protección de datos"
              }
          },
          "Your session name:":{
              "DE":{
                  translation: "Sitzungsname:"
              },
              "ES":{
                  translation: "El nombre de tu sesión:"
              }
          },
          "We are sorry, but it seems that this link is broken or the session you are invited at is expired": {
              "DE":{
                  translation: "Schade, der Link scheint kaputt zu sein oder die Sitzung ist abgelaufen!"
              },
              "ES":{
                  translation: "Lo sentimos, pero parece que la liga esta rota o la sesión a la que has sido invitado es ha expirado"
              }
          },
          "Subscribe":{
              "DE":{
                  translation: "Ja, ich will informiert bleiben"
              },
              "ES":{
                  translation: "Suscríbete"
              },
              "EN":{
                  translation: "Subscribe"
              }
          },

          "banner-message": {
              "DE":{
                  translation: "In der Vergangenheit konntest Du Dich über diese Seite an bestehende Sitzungen einwählen. Von nun an dient diese Seite nur noch zum Erstellen neuer Sitzungen. Versuchst Du gerade an einer Sitzung teilnehmen? Der Moderator deines Meetings muss Dir dafür einen speziellen Link geschickt haben."
              },
              "ES":{
                  translation: "Antes, se podía usar esta pantalla para unirse a reuniones. A partir de ahora, esta pantalla es solo para crear reuniones nuevas. Si estás intentando unirte a una reunión, utiliza el enlace que el moderador te envió."
              },
              "EN":{
                  translation: "In the past, you were able to use this screen to join meetings. From now on, this screen is just to create new meetings. If you are trying to join a meeting, you must use the link that the moderator sent you to get there!"
              }
          },


          "mailchimp-terms-description":{
              "DE":{
                  translation: "Wir benutzen Mailchimp als unsere Marketing Plattform. Wenn Du unten auf Abonnieren klickst, bestätigst du, dass deine E-Mail Adresse zu Mailchimp geschickt wird, um den Prozess zu bearbeiten."
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing."
              }
          },

          "mailchimp-link":{
              "DE":{
                  translation: "Erfahre mehr über Mailchimp's Datenschutzmaßnahmen hier."
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: "Learn more about Mailchimp's privacy practices here."
              }
          },

          "subscribe-check":{
              "DE":{
                  translation: "Teamprove Newsletter abonnieren"
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: " "
              }
          },

          "teamprove-newsletter":{
              "DE":{
                  translation: "Teamprove Newsletter"
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: " "
              }
          },

          "teamprove-terms":{
              "DE":{
                  translation: "Wir entwickeln Teamprove Meet regelmäßig weiter und informieren dich gerne über neue Funktionen und interessante News rund um Remote Working. Mit der Anmeldung zum Newsletter erteilst du uns die Erlaubnis, dass wir dich per Mail auf dem Laufenden halten dürfen. Du kannst dich selbstverständlich jederzeit wieder abmelden. Informationen zu unserer Datenschutzerklärung findest du auf unserer Website."
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: " "
              }
          },
          "Get notified about the next update":{
              "DE":{
                  translation: "Teamprove Meet: Bleib up to date!"
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: " "
              }
          },

          "newsletter":{
              "DE":{
                  translation: "Newsletter"
              },
              "ES":{
                  translation: " "
              },
              "EN":{
                  translation: "Newsletter"
              }
          },



          "Have you lost access to your session?": {
              "DE":{
                  translation: "Hast Du eine Session erstellt und kommst nicht mehr rein?"
              },
              "ES":{
                  translation: "¿Eres tu el moderador y has perdido el acceso a tu sesión?"
              },
              "EN":{
                  translation: "Are you a moderator and have lost access to your session?"
              }
          },

          "Introduce your e-mail and we'll get back to you": {
              "DE":{
                  translation: "Gib deine E-Mail-Adresse ein und wir melden uns bei dir!"
              },
              "ES":{
                  translation: "Envianos tu correo electrónico y nosotros nos pondremos en contacto contigo"
              },
              "EN":{
                  translation: "Introduce your e-mail and we'll get back to you"
              }
          },

          "e-mail address": {
              "DE":{
                  translation: "E-Mail-Addresse"
              },
              "ES":{
                  translation: "Correo electrónico"
              },
              "EN":{
                  translation: "E-mail address"
              }
          },

          "Additional information...": {
              "DE":{
                  translation: "Zusätzliche Information..."
              },
              "ES":{
                  translation: "Información adicional..."
              },
              "EN":{
                  translation: "Additional information..."
              }
          },

          "Recover Session": {
              "DE":{
                  translation: "Zugriffslink anfordern"
              },
              "ES":{
                  translation: "Recuperar sesión"
              },
              "EN":{
                  translation: "Recover Session"
              }
          },








      };
    };

    static getDeckDictionary = () => {
        return {

            "Coffee":{
                "DE":{
                    translation: "Kaffee"
                },
                "ES": {
                    translation: "Café"
                }
            },
            "Bravo":{
                "DE":{
                    translation: "Applaus!"
                },
                "ES": {
                    translation: "Bravo!"
                },
                "EN": {
                    translation: "Applause!"
                },
            },
            "Party":{
                "DE":{
                    translation: "Party"
                },
                "ES": {
                    translation: "¡Hurra!"
                },
                "EN": {
                    translation: "Party"
                },
            },
            "Lol":{
                "DE":{
                    translation: "Lautes Lachen"
                },
                "ES": {
                    translation: "Carcajadas"
                },
                "EN": {
                    translation: "laugh out loud"
                },
            },
            "Heart":{
                "DE":{
                    translation: "Ich mag das!"
                },
                "ES": {
                    translation: "Me gusta!"
                },
                "EN": {
                    translation: "Like!"
                },
            },
            "I'll be back":{
                "DE":{
                    translation: "Bin gleich zurück"
                },
                "ES": {
                    translation: "Ya vuelvo"
                }
            },
            "Let's focus":{
                "DE":{
                    translation: "Lass uns weitermachen"
                },
                "ES": {
                    translation: "Hay que concentrarnos"
                }
            },
            "Yes, and...":{
                "DE":{
                    translation: "Ja, und..."
                },
                "ES": {
                    translation: "Si, y..."
                }
            },
            "I have to leave":{
                "DE":{
                    translation: "Ich muss gehen"
                },
                "ES": {
                    translation: "Tengo que irme"
                }
            },
            "Turn on Webcams":{
                "DE":{
                    translation: "Webcam einschalten"
                },
                "ES": {
                    translation: "Enciendan sus camaras"
                }
            },
            "Time's up":{
                "DE":{
                    translation: "Zeit ist abgelaufen"
                },
                "ES": {
                    translation: "Tiempo fuera"
                }
            },
            "Please, Mute":{
                "DE":{
                    translation: "Bitte stummschalten"
                },
                "ES": {
                    translation: "Desactiva tu micro"
                }
            },
            "Show the screen":{
                "DE":{
                    translation: "Bildschirm teilen"
                },
                "ES": {
                    translation: "Muestra la pantalla"
                }
            },
            "I can't hear you":{
                "DE":{
                    translation: "Ich kann nichts hören"
                },
                "ES": {
                    translation: "No puedo oirte"
                }
            },
            "Yes":{
                "DE":{
                    translation: "Ja"
                },
                "ES": {
                    translation: "Si"
                }
            },
            "No":{
                "DE":{
                    translation: "Nein"
                },
                "ES": {
                    translation: "No"
                }
            }
        }
    };


    static getToolbarDictionary = () => {
        return {
            "How are you feeling?":{
                "DE":{
                    translation: "Wie geht es Dir?"
                },
                "ES": {
                    translation: "¿Cómo te encuentras?"
                }
            },
            "Clear all cards": {
                "DE":{
                    translation: "Alle Karten löschen"
                },
                "ES": {
                    translation: "Eliminar todas las cartas"
                }
            },
            "Share session link": {
                "DE":{
                    translation: "Sitzungslink teilen"
                },
                "ES": {
                    translation: "Compartir liga de la sesión"
                }
            },
            "Copied!":{
                "DE":{
                    translation: "Kopiert!"
                },
                "ES": {
                    translation: "Copiado!"
                }
            },
            "Language":{
                "DE":{
                    translation: "Sprache"
                },
                "ES": {
                    translation: "Idioma"
                }
            },
            "Reset Session": {
                "DE":{
                    translation: "Sitzung zurücksetzen"
                },
                "ES": {
                    translation: "Reciclar sesión"
                }
            },
            "All cards are visible":{
                "DE":{
                    translation: "Alle Karten sind sichtbar"
                },
                "ES": {
                    translation: "Las cartas están visibles"
                },
                "EN": {
                    translation: "All cards are visible"
                }
            },
            "All cards are hidden":{
                "DE":{
                    translation: "Alle Karten sind unsichtbar"
                },
                "ES": {
                    translation: "Las cartas están ocultas"
                }
            },

        "Sort by:":{
                "DE":{
                    translation: "Sortieren nach:"
                },
                "ES": {
                    translation: "Ordenar por:"
                }
            },
        "Name":{
                "DE":{
                    translation: "Name"
                },
                "ES": {
                    translation: "Nombre"
                }
            },
        "Last active user":{
                "DE":{
                    translation: "Letzter aktiver Teilnehmer"
                },
                "ES": {
                    translation: "Último usuario activo"
                }
            },
        "Login order":{
                "DE":{
                    translation: "Login-Reihenfolge"
                },
                "ES": {
                    translation: "Orden de registro"
                }
            },
            "This action will remove all participants of this session": {
                "DE":{
                    translation: "Alle Teilnehmer werden mit dieser Aktion gelöscht"
                },
                "ES": {
                    translation: "Esta acción eliminará a todos los participantes de esta sesión"
                }
            },
            "Cancel": {
                "DE":{
                    translation: "Abbrechen"
                },
                "ES": {
                    translation: "Cancelar"
                }
            },
            "Confirm": {
                "DE":{
                    translation: "Bestätigen"
                },
                "ES": {
                    translation: "Confirmar"
                }
            },

            "Logout":{
                "DE":{
                    translation: "Ausloggen"
                },
                "ES": {
                    translation: "Cerrar sesión"
                }
            },
            "Assign new admin":{
                "EN":{
                    translation: "Manage moderators"
                },
                "DE":{
                    translation: "Moderatoren verwalten"
                },
                "ES": {
                    translation: "Administrar moderadores"
                }
            }
        }
    };

    static getListDictionary = () => {
        return {

            "warning-text": {
                "DE":{
                    translation: "Dieser Link sollte nur von Dir als Moderator der Sitzung verwendet werden, wenn Du den Zugriff auf Deine Sitzung wiederherstellen musst. Nur für Dich. Nicht für andere. Bitte niemals weitergeben!"
                },
                "ES": {
                    translation: "Este enlace sólo debe ser utilizado por ti como moderador de la sesión en caso que necesites recuperar el acceso a tu sesión. Es solo para ti. No para los demás. ¡Por favor, nunca lo compartas!"
                },
                "EN": {
                    translation: "This link should only be used by you as the moderator of the session if you need to regain access to your session. Only for you. Not for others. Please never pass it on!"
                },
            },

            "Delete":{
                "DE":{
                    translation: "Löschen"
                },
                "ES": {
                    translation: "¿Eliminar"
                }
            },
            "Remove Action Card":{
                "DE":{
                    translation: "Diese Karte entfernen?"
                },
                "ES": {
                    translation: "Quitar esta accion"
                }
            },
            "Add others":{
                "DE":{
                    translation: "Niemand hier?"
                },
                "ES": {
                    translation: "¿Nadie más aquí?"
                },
                "EN": {
                    translation: "Nobody here?"
                }
            },

            "You":{
                "DE":{
                    translation: "Du"
                },
                "ES": {
                    translation: "Tu"
                }
            },

            "Share this info with people you want in the meeting": {
                "DE":{
                    translation: "Lade weitere Teilnehmer zu dieser Sitzung ein, indem Du diesen Link weitergibst:"
                },
                "ES": {
                    translation: "Invita a otros participantes a esta sesión compartiendo este enlace:"
                },
                "EN": {
                    translation: "Invite other participants to this session by sharing this link:"
                }
            },
            "Store this item to recover access to your session.": {
                "DE":{
                    translation: "Lade diese Datei herunter, um später auf diese Session zugreifen zu können."
                },
                "ES": {
                    translation: "Guarda este archivo para recuperar el acceso a tu sesión."
                },
                "EN": {
                    translation: "Store this item to recover access to your session."
                }
            },

            "Download session access": {
                "DE":{
                    translation: "Sitzungszugriff herunterladen"
                },
                "ES": {
                    translation: "Descargar acceso a la sesión"
                },
                "EN": {
                    translation: "Download session access"
                }
            },
            "New": {
                "DE":{
                    translation: "Neu!"
                },
                "ES": {
                    translation: "Nuevo"
                }
            },

            "recovery link":{
                "DE":{
                    translation: "Mit diesem Link kommst Du wieder in Deine Teamprove-Meet Session als Moderator rein"
                },
                "ES": {
                    translation: "Con este enlace podrás ingresar a tu sesión de Teamprove-Meet como moderador"
                },
                "EN": {
                    translation: "With this link you can access to your Teamprove-Meet session as moderator"
                }
            },

            "session": {
                "DE":{
                    translation: "Sitzungsname"
                },
                "ES":{
                    translation: "Sesión"
                },
                "EN":{
                    translation: "Session"
                }
            },

        }
    };



    static getHomeDictionary = () => {
        return {
            "How does Teamprove Meet work?": {
                "DE":{
                    translation: "Wie funktioniert Teamprove Meet?"
                },
                "ES": {
                    translation: "¿Cómo funciona Teamprove Meet?"
                }
            },

            "Quite simple:": {
                "DE":{
                    translation: "So einfach:"
                },
                "ES": {
                    translation: "Así de fácil:"
                }
            },

            "Teamprove Meet is a companion for your video or phone call. It doesn't replace a good video conferencing software. It just helps to make online meetings more effective and fun.":{
                "DE":{
                    translation: "Teamprove Meet ist eine Ergänzung zu Video- oder Telefonkonferenzen. Es ist kein Ersatz, sondern soll helfen online meetings erfolgreicher zu machen und macht auch noch Spaß."
                },
                "ES": {
                    translation: "Teamprove Meet es un complemento para teleconferencias, con y sin video. No reemplaza un buen sistema de conferencias. Sirve para mejorar las reuniones online más efectivas y divertidas."
                }
            },

            "You don't need to register, schedule or pay.": {
                "DE":{
                    translation: "Es ist keinerlei Registrierung notwendig. Auch entstehen keinerlei Kosten durch die Nutzung."
                },
                "ES": {
                    translation: "No se requiere registrarse. Tampoco debes pagar por utilizarlo."
                }
            },

            "Just enter your name or an alias, give your session a name and invite other participants.": {
                "DE":{
                    translation: "Nach Eingabe eines Namens (oder Pseudonyms) und einer selbstgewählten Session-ID kann es losgehen. Einfach den Link (per Knopf oben links) kopieren und mit weiteren Meetingteilnehmern teilen."
                },
                "ES": {
                    translation: "Basta que ingreses tu nombre (o un pseudónimo), un nombre para la reunión (de al menos 10 caracteres) y estás listo para empezar. Una vez que hayas ingresado, copia el vínculo y compártelo con los demás participantes."
                }
            },
            "You can create as many sessions as you want, whenever you need them.": {
                "DE":{
                    translation: "Es können beliebig viele Sessions erstellt werden."
                },
                "ES": {
                    translation: "Puedes crear cuantas sesiones quieras."
                }
            },

            "About your data:": {
                "DE":{
                    translation: "Was passiert mit den Daten?"
                },
                "ES":{
                    translation: "¿Qué pasa con mis datos?"
                },
            },


            "Your sessions will live for 21 days. Then, they will be deleted - this is to protect your data.": {
                "DE":{
                    translation: "Jede Session wird 21 Tage aufrecht erhalten, danach wird sie automatisch gelöscht."
                },
                "ES":{
                    translation: "Cada sesión se mantiene durante 21 días y luego es borrada automáticamente."
                },
            },
            "Beyond that period, we will not store any of your data, with exception of anonymized Google Analytics data which we use to improve our service.": {
                "DE":{
                    translation: "Darüberhinaus werden keinerlei weitere Daten erhoben, außer anonymisierte Google Analytics Daten die wir verwenden um das System zu verbessern.\n"
                },
                "ES":{
                    translation: "Más allá de ese periodo, no conservamos ninguna información de tu sesión, salvo información anonimizada en Google Analytics, que nos sirve para entender a nuestros usuarios y mejorar el servicio."
                },
            },
            "And of course, we don't share your data with anyone!": {
                "DE":{
                    translation: "Es werden keine Daten in irgendeiner Weise weitergegeben."
                },
                "ES":{
                    translation: "Por supuesto, no entregaremos tus datos a terceros."
                },
            },
            "Start now!": {
                "DE":{
                    translation: "Los geht es!"
                },
                "ES":{
                    translation: "Comenzar ahora!"
                },
            }

        }
    };


    static getFeedbackDictionary = () => {
        return {

            "User satisfaction":{
                "DE":{
                    translation: "Benutzerzufriedenheit"
                },
                "ES": {
                    translation: "Satisfacción de usuario"
                }
            },

            "Before you logout!":{
                "DE":{
                    translation: "Bevor du dich abmeldest!"
                },
                "ES": {
                    translation: "Antes de que te vayas!"
                }
            },

            "We want to keep improving Teamprove Meet for you, so we would love to hear your feedback.":{
                "DE":{
                    translation: "Wir möchten Teamprove Meet für dich weiter verbessern, daher würden wir uns über dein Feedback freuen."
                },
                "ES": {
                    translation: "Queremos seguir mejorando Teamprove Meet para ti, por lo tanto nos sería de gran ayuda tu  opinión"
                }
            },

            "How was your experience using Teamprove Meet?":{
                "DE":{
                    translation: "Wie war deine Erfahrung mit Teamprove Meet?"
                },
                "ES": {
                    translation: "¿Cómo fue su experiencia con Teamprove Meet?"
                }
            },

            "Hi":{
                "DE":{
                    translation: "Hallo"
                },
                "ES": {
                    translation: "Hola"
                }
            },

            "Send feedback":{
                "DE":{
                    translation: "Feedback senden"
                },
                "ES": {
                    translation: "Enviar opinión"
                }
            },

            "Logout":{
                "DE":{
                    translation: "Abmelden"
                },
                "ES": {
                    translation: "Salir"
                }
            },

            "Delete session":{
                "DE":{
                    translation: "Sitzung löschen"
                },
                "ES": {
                    translation: "Eliminar sesión"
                }
            },

            "leaving now?":{
                "DE":{
                    translation: "gehst du?"
                },
                "ES": {
                    translation: "¿ya te vas?"
                }
            },

            "Click here to logout and keep this current session open, you will still be the owner":{
                "DE":{
                    translation: "Wähle diese Option um dich abzumelden und die Sitzung zu behalten. Du behälst deinen Owner Status."
                },
                "ES": {
                    translation: "Elige esta opción para abandonar la sesión. La sesión se mantendrá abierta y tu seguirás siendo el dueño."
                }
            },

            "Click here to reset the session, all users including yourself will be removed.":{
                "DE":{
                    translation: "Wenn du diese Option auswählst, dann wird die gesammte Sitzung entfernt und alle Teilnehmer (also auch du) werden abgemeldet."
                },
                "ES": {
                    translation: "Si eliges esta opción, la sesión será completamente eliminada y todos los participantes (incluyéndote a tí) serán removidos de la sesión."
                }
            },

            "CAUTION: this action is irreversible!":{
                "DE":{
                    translation: "ACHTUNG: Diese Aktion kann nicht rückgängig gemacht werden!"
                },
                "ES": {
                    translation: "ATENCIÓN: ¡Esta acción no puede ser revertida!"
                }
            },

            "Give us feedback!":{
                "DE":{
                    translation: "Gib uns Feedback!"
                },
                "ES": {
                    translation: "¡Envíanos tu opinión!"
                }
            },

            "Search user":{
                "EN":{
                    translation: "Search participant"
                },
                "DE":{
                    translation: "Teilnehmer suchen"
                },
                "ES": {
                    translation: "Buscar participante"
                }
            },

            "No results for":{
                "DE":{
                    translation: "Keine Ergebnisse für"
                },
                "ES": {
                    translation: "No hay resultados para"
                }
            },

            "Select this user":{
                "EN":{
                    translation: "Make user co-moderator"
                },
                "DE":{
                    translation: "Als Co-Moderator hinzufügen"
                },
                "ES": {
                    translation: "Agregar moderador adicional"
                }
            },
            "Transfer moderator role":{
                "DE":{
                    translation: "Moderatorrolle übergeben"
                },
                "ES": {
                    translation: "Entregar el rol de moderador"
                }
            },

            "Remove moderator role":{
                "DE":{
                    translation: "Moderatorrolle entziehen"
                },
                "ES": {
                    translation: "Eliminar el rol de moderador"
                }
            },

            "Assign new admin to this session":{
                "EN":{
                    translation: "Manage moderators for this session"
                },
                "DE":{
                    translation: "Moderatoren dieser Session verwalten"
                },
                "ES": {
                    translation: "Administrar moderadores para esta sesión"
                }
            },

            "feedback_contact": {
                "DE":{
                    translation: "Möchtest Du Dein Feedback verfolgen können?"
                },
                "ES": {
                    translation: "¿Deseas dar seguimiento a tu comentario? "
                },
                "EN": {
                    translation: "Would you like to keep track of your feedback?"
                }
            },
            "feedback_contact_placeholder": {
                "DE":{
                    translation: "Gib deine E-Mailadresse ein"
                },
                "ES": {
                    translation: "Introduce tu dirección de correo electrónico"
                },
                "EN": {
                    translation: "Introduce your e-mail address"
                }
            },
            "Your feedback has been submitted successfully": {
                "DE":{
                    translation: "Dein Feedback wurde erfolgreich übermittelt"
                },
                "ES": {
                    translation: "Tu comenterio se ha enviado exitosamente"
                }
            },
            "Thank you for the feedback": {
                "DE":{
                    translation: "Vielen Dank für dein Feedback"
                },
                "ES": {
                    translation: "Gracias por tu comentario"
                },
                "EN": {
                    translation: "Thank you for the feedback"
                }
            }
        }
    };


    static getVotingDictionary = () => {
        return {
            "This poll's result has been overwritten!":{
                "DE":{
                    translation: "Das Ergebnis wurde überstimmt!"
                },
                "ES": {
                    translation: "¡El resultado ha sido sobrescrito!"
                },
            },
            "Final decision:":{
                "DE":{
                    translation: "Entgültige Entscheidung:"
                },
                "ES": {
                    translation: "Decisión final:"
                },
            },

            "Override result (Optional)":{
                "DE":{
                    translation: "Ergebnis überstimmen (Optional)"
                },
                "ES": {
                    translation: "Sobrescribir el resultado (Optional)"
                },
            },

            "Override result":{
                "DE":{
                    translation: "Ergebnis überstimmen"
                },
                "ES": {
                    translation: "Sobrescribir el resultado"
                },
            },


            "Results of this poll":{
                "DE":{
                    translation: "Ergebnisse dieser Abstimmung"
                },
                "ES": {
                    translation: "Resultados de esta votación"
                },
                "EN": {
                    translation: "Results of this poll"
                }
            },

            "Voting": {
                "DE":{
                    translation: "Abstimmung"
                },
                "ES": {
                    translation: "Votación"
                },
                "EN": {
                    translation: "Voting"
                }
            },

            "Formulate a question": {
                "DE":{
                    translation: "Gib eine Frage ein"
                },
                "ES": {
                    translation: "Escribe una pregunta"
                },
                "EN": {
                    translation: "Formulate a question"
                }
            },

            "Select a deck": {
                "DE":{
                    translation: "Wähle die Art der Abstimmung aus"
                },
                "ES": {
                    translation: "Elige un tipo de pregunta"
                },
                "EN": {
                    translation: "Select a deck"
                }
            },

            "Poker Planning": {
                "DE":{
                    translation: "Planning Poker"
                },
                "ES": {
                    translation: "Planning Poker"
                },
                "EN": {
                    translation: "Planning Poker"
                }
            },

            "Planning Poker (Fibonacci Cards)": {
                "DE":{
                    translation: "Planning Poker (Fibonacci Karten)"
                },
                "ES": {
                    translation: "Planning Poker (Cartas Fibonacci)"
                }
            },

            "Planning Poker (Free)": {
                "DE":{
                    translation: "Planning Poker (Frei)"
                },
                "ES": {
                    translation: "Planning Poker (Libre)"
                }
            },


            "ROTI (Return On Time Invested)": {
                "DE":{
                    translation: "ROTI (Return On Time Invested)"
                },
                "ES": {
                    translation: "ROTI (Return On Time Invested)"
                },
                "EN": {
                    translation: "ROTI (Return On Time Invested)"
                }
            },

            "Multiple choice 123..": {
                "DE":{
                    translation: "Mehrfachauswahl 123.."
                },
                "ES": {
                    translation: "Opción múltiple 123.."
                },
                "EN": {
                    translation: "Multiple choice 123.."
                }
            },

            "Multiple choice ABC..": {
                "DE":{
                    translation: "Mehrfachauswahl ABC.."
                },
                "ES": {
                    translation: "Opción múltiple ABC.."
                },
                "EN": {
                    translation: "Multiple choice ABC.."
                }
            },


            "poker-description": {
                "DE":{
                    translation: "Ein spielerischer Schätzungsansatz, der von vielen agilen Teams verwendet wird. Der Product Owner gibt kurz den Zweck und den Wert einer Geschichte an. Jedes Teammitglied wählt stillschweigend ein Schätzungswert aus und bereitet die entsprechende Karte verdeckt vor. Wenn alle ihre Wahl getroffen haben, werden die Karten aufgedeckt und die Schätzungen vorgelesen. Die zwei (oder mehr) Teammitglieder, die die höchste und niedrigste Schätzung abgegeben haben, begründen ihre Argumentation. Nach einer kurzen Diskussion kann das Team eine Konvergenz zu einer Konsensschätzung anstreben, indem es eine oder mehrere weitere Runden spielt."
                },
                "ES": {
                    translation: "Un enfoque lúdico para la estimación, utilizado por muchos equipos ágiles. El Product Owner explica brevemente la intención y el valor de una historia. Cada miembro del equipo elige un valor de estimación y prepara la tarjeta correspondiente, sin mostrarla. Cuando todos han elegido, las cartas se ponen boca arriba y las estimaciones se leen en voz alta. Los dos (o más) miembros del equipo que dieron la estimación alta y baja justifican su razonamiento. Después de una breve discusión, el equipo puede buscar la convergencia hacia una estimación consensuada jugando una o más rondas adicionales."
                },
                "EN": {
                    translation: "A playful approach to estimation, used by many Agile teams. The Product Owner briefly states the intent and value of a story. Each member of the team silently picks an estimate and readies the corresponding card, face down. When everyone has taken their pick, the cards are turned face up and the estimates are read aloud. The two (or more) team members who gave the high and low estimate justify their reasoning. After brief discussion, the team may seek convergence toward a consensus estimate by playing one or more further rounds."
                }
            },

            "Roti-description": {
                    "DE":{
                        translation: "ROTI steht für Return on Time Invested. Eine ROTI hilft dabei, Feedback zum Meeting zu generieren und die Effektivität der Sitzung aus Sicht der Teammitglieder zu beurteilen. ROTIs werden am Ende von Besprechungen verwendet."
                    },
                    "ES": {
                        translation: "ROTI significa Return on Time Invested. Un ROTI ayuda a generar feedback sobre la reunión y a medir la efectividad de la sesión desde la perspectiva de los miembros del equipo. Los ROTI se utilizan al final de las reuniones."
                    },
                    "EN": {
                        translation: "ROTI stands for Return on Time Invested. A ROTI helps to generate feedback on the meeting and gauge the effectiveness of the session from the team members’ perspectives. ROTIs are used at the end of meetings."
                    }
            },

            "123-description": {
                "DE":{
                    translation: "Verwende diese Karten, um die Fragen zu stellen, die Du möchtest. Du kannst entweder die Optionen 1,2,3, ... mündlich erläutern oder (empfohlen) Bezeichnungen auf die Optionen schreiben, um den Teilnehmern bei der Auswahl der gewünschten Optionen zu helfen."
                },
                "ES": {
                    translation: "Usa estas cartas para cualquier pregunta que quieras. Puedes explicar las opciones 1,2,3,... verbalmente o (recomendado) escribir etiquetas para las opciones. Eso ayudará a los participantes a elegir lo que quieren."
                },
                "EN": {
                    translation: "Use this deck to ask for any question you want. You can either verbally explain the options 1,2,3,... or (recommended) write a label on the options to help participants to choose what they want."
                }
            },
                "abc-description": {
                    "DE":{
                        translation: "Verwende diese Karten, um die Fragen zu stellen, die Du möchtest. Du kannst entweder die Optionen A,B,C, ... mündlich erläutern oder (empfohlen) Bezeichnungen auf die Optionen schreiben, um den Teilnehmern bei der Auswahl der gewünschten Optionen zu helfen."
                    },
                    "ES": {
                        translation: "Usa estas cartas para cualquier pregunta que quieras. Puedes explicar las opciones A,B,C,... verbalmente o (recomendado) escribir etiquetas para las opciones. Eso ayudará a los participantes a elegir lo que quieren."
                    },
                    "EN": {
                        translation: "Use this deck to ask for any question you want. You can either verbally explain the options A,B,C,... or (recommended) write a label on the options to help participants to choose what they want."
                    }
                },

            "roti-1": {
                "DE":{
                    translation: "Wertlos: ich habe meine Zeit verloren"
                },
                "ES": {
                    translation: "Sin valor: no hubo retorno"
                },
                "EN": {
                    translation: "Lost principle: No benefit received"
                }
            },

            "roti-2": {
                "DE":{
                    translation: "Etwas weniger als 3"
                },
                "ES": {
                    translation: "Algo menos que 3"
                },
                "EN": {
                    translation: "A little less than 3"
                }
            },

            "roti-3": {
                "DE":{
                    translation: "Break-Even: Nutzen gleicht investierte Zeit "
                },
                "ES": {
                    translation: "Break-Even: Valor igual a tiempo invertido"
                },
                "EN": {
                    translation: "Break-Even: Benefit equal to time invested"
                }
            },

            "roti-4": {
                "DE":{
                    translation: "Etwas weniger als 5"
                },
                "ES": {
                    translation: "Algo menos que 5"
                },
                "EN": {
                    translation: "A little less than 5"
                }
            },

            "roti-5": {
                "DE":{
                    translation: "Großer Nutzen: ein wirklich wertvolles Meeting"
                },
                "ES": {
                    translation: "Gran retorno, mucho mayor que el tiempo invertido"
                },
                "EN": {
                    translation: "High return on investment: benefit freater than time invested"
                }
            },

            "No effort": {
                "DE":{
                    translation: ""
                },
                "ES": {
                    translation: " "
                },
                "EN": {
                    translation: "No effort"
                }
            },

            "No idea": {
                "DE":{
                    translation: ""
                },
                "ES": {
                    translation: " "
                },
                "EN": {
                    translation: "No idea"
                }
            },

            "Day": {
                "DE":{
                    translation: ""
                },
                "ES": {
                    translation: " "
                },
                "EN": {
                    translation: "Day"
                }
            },

            "Days": {
                "DE":{
                    translation: ""
                },
                "ES": {
                    translation: " "
                },
                "EN": {
                    translation: "Days"
                }
            },

            "Define label": {
                "DE":{
                    translation: "Bezeichnung"
                },
                "ES": {
                    translation: "Etiqueta"
                },
                "EN": {
                    translation: "Label"
                }
            },

            "Create a poll": {
                "DE":{
                    translation: "Abstimmung starten"
                },
                "ES": {
                    translation: "Comenzar una votación"
                },
                "EN": {
                    translation: "Start a poll"
                }
            },


            "Poll behaviour": {
                "DE":{
                    translation: "Eigenschaften der Abstimmung"
                },
                "ES": {
                    translation: "Características de la votación"
                },
                "EN": {
                    translation: "Poll behaviour"
                }
            },

            "Voting Anonymous": {
                "DE":{
                    translation: "Anonyme Abstimmung"
                },
                "ES": {
                    translation: "Votación anónima"
                },
                "EN": {
                    translation: "Anonymous Poll"
                }
            },

            "Realtime Results": {
                "DE":{
                    translation: "Echtzeitanzeige der Ergebnisse"
                },
                "ES": {
                    translation: "Resultados en tiempo real"
                },
                "EN": {
                    translation: "Real-time results"
                }
            },

            "No one can see who voted what": {
                "DE":{
                    translation: "Niemand wird sehen, wer für was gestimmt hat"
                },
                "ES": {
                    translation: "Nadie podrá ver quién ha votado por cual opción"
                },
                "EN": {
                    translation: "No one can see who voted what"
                }
            },


            "Allow participants to see realtime results after have voted": {
                "DE":{
                    translation: "Aktiviert die Echtzeitanzeige der Ergebnisse für alle Teilnehmer. Ergebnisse sind von Anfang an für alle sichtbar."
                },
                "ES": {
                    translation: "Mostrar los resultados en tiempo real a todos los participantes. Los resultados serán visibles para todos los participantes desde el comienzo."
                },
                "EN": {
                    translation: "Show results real-time to all participants. Results will be visible to everyone from the beginning."
                }
            },

            "Results": {
                "DE":{
                    translation: "Ergebnisse"
                },
                "ES": {
                    translation: "Resultados"
                },
                "EN": {
                    translation: "Results"
                }
            },

            "No Votes so far": {
                "DE":{
                    translation: "Bisher gibt es keine Stimmen"
                },
                "ES": {
                    translation: "No hay votos todavía"
                },
                "EN": {
                    translation: "No votes so far"
                }
            },

            "Voting Time!": {
                "DE":{
                    translation: "Abstimmung läuft!"
                },
                "ES": {
                    translation: "¡A votar!"
                },
                "EN": {
                    translation: "Voting Time!"
                }
            },

            "Votes": {
                "DE":{
                    translation: "Stimmen"
                },
                "ES": {
                    translation: "votos"
                },
                "EN": {
                    translation: "votes"
                }
            },

            "Vote": {
                "DE":{
                    translation: "Stimme"
                },
                "ES": {
                    translation: "voto"
                },
                "EN": {
                    translation: "vote"
                }
            },


            "Close Poll": {
                "DE":{
                    translation: "Abstimmung schließen"
                },
                "ES": {
                    translation: "Terminar votación"
                },
                "EN": {
                    translation: "Close Poll"
                }
            },

            "Results are visible": {
                "DE":{
                    translation: "Ergebnisse sind sichtbar"
                },
                "ES": {
                    translation: "Resultados son visibles"
                },
                "EN": {
                    translation: "Results are visible"
                }
            },

            "Results are hidden": {
                "DE":{
                    translation: "Ergebnisse sind nicht sichtbar"
                },
                "ES": {
                    translation: "Resultados están ocultos"
                },
                "EN": {
                    translation: "Results are hidden"
                }
            },

            "Run Poll": {
                "DE":{
                    translation: "Abstimmung starten"
                },
                "ES": {
                    translation: "Iniciar votación"
                },
                "EN": {
                    translation: "Run Poll"
                }
            },

            "Create a new Poll": {
                "DE":{
                    translation: "Neue Abstimmung starten"
                },
                "ES": {
                    translation: "Comenzar una nueva votación"
                },
                "EN": {
                    translation: "Start a new poll"
                }
            },

            "read more": {
                "DE":{
                    translation: "Weiterlesen"
                },
                "ES": {
                    translation: "Seguir leyendo"
                },
                "EN": {
                    translation: "Read more"
                }
            },

            "read less": {
                "DE":{
                    translation: "Text zuklappen"
                },
                "ES": {
                    translation: "Ocultar texto"
                },
                "EN": {
                    translation: "Read less"
                }
            },
            "Restart the poll": {
                "DE":{
                    translation: "Abstimmung wiederholen"
                },
                "ES": {
                    translation: "Reiniciar la votacion"
                },
                "EN": {
                    translation: "Re-start poll"
                }
            },

            "poll-widget-description":{
                "DE":{
                    translation: "Hier kannst Du Abstimmungen vorbereiten und starten. Es gibt verschiedene Arten von Abstimmungen."
                },
                "ES": {
                    translation: "Aquí puedes preparar e iniciar tus votaciones. Existen diversos tipos de votaciones."
                },
                "EN": {
                    translation: "Here you can prepare and start your polls. There are many different types of polls."
                }
            },

            "page": {
                "DE":{
                    translation: "Seite"
                },
                "ES": {
                    translation: "página"
                },
                "EN": {
                    translation: "page"
                }
            },

            "poll": {
                "DE":{
                    translation: "Abstimmung"
                },
                "ES": {
                    translation: "votación"
                },
                "EN": {
                    translation: "poll"
                }
            },

            "polls": {
                "DE":{
                    translation: "Abstimmungen"
                },
                "ES": {
                    translation: "votaciones"
                },
                "EN": {
                    translation: "polls"
                }
            },

            "No votes for this poll": {
                "DE":{
                    translation: "Schade! Es wurden keine Stimmen für diese Abstimmung gegeben."
                },
                "ES": {
                    translation: "¡Qué lástima! Nadie votó en esta votación."
                },
                "EN": {
                    translation: "What a pity! Nobody voted on this poll."
                }
            },

            "Ready": {
                "DE":{
                    translation: "Fertig"
                },
                "ES": {
                    translation: "Listo"
                },
                "EN": {
                    translation: "Ready"
                }
            },

            "Poll Manager": {
                "DE":{
                    translation: "Verwalte deine Abstimmungen"
                },
                "ES": {
                    translation: "Administra tus votaciones"
                },
                "EN": {
                    translation: "Manage your polls"
                }
            },

            "Show Results": {
                "DE":{
                    translation: "Ergebnisse anzeigen"
                },
                "ES": {
                    translation: "Mostrar resultados"
                },
                "EN": {
                    translation: "Show results"
                }
            },

            "Hide Results": {
                "DE":{
                    translation: "Ergebnisse ausblenden"
                },
                "ES": {
                    translation: "Ocultar resultados"
                },
                "EN": {
                    translation: "Hide results"
                }
            },

            "Add poll": {
                "DE":{
                    translation: "Abstimmung hinzufügen"
                },
                "ES": {
                    translation: "Añadir votación"
                },
                "EN": {
                    translation: "Add poll"
                }
            },

            "Set this behaviour to all polls": {
                "DE":{
                    translation: "Diese Einstellungen für alle Abstimmungen übernehmen"
                },
                "ES": {
                    translation: "Aplicar esta configuración para todas las votaciones"
                },
                "EN": {
                    translation: "Apply this configuration to all existing polls"
                }
            },

            "Only you can see this preview": {
                "DE":{
                    translation: "Nur Du kannst diese Vorschau sehen"
                },
                "ES": {
                    translation: "Solo tú puedes ver esta vista previa"
                },
                "EN": {
                    translation: "Only you can see this preview"
                }
            },

            "You will see the results once this poll is finished": {
                "DE":{
                    translation: "Die Ergebnisse werden angezeigt sobald die Abstimmung beendet ist."
                },
                "ES": {
                    translation: "Los resultados serán visibles una vez que la votación termine"
                },
                "EN": {
                    translation: "You will see the results once this poll is finished"
                }
            },

            "No words so far":{
                "DE":{
                    translation: "Bisher gibt es keine Einträge"
                },
                "ES": {
                    translation: "No hay palabras hasta ahora"
                },
                "EN": {
                    translation: "No words so far"
                }
            },

            "Submit another answer":{
                "DE":{
                    translation: "Noch eine Antwort senden"
                },
                "ES": {
                    translation: "Enviar otra respuesta"
                }
            },

            "Submit": {
                "DE":{
                    translation: "Antwort senden"
                },
                "ES": {
                    translation: "Enviar respuesta"
                },
                "EN": {
                    translation: "Submit answer"
                }
            },

            "Enter a value": {
                "DE":{
                    translation: "Gib ein Wert ein"
                },
                "ES": {
                    translation: "Ingresa un valor"
                },
                "EN": {
                    translation: "Enter a value"
                }
            },

            "Change estimation": {
                "DE":{
                    translation: "Schätzung ändern"
                },
                "ES": {
                    translation: "Modificar estimación"
                }
            },

            "Submit estimation": {
                "DE":{
                    translation: "Schätzung senden"
                },
                "ES": {
                    translation: "Enviar estimación"
                }
            },


            "Write one or more words to answer the question": {
                "DE":{
                    translation: "Schreibe ein oder mehrere Wörter um die Frage zu beantworten"
                },
                "ES": {
                    translation: "Escribe una o más palabras para responder la pregunta"
                }
            },


            "Type in the new result": {
                "DE":{
                    translation: "Wert zum Überschreiben des Ergebnisses (Optional)"
                },
                "ES": {
                    translation: "Valor para sobrescribir el resultado (Opcional)"
                },
                "EN": {
                    translation: "Value to override result (Optional)"
                }
            },
            "Word Cloud": {
                "DE":{
                    translation: "Wortwolke"
                },
                "ES": {
                    translation: "Nube de palabras"
                },
            },
            "Team Radar": {
                "DE":{
                    translation: "Team Radar"
                },
                "ES": {
                    translation: "Team Radar"
                },
            },
            "Collect Votes": {
                "DE":{
                    translation: "Stimmen sammeln"
                },
                "ES": {
                    translation: "Collect Votes"
                },
            },
            "entered_values_radar": {
                "DE":{
                    translation: "Dein Ergebnis"
                },
                "ES": {
                    translation: "Tu resultado"
                },
                "EN":{
                    translation: "Your Result"
                }
            },

            "averaged_values_radar": {
                "DE":{
                    translation: "Teamergebnis"
                },
                "ES": {
                    translation: "Resultado Equipo"
                },
                "EN":{
                    translation: "Team Score"
                }
            }
        }
    };

    static getMoodDictionary = ()=> {
      return {
          "persons are feeling":{
              "DE": {
                  translation: "Personen fühlen sich"
              },
              "ES":{
                  translation: "personas se sienten"
              },
              "EN": {
                  translation: "persons are feeling"
              }
          },
          "person is feeling":{
              "DE": {
                  translation: "Person fühlt sich"
              },
              "ES":{
                  translation: "persona se siente"
              },
              "EN": {
                  translation: "person is feeling"
              }
          },
          "angry":{
              "DE": {
                  translation: "verärgert"
              },
              "ES":{
                  translation: "enojado"
              },
              "EN": {
                  translation: "angry"
              }
          },
          "bored":{
              "DE": {
                  translation: "gelangweilt"
              },
              "ES":{
                  translation: "aburrido"
              },
              "EN": {
                  translation: "bored"
              }
          },
          "puzzled":{
              "DE": {
                  translation: "unentschieden"
              },
              "ES":{
                  translation: "confundido"
              },
              "EN": {
                  translation: "puzzled"
              }
          },
          "happy":{
              "DE": {
                  translation: "zufrieden"
              },
              "ES":{
                  translation: "feliz"
              },
              "EN": {
                  translation: "happy"
              }
          },
          "exited":{
              "DE": {
                  translation: "begeistert"
              },
              "ES":{
                  translation: "emocionado"
              },
              "EN": {
                  translation: "excited"
              }
          }
      }
    };


    static getSidebarDictionary = ()=> {
        return {
            "Send message": {
                "DE":{
                    translation: "Nachricht senden"
                },
                "ES":{
                    translation: "Enviar mensaje"
                },
                "EN":{
                    translation: "Send message"
                }

            },

            "Title": {
                "DE":{
                    translation: ""
                },
                "ES":{
                    translation: ""
                },
                "EN":{
                    translation: "Title"
                }

            }
        }
    };
    static getNotificationDictionary = ()=> {
        return {
            "Send new message": {
                "DE":{
                    translation: "Nachricht an alle Teilnehmer senden"
                },
                "ES":{
                    translation: "Enviar un mensaje a todos los participantes"
                },
                "EN":{
                    translation: "Send new message to all participants"
                }
            },
            "Notification Center": {
                "DE":{
                    translation: "Abstimmungen"
                },
                "ES":{
                    translation: "Votaciones"
                },
                "EN":{
                    translation: "Polls"
                }
            },
            "placeholder-text-message": {
                "DE":{
                    translation: "Deine Nachricht..."
                },
                "ES":{
                    translation: "Tu mensaje..."
                },
                "EN":{
                    translation: "Your message..."
                }
            },

            "See results": {
                "DE":{
                    translation: "Ergebnisse anzeigen"
                },
                "ES":{
                    translation: "Ver resultados"
                },
                "EN":{
                    translation: "See results"
                }
            },
            "Vote now!": {
                "DE":{
                    translation: "Abstimmung läuft!"
                },
                "ES":{
                    translation: "¡Votar ahora!"
                },
                "EN":{
                    translation: "Vote now!"
                }
            },
            "Running...": {
                "DE":{
                    translation: "Läuft..."
                },
                "ES":{
                    translation: "En curso"
                },
                "EN":{
                    translation: "Running..."
                }
            },
            "Finished": {
                "DE":{
                    translation: " abgeschlossen"
                },
                "ES":{
                    translation: "Cerrada "
                },
                "EN":{
                    translation: "Finished "
                }
            },
            "Poll": {
                "DE":{
                    translation: "Abstimmung"
                },
                "ES":{
                    translation: "Votación"
                },
                "EN":{
                    translation: "Poll"
                }
            },
            "Message": {
                "DE":{
                    translation: "Nachricht"
                },
                "ES":{
                    translation: "Mensaje"
                },
                "EN":{
                    translation: "Message"
                }
            },
            "Messages": {
                "DE":{
                    translation: "Nachrichten"
                },
                "ES":{
                    translation: "Mensajes"
                },
                "EN":{
                    translation: "Messages"
                }
            },
            "Polls": {
                "DE":{
                    translation: "Abstimmungen"
                },
                "ES":{
                    translation: "Votaciones"
                },
                "EN":{
                    translation: "Polls"
                }
            }

        }
    };

    static findDictionary = (dictionary: DictionaryType) => {
        switch (dictionary) {
            case "LOGIN": return Translator.getLoginDictionary();
            case "TOOLBAR": return Translator.getToolbarDictionary();
            case "DECK": return Translator.getDeckDictionary();
            case "LIST": return Translator.getListDictionary();
            case "HOME": return Translator.getHomeDictionary();
            case "FEEDBACK": return Translator.getFeedbackDictionary();
            case "VOTING": return Translator.getVotingDictionary();
            case "MOOD_CHART": return Translator.getMoodDictionary();
            case "SIDEBAR":return Translator.getSidebarDictionary();
            case "NOTIFICATION":return Translator.getNotificationDictionary();
            case "CHAT": return Translator.getChatDictionary();
        }
    };

    static getTranslation = (translations: Object, ...args: any) => {
        return args.reduce((translations:any, level:any) => translations && translations[level], translations);
    };

    static translate = (word: string, locale: LocaleType, dictionary: DictionaryType) => {
       const translation =  Translator.getTranslation(Translator.findDictionary(dictionary), word, locale, 'translation');
       return !translation ? word : translation;
    };

}
