import React, {Component} from 'react';
import {Guest, LocaleType, NotificationType} from "../../../types/Types";
import {Col, Container, Row, Toast, Form, Button, Badge} from "react-bootstrap";
import firebase from 'firebase/app';
import 'firebase/database';
import Util from "../../util/Util";
import Translator from "../../util/Locale";
import "./chat.scss";

const moment = require('moment');

type Props = {
    room: string | null;
    locale: LocaleType;
    user: Guest;
    guestList: Array<Guest>;
    showChat: boolean;
    isChatExpanded: boolean;
    toggleChat: Function;
    expandChat: Function;
}

type State = {
    messages: Array<NotificationType>;
    isNewMessage: boolean;
    messagesQueueSize: number,
    lastMessageId: number,
    text: string,
    unreadMessages: number, //messages shown on top
    sendOnEnter: boolean,
    isTextareaCollapsed: boolean,
    showScroller: boolean,
    scrollerCounter: number,
    notificationSound: any | null,
    remainingUnreadMessages: number, //messages show in the scroller

}

class Chat extends Component<Props, State> {

    private inputValue = React.createRef<any>() || null;
    private scrollbar = React.createRef<HTMLDivElement>() || null;
    private chatBox = React.createRef<HTMLDivElement>() || null;
    state: State = {
        messages: [],
        isNewMessage: false,
        messagesQueueSize: 0,
        lastMessageId: 0,
        text: "",
        unreadMessages: 0,
        sendOnEnter: true,
        isTextareaCollapsed: true,
        showScroller: true,
        scrollerCounter: 0,
        notificationSound: null,
        remainingUnreadMessages: 0
    };

    submitMessage = (text: string)=> {
        const {
            props: {user, room}
        } = this;
        let messageTime = moment().valueOf();
        if(text.trim() !== ""){
            firebase.database().ref(`rooms/${room}/messages/${messageTime}`).update({
                    text: Util.encodeCharacters(text),
                    type: "TEXT",
                    timestamp: messageTime,
                    data: [],
                    user: user
                }
            ).then(()=> {
                firebase.analytics().logEvent("send-info-message");
            });
        }
        this.scrollToBottom();
        this.setState({text: ""});
    };

    componentDidMount(): void {
        firebase.database().ref(`rooms/${this.props.room}/messages`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                //GET ONLY MESSAGES THAT ARE TYPE TEXT
                let messages: any = snapshot && snapshot.val() ? Object.keys(snapshot.val())
                    .map( (m: any) => snapshot.val()[m])
                    .filter((m: any)=> m.type === "TEXT" ): [];

                if(this.state.messagesQueueSize <  messages.length && this.state.messagesQueueSize > 0){
                    this.updateNewMessagesCounter();
                    Util.loadAudio(require("../../../assets/sounds/notification.mp3").default);
                }

                //Ensure we only react on text messages
                if(this.state.messagesQueueSize <  messages.length){
                    this.setState({
                        messagesQueueSize: messages.length,
                        showScroller: true,
                        messages: messages
                    });
                    if(this.state.showScroller){
                        this.setState( prevState => ({remainingUnreadMessages: prevState.remainingUnreadMessages + 1}))
                    }
                }


            }
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.showChat !== this.props.showChat && this.props.showChat){
            this.scrollToBottom();
        }
    }

    updateNewMessagesCounter = ()=>{
        if(!this.props.showChat){
            this.setState(prevState => ({unreadMessages: prevState.unreadMessages+1} ));
        }
    };

    scrollToBottom = () => {
        setTimeout(()=> {
            if(this.chatBox.current && this.chatBox.current.scrollIntoView){
                this.chatBox.current.scrollIntoView({block: "end", behavior: "smooth"});
            }
            this.setState({showScroller: false});
            setTimeout(()=>{
                if( this.inputValue && this.inputValue.current){
                    this.inputValue.current.focus();
                }
            }, 1000);
        }, 100);
    };

    handleOnChatExpand = () => {
        this.props.toggleChat(!this.props.showChat);
        if(this.state.unreadMessages > 0 ){
            this.scrollToBottom();
            setTimeout(()=>{
                this.setState({unreadMessages: 0})
            }, 1000);
        }
    };

    handleKeyPressEnter =  (e: any) => {
        if(e.key === 'Enter' && this.state.sendOnEnter){
            e.preventDefault();
            this.submitMessage(this.state.text);
            this.setState({text: ""});
        }
    };

    handleScrolling = ()=> {
        let scrollHeight = this.scrollbar && this.scrollbar.current? this.scrollbar.current.scrollHeight: 0;
        let clientHeight = this.scrollbar && this.scrollbar.current? this.scrollbar.current.clientHeight: 0;
        let scrollTop = this.scrollbar && this.scrollbar.current? this.scrollbar.current.scrollTop: 0;

        if( scrollHeight   === (scrollTop + clientHeight)){
            this.setState({showScroller: false, remainingUnreadMessages: 0})
        }else{
            if(!this.state.showScroller){
                this.setState({showScroller: true});
            }
        }
    };

    render() {
        const {
            props: {locale, showChat, isChatExpanded, expandChat },
            state: {  messages, lastMessageId, text, unreadMessages, sendOnEnter, isTextareaCollapsed, showScroller, remainingUnreadMessages}
        } = this;

        return (
            <div className={`chat-widget-wrapper ${showChat? "open": "closed"} ${isChatExpanded && showChat? "expanded": ""}`}>
                <div className={"chat-widget-header"}  onClick={this.handleOnChatExpand} >
                    <div className={"chat-icon"}>
                        <i className="fas fa-comments"/>
                    </div>
                    { unreadMessages === 0 &&
                        <div className={"chat-new-messages-counter"}>
                            <div className={"title"}>{ Translator.translate("Chat", locale, "CHAT")}</div>
                        </div>
                    }
                    {unreadMessages > 0 &&
                        <div className={"chat-new-messages-counter"}>
                            <h6>
                                <Badge variant={"primary"}>
                                    {unreadMessages}&nbsp;
                                    {unreadMessages === 1 ?
                                            Translator.translate("new message", locale, "CHAT"):
                                            Translator.translate("new messages", locale, "CHAT")
                                    }
                                </Badge>
                            </h6>
                        </div>
                    }
                    {showChat &&
                        <div className={"expander"} onClick={(e: any)=> {
                            e.preventDefault();
                            e.stopPropagation();
                            expandChat()
                        }}>
                            <i  className={`fas ${isChatExpanded? "fa-compress-alt": "fa-expand-alt"}`}/>
                        </div>
                    }
                    <div className={"toggle"}>
                        <i  className={`fas ${!showChat? "fa-chevron-up": "fa-chevron-down"}`}/>
                    </div>
                </div>

                <div
                    onScroll={this.handleScrolling}
                    ref={this.scrollbar}
                    className={`chat-widget-body scrollbar ${!showChat || !isTextareaCollapsed? "closed":  "open"}`}>

                        <div className={`scroller ${showScroller? "shown": "hidden"} ${remainingUnreadMessages > 0 ? "withNumber": ""}`} onClick={this.scrollToBottom}>
                            <i className="fas fa-arrow-circle-down"/>
                            {remainingUnreadMessages > 0 &&
                                <span>
                                    {remainingUnreadMessages < 100? remainingUnreadMessages: "+99"}
                                </span>
                            }
                        </div>

                    { messages.map( (m: NotificationType) =>
                        <TextMessage
                            key={m.timestamp}
                            message={m}
                            activeMessage={lastMessageId}
                        />
                    )}
                    <div ref={this.chatBox} className={"toast fake-toast"} />
                </div>

                <Container fluid className={`chat-controls ${showChat? "open": "closed"}`}>

                    <div className="btn-expand-text" onClick={()=> this.setState(prevState=>({isTextareaCollapsed: !prevState.isTextareaCollapsed}))}>
                        <i  className={`fas ${isTextareaCollapsed? "fa-expand-alt": " fa-compress-alt"}`}/>
                    </div>

                    <Row noGutters>
                        <Col>
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        placeholder={Translator.translate("Your message...", locale, "CHAT")}
                                        as="textarea"
                                        className={`${isTextareaCollapsed ? "collapsed":"expanded"}  ${sendOnEnter? "whites": ""}`}
                                        ref={this.inputValue}
                                        rows={0}
                                        value={text}
                                        onChange={(e: any)=> this.setState({text: e.target.value})}
                                        onKeyPress={this.handleKeyPressEnter}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                    <Row noGutters>
                        <Col>
                            <Form.Group controlId="sendOnEnter" className={"enterCheck"}>
                                <Form.Check type="checkbox"
                                            checked={sendOnEnter}
                                            onChange={()=> this.setState(prevState => ({sendOnEnter: !prevState.sendOnEnter}))}
                                            label={Translator.translate("Press ENTER to send", locale, "CHAT")} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formBasicCheckbox">
                                <Button
                                    className="send-btn"
                                    variant={"primary"}
                                    disabled={text.trim() === ""}
                                    onClick={()=> this.submitMessage(text)}>
                                    <i className="fas fa-paper-plane"/>
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Chat;


type StateTextMessage = {active: boolean};

class TextMessage extends Component<{message: NotificationType, activeMessage: number}, StateTextMessage> {

    state: StateTextMessage = {
        active: this.props.message.timestamp === this.props.activeMessage,
    };

    componentDidUpdate(prevProps: Readonly<{ message: NotificationType; activeMessage: number }>, prevState: Readonly<StateTextMessage>, snapshot?: any): void {
        if((prevProps.message.timestamp !== this.props.activeMessage) && this.state.active){
            this.setState({active: false});
        }
    }

    componentDidMount(): void {
        setTimeout(()=>{
            this.setState({active: false});
        }, 10000);
    }

    URLify = (text: string ) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = text;
        text = tmp.textContent || tmp.innerText || "";

        const urlRegex = /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;

        let html = text.replace(/\n/g ," <br/> " ).replace(urlRegex, function(url) {
            let finalURL = url.startsWith("http") || url.startsWith("https") ? url: `http://${url}`;
            return `<a href="${finalURL}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });

        return <span dangerouslySetInnerHTML={{__html: html}}/>
    };

    render (){
        const {
            props: {message},
            state: {active}
        } = this;

        const messageOwner = message.user? message.user : {name: "", avatar: null};

        return(
            <Toast
                key={message.timestamp}
                className={`chat-toast-item ${ active ? "activeMsn": ""}`}>
                <Toast.Header closeButton={false}>
                    {messageOwner.avatar &&
                        <span className={"chat-avatar"}>
                            <img src={require(`../../../assets/avatar/${messageOwner.avatar}`).default} alt=""/>
                        </span>
                    }
                    <span className={"owner"}>{messageOwner.name}</span>
                    <small>{moment(message.timestamp).fromNow()}</small>
                </Toast.Header>
                <Toast.Body>
                    {this.URLify(Util.decodeCharacters(message.text))}
                </Toast.Body>
            </Toast>
        );
    }
}
