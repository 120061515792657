import React, {Component} from 'react';
import {Container, Modal} from "react-bootstrap";
import Translator from "../../util/Locale";
import {Guest, LocaleType, PollType} from "../../../types/Types";
import VotingResult from "./VotingResult";
import Cloud from "../cloud/Cloud";
const moment = require('moment');

type Props = {
    show: boolean;
    poll: PollType;
    locale: LocaleType;
    user: Guest;
    guestList: Array<Guest>;
    onHide: Function
}

class VotingResultModal extends Component<Props, {}> {

    render() {
        const {
            props: {show, poll, locale, user, guestList, onHide}
        } = this;
        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={()=> onHide()}
                className={"voting-wrapper"}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("Results of this poll", locale, "VOTING")}
                        <span>&nbsp;{moment(parseInt(poll.config.id)).format("DD/MM/YYYY HH:mm")}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        { poll.config.selectedDeckName !== "CLOUD" &&
                            <VotingResult poll={poll} user={user} locale={locale} guestList={guestList}/>
                        }

                        { poll.config.selectedDeckName === "CLOUD" &&
                            <Cloud poll={poll} user={user} locale={locale} guestList={guestList} room={null}/>
                        }
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

export default VotingResultModal;
