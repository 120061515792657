import React, {Component } from 'react'
import {Button, Col, OverlayTrigger, Popover} from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import "./timer.scss";
import {Guest} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';

const moment = require('moment');
type Props = {
    timer: {isTimerRunning: boolean, totalSeconds: number, timeStamp: number} | null;
    room: string;
    user: Guest;
};

type State = {
    totalSeconds: number;
    countDown: number,
    isTimerRunning: boolean;
    minutes: number;
    seconds: number;
}

const audio =  require('../../../assets/sounds/proximity.ogg').default;

export default class Timer extends Component<Props, State> {
    private interval: any;
    private audioTimer: HTMLAudioElement = new Audio(audio);

    state:State = {
        totalSeconds: this.props.timer ? this.props.timer.totalSeconds : 0,
        countDown: this.props.timer ? this.props.timer.totalSeconds : 0,
        isTimerRunning: this.props.timer ? this.props.timer.isTimerRunning : false,
        minutes: 0,
        seconds: 0
    };

    stopTimerDB = ()=> {
        let user = this.props.user;
        if(user && user.isAdmin){
            firebase
                .database()
                .ref(`rooms/${this.props.room}/config/timer`)
                .update({totalSeconds: 0, isTimerRunning: false, timeStamp: moment().valueOf()})
                .then(()=>{
                    this.stopTimer();
                });
        }
    };

    pauseTimerDB = ()=> {
        let user = this.props.user;
        if(user && user.isAdmin){
            firebase.database()
                .ref(`rooms/${this.props.room}/config/timer`)
                .update({totalSeconds: this.state.countDown, isTimerRunning: false, timeStamp: moment().valueOf()})
                .then(()=>{
                   this.pauseTimer();
                });
        }
    };

    startTimerDB = ()=> {
        let user = this.props.user;
        if(user && user.isAdmin){
            firebase.database()
                .ref(`rooms/${this.props.room}/config/timer`)
                .update({totalSeconds: this.state.countDown, isTimerRunning: true, timeStamp: moment().valueOf()})
                .then(()=>{
                    this.startTimer();
                });
        }
    };

    stopTimer = ()=>{
        clearInterval(this.interval);
        this.setState({
            minutes: 0,
            seconds: 0,
            countDown: 0,
            totalSeconds: 0,
            isTimerRunning: false
        })
    };

    pauseTimer = ()=>{
        clearInterval(this.interval);
        this.setState({
            isTimerRunning: false
        })
    };

    startTimer = ()=>{
        this.setState({minutes: 0, seconds: 0});

        this.interval = setInterval(() => {
            const { countDown } = this.state;

                if(countDown === 1){
                    try {
                        this.audioTimer.play().catch(()=>{
                            alert("Times up!");
                        });
                    }catch (e) {
                        alert("Times up!");
                    }
                }

                if (countDown === 0) {
                    clearInterval(this.interval);
                    this.stopTimer();
                    this.setState({isTimerRunning: false});
                } else {
                    this.setState(({ countDown }) => ({
                        countDown: countDown - 1,
                    }))
                }
        }, 1000)
    };

    toggleTimer = ()=>{
        const { isTimerRunning } = this.state;
        let user = this.props.user;

        if(!isTimerRunning){
            if(this.state.countDown === 0){
                let totalSeconds = (parseInt(this.state.minutes.toString()) * 60) + parseInt(this.state.seconds.toString());
                if(user && user.isAdmin){
                    firebase.database().ref(`rooms/${this.props.room}/config/timer`).update({ totalSeconds: totalSeconds, isTimerRunning: true, timeStamp: moment().valueOf()}).then();
                }
                this.setState({countDown: totalSeconds, totalSeconds:totalSeconds });
            }
            this.startTimerDB();
            //this.setState({isTimerRunning: true});
        }else{
           // this.setState({isTimerRunning: false});
            this.pauseTimerDB();
        }

        this.setState(prevState => ({isTimerRunning: !prevState.isTimerRunning}));

    };

    setMinutes = (e: any)=> {
        let value =  e.target.value.replace(/-/g, 0);
        if(value < 0){
            value = 0;
        }
        let totalSeconds = (parseInt(value) * 60) + parseInt(this.state.seconds.toString());
        this.setState({minutes: value, totalSeconds:  isNaN(totalSeconds) ? 0 : totalSeconds, countDown:  isNaN(totalSeconds) ? 0 : totalSeconds});


    };
    setSeconds = (e: any)=> {
        let value =  e.target.value.replace(/-/g, 0);

        if(value > 60){
            value = 60;
        }
        if(value < 0){
            value = 0;
        }
        let totalSeconds =  parseInt(this.state.minutes.toString()) * 60 + parseInt(value);
        this.setState({seconds: parseInt(value.toString()), totalSeconds:  isNaN(totalSeconds) ? 0 : totalSeconds, countDown: isNaN(totalSeconds) ? 0 : totalSeconds});
    };


    getTotalSeconds = () =>{
        let timer = this.props.timer;
        let totalSeconds = 0;
        if(timer && timer.isTimerRunning && timer.totalSeconds > 0){
            const a = moment(parseInt( timer.timeStamp.toString()));
            const b = moment();
            const diff = a.diff(b, 'seconds');
            totalSeconds = parseInt( timer.totalSeconds.toString()) + diff;
        }
        return totalSeconds;
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {

        if(this.getTotalSeconds() < 0 && this.props.timer && this.props.timer.isTimerRunning){
            this.stopTimerDB();
        }

        if(this.props.timer && this.state.isTimerRunning !== this.props.timer.isTimerRunning){
            this.setState({isTimerRunning: this.props.timer.isTimerRunning});
            if(!this.props.timer.isTimerRunning){
                this.pauseTimerDB();
            }
        }
        if(((prevProps.timer === null && this.props.timer) ||
            (this.props.timer && prevProps.timer && this.props.timer.timeStamp !==  prevProps.timer.timeStamp)) &&
            !this.state.isTimerRunning
        ){
            let timer = this.props.timer;
            if( timer && timer.totalSeconds > 0){
                const a = moment(parseInt( timer.timeStamp.toString()));
                const b = moment();
                const diff = a.diff(b, 'seconds');
                const totalSeconds = parseInt( timer.totalSeconds.toString()) + diff;
                this.setState({
                    totalSeconds: totalSeconds > 0 ? totalSeconds  : 0,
                    countDown: totalSeconds > 0 ? totalSeconds  : 0,
                    isTimerRunning: totalSeconds > 0,
                });
                this.pauseTimer();
                if(timer.isTimerRunning){
                    this.startTimer();
                }
            }else{
                this.stopTimer();
            }
        }
    }

    render() {
        const {countDown, isTimerRunning, seconds, minutes, totalSeconds } = this.state;
        const {user} = this.props;

        if(countDown === 0 && totalSeconds === 0 && user && !user.isAdmin){
            return null;
        }

        return (
            <div className={"timer-wrapper"}>
                {user && user.isAdmin &&
                <OverlayTrigger
                    trigger="click"
                    placement={"bottom"}
                    rootClose={true}
                    rootCloseEvent={'mousedown'}
                    overlay={
                        <Popover
                            id={`popover-positioned-bottom`}
                            className={"timer-controls"} >

                            <Popover.Content>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Control type={"number"}  min={0} value={minutes} onChange={this.setMinutes} disabled={isTimerRunning}/>
                                        </Form.Group>
                                        <span>:</span>
                                        <Form.Group as={Col} controlId="formGridState">
                                            <Form.Control type={"number"} max={60} min={0} value={seconds} onChange={this.setSeconds} disabled={isTimerRunning}/>
                                        </Form.Group>
                                        <Button variant={"light"} onClick={this.toggleTimer}>
                                            {isTimerRunning ? <i className="fas fa-pause"/>: <i className="fas fa-play"/>}
                                        </Button>
                                        <Button variant={"light"} onClick={this.stopTimerDB}>
                                            <i className="fas fa-stop"/>
                                        </Button>
                                    </Form.Row>
                                </Form>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <Button className={`${countDown === 1 && totalSeconds !== 0? "bell": ""}`}>
                        <img src={require('../../../assets/icon/timer.svg').default} alt="" draggable={false}/>
                        { countDown === 0 && totalSeconds !== 0
                            ? <h6>Time's up</h6>
                            : <h2 className={countDown >= 3599 ? "smallTime": ""}>{moment.utc(countDown*1000).format(countDown >= 3599 ? 'HH:mm:ss': 'mm:ss')}</h2>
                        }
                    </Button>
                </OverlayTrigger>
                }

                {user && !user.isAdmin &&
                    <Button className={`${countDown === 1 && totalSeconds !== 0? "bell": ""}`}>
                        <img src={require('../../../assets/icon/timer.svg').default} alt="" draggable={false}/>
                        { countDown === 0 && totalSeconds !== 0
                            ? <h6>Time's up</h6>
                            : <h2 className={countDown >= 3599 ? "smallTime": ""}>{moment.utc(countDown*1000).format(countDown >= 3599 ? 'HH:mm:ss': 'mm:ss')}</h2>
                        }
                    </Button>
                }
            </div>
        )
    }
}
