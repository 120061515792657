
const url = new URL(window.location.href);
const hostname = url.hostname;

export class Config {
    static getFirebaseKeys = () => {
        let devKeys =  {
            apiKey: "AIzaSyAALX9eMEcEChpWgOukVBtBsyEnM9x89FE",
            authDomain: "meets-4a28a.firebaseapp.com",
            databaseURL: "https://meets-4a28a.firebaseio.com",
            projectId: "meets-4a28a",
            storageBucket: "meets-4a28a.appspot.com",
            messagingSenderId: "851584268754",
            appId: "1:851584268754:web:da85df68dd08423186f00b",
            measurementId: "G-D0BBQJ71E7"
        };
        let prodKeys =  {
            apiKey: "AIzaSyAInpnckUSGTwtOgledj8Wi7UsOitFe938",
            authDomain: "teamprove-meet.firebaseapp.com",
            databaseURL: "https://teamprove-meet.firebaseio.com",
            projectId: "teamprove-meet",
            storageBucket: "teamprove-meet.appspot.com",
            messagingSenderId: "442224730225",
            appId: "1:442224730225:web:9be29a30b3abeeb2a7bbd0",
            measurementId: "G-X7FLQHC5VJ"
        };
        return process.env.REACT_APP_STAGE === "dev" || hostname.startsWith("meets-4a28a.web.app") ? devKeys : prodKeys
    }
}
