import React, {Component} from 'react';
import {
    Row,
    Col,
    ButtonGroup,
    DropdownButton,
    Button,
    Navbar,
    Nav,
    Form,
    FormControl,
    Modal,
    Container
} from "react-bootstrap";
import './toolbar.scss';
import {Dropdown} from "react-bootstrap";
import Translator from "../../util/Locale";
import {Guest, LocaleType, SortBy} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';
import FeedbackModal from "../feedback/FeedbackModal";
import Util from "../../util/Util";
import LogoutModal from "../logoutModal/LogoutModal";
import Timer from "../timer/Timer";
import MoodWidget from "../moodWidget/MoodWidget";
import NewAdminModal from "../newAdminModal/NewAdminModal";
const moment = require('moment');

type Props = {
    room: string,
    user: Guest;
    locale: LocaleType;
    setLocale: Function;
    guestList: any;
    moodTrend: Array<any>;
    setSortBy: Function;
    sortedBy: SortBy;
    timer: {isTimerRunning: boolean, totalSeconds: number, timeStamp: number} | null;
    roomName: string,
}

type State = {
    sharedLinkClicked: boolean;
    actionIsOpen: boolean;
    modalResetShow: boolean;
    modalFeedbackShow: boolean;
    modalNewAdminShow: boolean;
    isFeedBackTriggerByUser: boolean;
    modalLogoutShow: boolean;
    sortedBy: string
}

class Toolbar extends Component<Props, State> {

    state: State = {
        sharedLinkClicked: false,
        actionIsOpen: false,
        modalResetShow: false,
        modalFeedbackShow: false,
        modalNewAdminShow: false,
        isFeedBackTriggerByUser: false,
        modalLogoutShow: false,
        sortedBy: this.props.sortedBy
    };

    onUnload = () => {
        if(this.props.user && !this.props.user.isAdmin){
            this.logout();
        }
        return true;
    };

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);
    }

    setMood = (mood: string) => {
        if( this.props.user && mood !== this.props.user.mood  ){
            localStorage.setItem("teamprove.mood", mood);
        }else{
            localStorage.removeItem("teamprove.mood");
        }

        firebase.database().ref(`rooms/${this.props.room}/users/${this.props.user.id}`).update({mood: mood === this.props.user.mood ? null: mood }
        ).then();

        const roundUpTo = (roundTo: number ) => (x: number) => Math.ceil(x / roundTo) * roundTo;
        const roundUpToHalfMinute = roundUpTo(1000 * 30 );

        let guestList = this.props.guestList;

        let mood5 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "5").filter(m => m === true).length || 0 ;
        let mood4 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "4").filter(m => m === true).length || 0 ;
        let mood3 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "3").filter(m => m === true).length || 0 ;
        let mood2 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "2").filter(m => m === true).length || 0 ;
        let mood1 = Object.keys(guestList).map( (m: string) => guestList[m].mood && guestList[m].mood.replace(".svg", "") === "1").filter(m => m === true).length || 0 ;

        //remove his mood
        if(mood === this.props.user.mood){
            let m = mood ? mood.replace(".svg", "") : "";
            switch (m) {
                case "1": mood1--; break;
                case "2": mood2--; break;
                case "3": mood3--; break;
                case "4": mood4--; break;
                case "5": mood5--; break;
            }
        }

        //remove his mood and update
        let prevMood = this.props.user.mood ? this.props.user.mood.replace(".svg", ""): "";
        switch (prevMood) {
            case "1": mood1--; break;
            case "2": mood2--; break;
            case "3": mood3--; break;
            case "4": mood4--; break;
            case "5": mood5--; break;
        }

        let currentMood = mood ? mood.replace(".svg", ""): "";
        switch (currentMood) {
            case "1": mood1++; break;
            case "2": mood2++; break;
            case "3": mood3++; break;
            case "4": mood4++; break;
            case "5": mood5++; break;
        }
        let totalUsers = this.props.guestList ? Object.keys(this.props.guestList).length: 0;
        firebase.database()
            .ref(`rooms/${this.props.room}/mood/${roundUpToHalfMinute(moment().valueOf())}`)
            .update({moods: {"m1": mood1, "m2": mood2, "m3": mood3, "m4":mood4, "m5": mood5}, timestamp: roundUpToHalfMinute(moment().valueOf()), totalUsers: totalUsers}
        ).then();
        firebase.analytics().logEvent("set_mood", {"mood": mood});
    };

    resetSession= () => {
        this.props.guestList.filter((participant: any) => !participant.isAdmin)
            .forEach((participant: any) => {
                if(participant && participant.id){
                    firebase.database().ref(`rooms/${this.props.room}/users/${participant.id}`).update({
                        isLogin: false,
                        isRemovedByAdmin: true
                    }).then(()=>{
                        //add double check since "then" happens after a couple of milliseconds
                        if(participant && participant.id){
                            firebase.database().ref(`rooms/${this.props.room}/users`).update({[participant.id]: null}).then();
                        }

                    });
                }
        });
        firebase.database().ref(`rooms/${this.props.room}/`).update({"polls": null }).then();
        firebase.database().ref(`rooms/${this.props.room}/`).update({"mood": null }).then();
        firebase.database().ref(`rooms/${this.props.room}/`).update({"messages": null }).then();
        firebase.analytics().logEvent("reset_session");
        this.setState({modalResetShow: false});
    };

    handleActionsOnClick= () => {
        if(!this.state.actionIsOpen){
            this.setState({  sharedLinkClicked: false});
        }
    };

    handleActionsOnToggle= (a: any, b: any, {source}: any) => {
        if(source === "select"){
            this.setState(   {actionIsOpen: true });
        }else{
            this.setState(   {actionIsOpen: a });
        }
    };

    handleOnClickSharedLink= (link: string) => {
       this.setState({sharedLinkClicked: true, actionIsOpen:true});
        const el = document.createElement('textarea');
        el.value = link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setTimeout(()=> {
            this.setState({sharedLinkClicked: false, actionIsOpen:false});
        }, 800)
    };

    logout = ()=> {
        firebase.database().ref(`rooms/${this.props.room}/users`).update({[this.props.user.id]: null }
        ).then(()=> {
            firebase.analytics().logEvent("logout", {room: this.props.room});
            window.location.href = "/";
        });
    };

    openFeedback = (isFeedBackTriggerByUser: boolean)=> {
        this.setState({modalFeedbackShow: true, isFeedBackTriggerByUser: isFeedBackTriggerByUser});
    };

    openLogout = ()=> {
        this.setState({modalLogoutShow: true });
    };

    sendFeedback = (score: number, feedback: string)=> {
        if(feedback.trim() !== "" || score !== 0){
            firebase.database().ref(`feedback/${moment().valueOf()}`).update({score: score, feedback: Util.encodeCharacters(feedback), timestamp: moment().valueOf() , locale: this.props.locale, room: this.props.room }
            ).then(()=> {
                    firebase.analytics().logEvent("feedback", {score: score, feedback: Util.encodeCharacters(feedback), timestamp: moment().valueOf() , locale: this.props.locale, room: this.props.room });
                }
            );
        }
       if(this.state.isFeedBackTriggerByUser){
           this.setState({modalFeedbackShow: false});
       }
    };

    sendFeedbackAndLogout = (score: number, feedback: string)=> {
      if(score !== 0 || feedback.trim() !== ""){
        this.sendFeedback(score, feedback);
      }
      this.logout();
    };

    handleOnDownload = ()=>{
        const {
            props: {user, room, roomName, locale}
        } = this;
        Util.downloadRecoveryLink(user, room, roomName, locale );
    };

    render() {
        const {
            props: {room, locale="EN", setLocale, setSortBy, sortedBy, user, timer, guestList, roomName},
            state: {sharedLinkClicked, actionIsOpen, modalResetShow, modalFeedbackShow, isFeedBackTriggerByUser, modalLogoutShow, modalNewAdminShow}
        } = this;

        const url = new URL(window.location.href);
        const hostname = url.hostname;
        const port = url.port;
        const protocol = url.protocol;
        let linkSharing =`${protocol}//${hostname}${port ? `:${port}` : ""}/join/${room.replace(/ /g, "%20")}`;

        return (
            <Row noGutters>
                <Col>
                    <Navbar className="app-navbar">
                        <Nav className={"user-info "}>
                            <div className={"tool-bar-avatar d-none d-sm-block"}>
                                <img src={require(`../../../assets/avatar/${user.avatar}`).default} alt="" draggable={false}/>
                            </div>
                            <span className={"info"}>
                                <span className={"name"}>{Util.decodeCharacters(user.name)}</span>
                                <span className={"room"}>{Util.decodeCharacters(roomName)}</span>
                            </span>
                        </Nav>
                        <Nav>
                            <MoodWidget
                                locale = {locale}
                                onMoodChange = {this.setMood}
                                user={user}
                            />
                        </Nav>
                        <Nav className={"timer-nav-wrapper"}>
                            <Timer timer={timer}  user={user} room={room}/>
                        </Nav>
                        <Nav className="justify-content-end float-right tool-action-wrapper">
                            <DropdownButton
                                alignRight
                                as={ButtonGroup}
                                id={"locale"}
                                title={""}
                                className={"btn-locale"}
                                onClick={this.handleActionsOnClick}
                                rootCloseEvent={"click"}
                                show={actionIsOpen}
                                variant={"light"}
                                onToggle={this.handleActionsOnToggle}
                            >
                                {user.isAdmin &&
                                    <Dropdown.Item onClick={() => this.setState({modalResetShow: true})}>
                                        <i className="fas fa-recycle"/> {Translator.translate("Reset Session", locale, "TOOLBAR")}
                                    </Dropdown.Item>
                                }
                                {user.isAdmin && <Dropdown.Divider/> }
                                {user.isAdmin &&
                                    <Dropdown.Item onClick={this.handleOnDownload}>
                                        <i className="fas fa-download"/> {Translator.translate("Download session access", locale, "LIST")}
                                    </Dropdown.Item>
                                }

                                {user.isAdmin && <Dropdown.Divider/> }
                                <Dropdown.Item onClick={() => this.handleOnClickSharedLink(linkSharing)}
                                               className={`${sharedLinkClicked ? "active" : ""}`}>
                                    <Form style={{minWidth: "250px", width: "100%"}}>
                                        <Form.Group>
                                            <Form.Label column={false} style={{width: "100%"}}>
                                                <i className="fas fa-share-square"/> {Translator.translate("Share session link", locale, "TOOLBAR")}
                                                <span className={"float-right"}>
                                                    {sharedLinkClicked ? Translator.translate("Copied!", locale, "TOOLBAR") : ""}
                                                </span>
                                            </Form.Label>
                                            <FormControl
                                                readOnly={true}
                                                value={linkSharing}
                                                style={{width: "100%"}}
                                                required
                                                name="link"
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                    </Form>
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item>
                                    <i className="fas fa-globe"/> {Translator.translate("Language", locale, "TOOLBAR")}
                                    <div className={"languages-wrapper"}>
                                        <Button onClick={() => setLocale("DE")} variant="light"
                                                className={`${locale === "DE" ? "active" : ""}`}>
                                            <img className={"flag"} src={require(`../../../assets/flag/DE.svg`).default} alt=""
                                                 draggable={false}/>
                                            <span>{Translator.translate("German", locale, "LOGIN")}</span>
                                        </Button>
                                        <Button onClick={() => setLocale("EN")} variant="light"
                                                className={`${locale === "EN" ? "active" : ""}`}>
                                            <img className={"flag"} src={require(`../../../assets/flag/EN.svg`).default} alt=""
                                                 draggable={false}/>
                                            <span>{Translator.translate("English", locale, "LOGIN")}</span>
                                        </Button>
                                        <Button onClick={() => setLocale("ES")} variant="light"
                                                className={`${locale === "ES" ? "active" : ""}`}>
                                            <img className={"flag"} src={require(`../../../assets/flag/ES.svg`).default} alt=""
                                                 draggable={false}/>
                                            <span>{Translator.translate("Spanish", locale, "LOGIN")}</span>
                                        </Button>
                                    </div>
                                </Dropdown.Item>

                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={() => false}>
                                    <i className="fas fa-sort-amount-down-alt"/> {Translator.translate("Sort by:", locale, "TOOLBAR")}
                                    <div className={"sorting-wrapper"}>

                                        <Button onClick={() => {
                                            this.setState({sortedBy: "NAME"});
                                            setSortBy("NAME")
                                        }} variant="light">
                                            {sortedBy === "NAME" ? <i className="active fas fa-circle"/> :
                                                <i className="far fa-circle"/>}
                                            <span>{Translator.translate("Name", locale, "TOOLBAR")}</span>
                                        </Button>
                                        <Button onClick={() => {
                                            this.setState({sortedBy: "LAST_UPDATE"});
                                            setSortBy("LAST_UPDATE")
                                        }} variant="light">
                                            {sortedBy === "LAST_UPDATE" ? <i className="active fas fa-circle"/> :
                                                <i className="far fa-circle"/>}
                                            <span>{Translator.translate("Last active user", locale, "TOOLBAR")}</span>
                                        </Button>
                                        <Button onClick={() => {
                                            this.setState({sortedBy: "CREATION"});
                                            setSortBy("CREATION")
                                        }} variant="light">
                                            {sortedBy === "CREATION" ? <i className="active fas fa-circle"/> :
                                                <i className="far fa-circle"/>}
                                            <span>{Translator.translate("Login order", locale, "TOOLBAR")}</span>
                                        </Button>
                                    </div>
                                </Dropdown.Item>


                                {user.isAdmin && <Dropdown.Divider/> }
                                {user.isAdmin &&
                                    <Dropdown.Item onClick={() =>  this.setState({modalNewAdminShow: true}) }>
                                        <i className="fas fa-crown"/> {Translator.translate("Assign new admin", locale, "TOOLBAR")}
                                    </Dropdown.Item>
                                }
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={() => this.openFeedback(true)}>
                                    <i className="fas fa-comment-dots"/> {Translator.translate("Feedback", locale, "TOOLBAR")}
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={user.isAdmin ? this.openLogout : () => this.openFeedback(false)}>
                                    <i className="fas fa-sign-out-alt"/> {Translator.translate("Logout", locale, "TOOLBAR")}
                                </Dropdown.Item>

                            </DropdownButton>
                        </Nav>
                    </Navbar>
                </Col>

                <Modal show={modalResetShow} onHide={() => this.setState({modalResetShow: false})}>
                    <Modal.Body>
                        <Container>
                            <Row className="show-grid">
                                <Col>
                                    <p>
                                        {Translator.translate("This action will remove all participants of this session", locale, "TOOLBAR")}
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light"
                                onClick={() => this.setState({modalResetShow: false})}> {Translator.translate("Cancel", locale, "TOOLBAR")}</Button>
                        <Button variant="danger"
                                onClick={this.resetSession}> {Translator.translate("Confirm", locale, "TOOLBAR")}</Button>
                    </Modal.Footer>
                </Modal>

                <FeedbackModal
                    onHide={() => this.setState({modalFeedbackShow: false})}
                    onSubmit={isFeedBackTriggerByUser ? this.sendFeedback : this.sendFeedbackAndLogout}
                    locale={locale}
                    user={user}
                    onQuit={this.logout}
                    show={modalFeedbackShow}
                    isTriggerByUser={isFeedBackTriggerByUser}/>

                <NewAdminModal
                    onHide={() => { this.setState({modalNewAdminShow: false})}}
                    onQuit={() => { this.setState({modalNewAdminShow: false})}}
                    onSubmit={() => { this.setState({modalNewAdminShow: false})}}
                    show={modalNewAdminShow}
                    locale={locale}
                    user={user}
                    room={room}
                    isTriggerByUser={true}
                    guestList={guestList}/>

                {user.isAdmin &&
                    <LogoutModal
                        onHide={() => this.setState({modalLogoutShow: false})}
                        locale={locale}
                        user={user}
                        room={room}
                        roomName={roomName}
                        show={modalLogoutShow}
                        sendFeedback={this.sendFeedback}
                    />
                }


            </Row>
        );

    }
}

export default Toolbar;
