import {Action, LocaleType} from "../../../types/Types";
import Translator from "../../util/Locale";

let browserLocale = navigator.language.toUpperCase().substr(0, 2);
let locale = (browserLocale === "DE" || browserLocale === "EN" || browserLocale === "ES") ? browserLocale : "EN";
locale = localStorage.getItem("teamprove.locale")  || locale;



export default class Decks  {

    static pokerDeck: Array<Action> = [
        {
            label: "",
            image: "zero.svg",
            description: "",
            id: "PK_0",
            sortValue: 0,
            checked: true
        },
        {
            label: "",
            image: "half.svg",
            description: "",
            id: "PK_1-2",
            sortValue: 0.5,
            checked: true
        },
        {
            label: "",
            image: "one.svg",
            description: "",
            id: "PK_1",
            sortValue: 1,
            checked: true
        },
        {
            label: "",
            image: "two.svg",
            description: "",
            id: "PK_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "three.svg",
            description: "",
            id: "PK_3",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "five.svg",
            description: "",
            id: "PK_5",
            sortValue: 5,
            checked: true
        },
        {
            label: "",
            image: "eight.svg",
            description: "",
            id: "PK_8",
            sortValue: 8,
            checked: true
        },
        {
            label: "",
            image: "thirteen.svg",
            description: "",
            id: "PK_13",
            sortValue: 13,
            checked: true
        },
        {
            label: "",
            image: "twenty.svg",
            description: "",
            id: "PK_20",
            sortValue: 20,
            checked: true
        },
        {
            label: "",
            image: "forty.svg",
            description: "",
            id: "PK_40",
            sortValue: 40,
            checked: true
        },
        {
            label: "",
            image: "hundred.svg",
            description: "",
            id: "PK_100",
            sortValue: 100,
            checked: true
        },
        {
            label: "",
            image: "question.svg",
            description: "",
            id: "PK_Q",
            sortValue: 1000,
            checked: true
        }
    ];



    static rotiDeck: Array<Action> = [
        {
            label: Translator.translate("roti-1", locale as LocaleType, "VOTING"),
            image: "one.svg",
            description: "",
            id: "ROTI_1",
            sortValue: 1,
            checked: true
        },

        {
            label: Translator.translate("roti-2", locale as LocaleType, "VOTING"),
            image: "two.svg",
            description: "",
            id: "ROTI_2",
            sortValue: 2,
            checked: true
        },
        {
            label: Translator.translate("roti-3", locale as LocaleType, "VOTING"),
            image: "three.svg",
            description: "",
            id: "ROTI_3",
            sortValue: 3,
            checked: true
        },
        {
            label: Translator.translate("roti-4", locale as LocaleType, "VOTING"),
            image: "four.svg",
            description: "",
            id: "ROTI_4",
            sortValue: 4,
            checked: true
        },
        {
            label: Translator.translate("roti-5", locale as LocaleType, "VOTING"),
            image: "five.svg",
            description: "",
            id: "ROTI_5",
            sortValue: 5,
            checked: true
        },
    ];



    static _123Deck: Array<Action> = [
        {
            label: "",
            image: "one.svg",
            description: "",
            id: "123_1",
            sortValue: 1,
            checked: true
        },

        {
            label: "",
            image: "two.svg",
            description: "",
            id: "123_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "three.svg",
            description: "",
            id: "123_3",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "four.svg",
            description: "",
            id: "123_4",
            sortValue: 4,
            checked: true
        },
        {
            label: "",
            image: "five.svg",
            description: "",
            id: "123_5",
            sortValue: 5,
            checked: true
        },
    ];

    static abcDeck: Array<Action> = [
        {
            label: "",
            image: "a.svg",
            description: "",
            id: "ABC_A",
            sortValue: 1,
            checked: true
        },

        {
            label: "",
            image: "b.svg",
            description: "",
            id: "ABC_B",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "c.svg",
            description: "",
            id: "ABC_C",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "d.svg",
            description: "",
            id: "ABC_D",
            sortValue: 4,
            checked: true
        },
        {
            label: "",
            image: "e.svg",
            description: "",
            id: "ABC_E",
            sortValue: 5,
            checked: true
        },
    ];

    static cloudDeck: Array<Action> = [
        {
            label: "",
            image: "",
            description: "",
            id: "C_1",
            sortValue: 1,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "C_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "C_3",
            sortValue: 3,
            checked: true
        },

    ];

    static radarDeck: Array<Action> = [
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_1",
            sortValue: 1,
            checked: true
        },

        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_3",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_4",
            sortValue: 4,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_5",
            sortValue: 5,
            checked: true
        },
    ];
}

