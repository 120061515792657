import React, {Component} from 'react';
import LineChart from "../lineChart/LineChart";
import "./stackAreaChart.scss";
import MoodTrend from "../moodTrend/MoodTrend";
import {Guest, LocaleType, PollType} from "../../../types/Types";
import Translator from "../../util/Locale";
import firebase from 'firebase/app';
import 'firebase/database';
import PollLine from "./pollLine/PollLine";
import VotingResultModal from "../votingResult/VotingResultModal";

type Props = {
    moodTrend: Array<any>;
    width: number;
    height: number;
    onClick: Function;
    isExpanded: boolean;
    locale: LocaleType;
    room: string;
    user: any,
    guestList: Array<Guest>;
}

type State = {
    polls: any | null;
    isPollModalShow: boolean;
    poll: PollType | null;
}

class StackAreaChart extends Component<Props, State> {

    state: State = {
        polls: null,
        isPollModalShow: false,
        poll: null
    };

    componentDidMount(): void {
        firebase.database().ref(`rooms/${this.props.room}/polls`).on('value', (snapshot: any) => {
            this.setState({polls: snapshot.val() ? snapshot.val() :{}});
        });
    }

    getMaxYinVector = (vector: Array<any>)=>{
        return Math.max(...vector.map((v) => v.y));
    };

    normalizeVector = (key: any, offset?: Array<any>)=> {
        return   this.props.moodTrend.map( (m: any, index: number) => {
            let offsetAt = offset ? offset[index].y : 0;
            let value = m.moods && m.moods[key] ? m.moods[key]: 0;
            return { x:index, y: value + offsetAt}
        });
    };

    roundUpTo = (roundTo: number ) => (x: number) => Math.ceil(x / roundTo) * roundTo;
    roundUpToHalfMinute = this.roundUpTo(1000 * 30 );

    handleOnResultModalShow = (poll: any) => {
        this.setState(prevState => ({
            isPollModalShow: !prevState.isPollModalShow,
            poll: poll
        }))
    };

    render() {
        const {
            props: { width, height, onClick, moodTrend, isExpanded, locale, user, guestList },
            state: { polls, poll, isPollModalShow }
        } = this;
        const vector5 = this.normalizeVector("m5");
        const vector4 = this.normalizeVector("m4", vector5);
        const vector3 = this.normalizeVector("m3", vector4);
        const vector2 = this.normalizeVector("m2", vector3);
        const vector1 = this.normalizeVector("m1", vector2);
        const audienceSize = this.props.moodTrend.map( (m: any, index: number) => {
            return { x:index, y: m.totalUsers}
        });

        let maxY = this.getMaxYinVector(audienceSize);

        let svgHeight = 105;
        let pollTimes:any = [];
        if(polls && moodTrend){
            pollTimes = Object.keys(polls).map((p: any)=> {
                let normalizedTime = this.roundUpToHalfMinute(polls[p].config.id);
                let x = moodTrend.findIndex( i => i.timestamp === normalizedTime);
                return {x:x, y:maxY, poll: polls[p]};
            }).filter((p)=> p.x !== -1);
        }

        return (
            <div className={`distribution-wrapper ${isExpanded ? "open" : ""}`}
                 style={{width: "100%", height: height}}>

                { vector1.length > 2 &&
                    <span className={`expander ${!isExpanded ? "collapsed" : ""}`} onClick={() => onClick()}>
                        {!isExpanded ?
                            <i className="fas fa-chart-area"/> :
                            <i className="fas fa-compress-alt"/>
                        }
                    </span>
                }

                {isExpanded && vector1.length > 2 &&
                <div className={"legend"}>
                    <span className={"legend-item"}>{Translator.translate("exited", locale, "MOOD_CHART")}</span>
                    <span className={"legend-item"}>{Translator.translate("happy", locale, "MOOD_CHART")}</span>
                    <span className={"legend-item"}>{Translator.translate("puzzled", locale, "MOOD_CHART")}</span>
                    <span className={"legend-item"}>{Translator.translate("bored", locale, "MOOD_CHART")}</span>
                    <span className={"legend-item"}>{Translator.translate("angry", locale, "MOOD_CHART")}</span>
                </div>
                }

                {!isExpanded && <MoodTrend moodTrend={moodTrend} locale={locale}/>}
                {isExpanded && vector1.length > 2 &&
                <svg viewBox={`0 0 ${width} ${svgHeight}`}>
                    <g  transform="translate(0 6)">
                        <LineChart data={vector1} color={"#fa5462"} fill={"#fa5462"} svgHeight={svgHeight} svgWidth={width}
                                   maxY={maxY} chartType={"AREA"} label={"Very Bad"}/>
                        <LineChart data={vector2} color={"#fa834f"} fill={"#fa834f"} svgHeight={svgHeight} svgWidth={width}
                                   maxY={maxY} chartType={"AREA"} label={"Bad"}/>
                        <LineChart data={vector3} color={"#fbcf3e"} fill={"#fbcf3e"} svgHeight={svgHeight} svgWidth={width}
                                   maxY={maxY} chartType={"AREA"} label={"Regular"}/>
                        <LineChart data={vector4} color={"#9fd77a"} fill={"#9fd77a"} svgHeight={svgHeight} svgWidth={width}
                                   maxY={maxY} chartType={"AREA"} label={"Good"}/>
                        <LineChart data={vector5} color={"#6acb6c"} fill={"#6acb6c"} svgHeight={svgHeight} svgWidth={width}
                                   maxY={maxY} chartType={"AREA"} label={"Excellent"}/>
                        <LineChart data={audienceSize} color={"#4d4d4d"} fill={"transparent"} svgHeight={svgHeight}
                                   svgWidth={width} maxY={maxY} chartType={"LINE"} label={"Active users"}/>
                    </g>
                    {pollTimes.map((p: { x: number, y: number, poll: any; }) =>
                        <PollLine
                            key={p.poll.config.id}
                            data={p}
                            svgHeight={svgHeight+14}
                            svgWidth={width}
                            maxY={maxY}
                            toggleModal={this.handleOnResultModalShow}
                            maxX={Object.keys(moodTrend).length}
                            color={"#000"} />
                    )}
                </svg>
                }
                {poll && <VotingResultModal
                    onHide={()=> this.setState({isPollModalShow: false})}
                    show={isPollModalShow}
                    guestList={guestList}
                    locale={locale}
                    poll={poll}
                    user={user}/>}
            </div>
        );
    }
}

export default StackAreaChart;
