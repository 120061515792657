import React, { Component } from 'react';
import {Pagination as BootstrapPagination, Row, Col} from "react-bootstrap";
import "./pagination.scss";

type Props = {
    totalRecords:  number,
    pageLimit?:  number,
    pageNeighbours?: number,
    onPageChanged: Function,
    currentPage: number,

}

type State = {
    pageLimit: number,
    totalRecords: number,
    pageNeighbours: number,
    totalPages: number
}
const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';


class Pagination extends Component<Props, {}> {

    state: State = {
        pageLimit: typeof this.props.pageLimit === 'number' ? this.props.pageLimit : 30,
        totalRecords: typeof this.props.totalRecords === 'number' ? this.props.totalRecords : 0,
        pageNeighbours: typeof this.props.pageNeighbours === 'number' ? Math.max(0, Math.min(this.props.pageNeighbours, 2)) : 0,
        totalPages:  0
    };

    componentDidMount(): void {
        this.setState({
            totalPages: Math.ceil(this.state.totalRecords / this.state.pageLimit)
        });
    }

    gotoPage = (page: any) => {
        const { onPageChanged = (f: Function) => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.state.totalPages));
        const paginationData = {
            currentPage,
            totalPages: this.state.totalPages,
            pageLimit: this.state.pageLimit,
            totalRecords: this.state.totalRecords
        };
        this.setState({ currentPage }, () => onPageChanged(paginationData));
    };

    handleClick = (page: number | string, evt: any) => {
        evt.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = (evt: any) => {
        evt.preventDefault();
        this.gotoPage(this.props.currentPage - (this.state.pageNeighbours * 2) - 1);
    };

    handleMoveRight = (evt: any) => {
        evt.preventDefault();
        this.gotoPage(this.props.currentPage + (this.state.pageNeighbours * 2) + 1);
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {

        if(prevProps.totalRecords !== this.props.totalRecords){
            this.setState({
                totalRecords: this.props.totalRecords,
                totalPages:  Math.ceil(this.props.totalRecords / this.state.pageLimit)
            });
            if(this.props.currentPage === 0){
                //this.gotoPage(1);
            }
        }
    }

    range = (from: number, to: number, step: any = 1) => {
        let i = from;
        const range = [];
        while (i <= to) {
            range.push(i);
            i += step;
        }
        return range;
    };

    fetchPageNumbers = () => {

        const totalPages = this.state.totalPages;
        const currentPage = this.props.currentPage;
        const pageNeighbours = this.state.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (this.state.pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {

            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pagesRange = this.range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pagesRange.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = this.range(startPage - spillOffset, startPage - 1);
                    // @ts-ignore
                    pagesRange = [LEFT_PAGE, ...extraPages, ...pagesRange];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = this.range(endPage + 1, endPage + spillOffset);
                    // @ts-ignore
                    pagesRange = [...pagesRange, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    // @ts-ignore
                    pagesRange = [LEFT_PAGE, ...pagesRange, RIGHT_PAGE];
                    break;
                }
            }

            return Array.from(new Set([1, ...pagesRange, totalPages]));

        }

        return this.range(1, totalPages);

    };

    render() {

        if (!this.state.totalRecords || this.state.totalPages === 1) return null;
        const { currentPage } = this.props;
        const pages = this.fetchPageNumbers();

        return (
            <Row>
                <Col>
                    <div className="pagination-wrapper">
                        <BootstrapPagination>
                            { pages.map((page: any, index: number) => {
                                if (page === LEFT_PAGE) return (
                                    <BootstrapPagination.Prev key={index} onClick={this.handleMoveLeft}/>
                                );
                                if (page === RIGHT_PAGE) return (
                                    <BootstrapPagination.Next key={index} onClick={this.handleMoveRight} />
                                );
                                return (
                                    <BootstrapPagination.Item key={index} onClick={ (e: any)=> this.handleClick(page, e) }
                                                              active={currentPage === page}>{ page }
                                    </BootstrapPagination.Item>
                                );
                            }) }
                        </BootstrapPagination>
                    </div>
                </Col>
            </Row>
        );

    }


}

export default Pagination;
