import React, {Component} from 'react';
import {Badge, Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import Translator from "../../util/Locale";
import Util from "../../util/Util";
import {Guest, PollType} from "../../../types/Types";
import firebase from 'firebase/app';
import 'firebase/database';
const moment = require('moment');

type Props = {
    locale: any,
    poll: PollType | null;
    user: Guest | null;
    room: string | null;
}
type State = {
    value: string
}

class FreePoker extends Component<Props, State> {
    private inputvalue = React.createRef<any>();

    state: State = {
        value: ""
    };

    handleKeyPressEnter =  (e: any) => {
        if(e.key === 'Enter'){
            this.submitVote(this.state.value);
        }
    };

    submitVote = (vote: string) =>{
        const room = this.props.room;
        this.setState({ value: ""});

        if( this.inputvalue && this.inputvalue.current){
            this.inputvalue.current.focus();
        }

        const poll = this.props.poll;
        if(poll !== null && this.props.user !== null){
            firebase.database().ref(`rooms/${room}/polls/${poll.config.id}/votes/${this.props.user.id}`).update({
                    vote:   Util.encodeCharacters(vote),
                    avatar: this.props.user.avatar,
                    name: this.props.user.name,
                    timestamp: moment().valueOf()
                }
            ).then();
        }
    };

    render() {

        const {
            props: {locale , poll, user},
            state: {value}
        } = this;

        let userHasVoted = user && poll &&  poll.votes &&  poll.votes[user.id];

        return (
            <>
                <Row>
                    <Col>
                        <InputGroup>
                            <FormControl
                                type={"text"}
                                value={value}
                                onChange={(e: any)=> this.setState({value: e.target.value.substr(0, 4)})}
                                onKeyPress={this.handleKeyPressEnter}
                                ref={this.inputvalue}
                                placeholder={Translator.translate("Enter a value", locale, "VOTING")}
                                style={{paddingLeft: "30px"}}
                            />
                            <InputGroup.Append style={{position: "absolute", zIndex: 100, top: "10px", left: "10px"}}>
                                <Badge variant={ ((4 - value.length) > 0) ? "success": "danger"}>
                                    {4 - value.length}
                                </Badge>
                            </InputGroup.Append>
                            <InputGroup.Append>
                                <Button
                                    variant={"primary"}
                                    onClick={()=> this.submitVote(value)}
                                    disabled={value.trim() === ""}
                                >
                                    {userHasVoted ?
                                        <span>{Translator.translate("Change estimation", locale, "VOTING")}</span>:
                                        <span>{Translator.translate("Submit estimation", locale, "VOTING")}</span>
                                    }
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                {userHasVoted && poll && user &&
                    <Row noGutters>
                        <Col>
                            <h5 style={{marginTop: "10px", marginBottom: "0"}}>
                                <Badge  variant={"secondary"} className={"badge-cloud"}>
                                    {Translator.translate("You voted", locale, "VOTING")}: {Util.decodeCharacters(poll.votes[user.id].vote)}
                                </Badge>
                            </h5>
                        </Col>
                    </Row>
                }


            </>
        );
    }
}

export default FreePoker;
