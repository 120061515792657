import React, {Component} from 'react';
import "./moodTrend.scss";
import ReactTooltip from 'react-tooltip';
import Translator from "../../util/Locale";
import {LocaleType} from "../../../types/Types";

type Props = {
    moodTrend: Array<any>;
    locale: LocaleType;
}

type State = {}


class MoodTrend extends Component<Props, State> {

    render() {
        const {props: { moodTrend, locale }} = this;
        const lastSnapshot = moodTrend[moodTrend.length-1];

        if(!lastSnapshot){
            return null;
        }

        const moodOne   = lastSnapshot.moods.m1;
        const moodTwo   = lastSnapshot.moods.m2;
        const moodThree = lastSnapshot.moods.m3;
        const moodFour  = lastSnapshot.moods.m4;
        const moodFive  = lastSnapshot.moods.m5;
        const totalUsers = lastSnapshot.totalUsers;

        const singular = Translator.translate("person is feeling", locale, "MOOD_CHART");
        const plural =  Translator.translate("persons are feeling", locale, "MOOD_CHART");

        const tooltip1 = `${moodOne} ${moodOne > 1 ? plural : singular  } ${Translator.translate("angry", locale, "MOOD_CHART")}`;
        const tooltip2 = `${moodTwo} ${moodTwo > 1 ? plural : singular  } ${Translator.translate("bored", locale, "MOOD_CHART")}`;
        const tooltip3 = `${moodThree} ${moodThree > 1 ? plural : singular } ${Translator.translate("puzzled", locale, "MOOD_CHART")}`;
        const tooltip4 = `${moodFour} ${moodFour > 1 ? plural : singular  } ${Translator.translate("happy", locale, "MOOD_CHART")}`;
        const tooltip5 = `${moodFive} ${moodFive > 1 ? plural : singular  } ${Translator.translate("exited", locale, "MOOD_CHART")}`;

        return (
               <div className={"mood-trend-wrapper"}>
                    <div className="mood-item mood-five" style={{width: `${moodFive * 100 / totalUsers}%`}}
                         data-tip={tooltip5}
                         data-for={`excellent-mood-tooltip`}
                    />
                    <div className="mood-item mood-four" style={{width: `${moodFour * 100 / totalUsers}%`}}
                         data-tip={tooltip4}
                         data-for={`good-mood-tooltip`}
                    />
                    <div className="mood-item mood-three" style={{width: `${moodThree * 100 / totalUsers}%`}}
                         data-tip={tooltip3}
                         data-for={`regular-mood-tooltip`}
                    />
                    <div className="mood-item mood-two" style={{width: `${moodTwo * 100 / totalUsers}%`}}
                         data-tip={tooltip2}
                         data-for={`bad-mood-tooltip`}
                    />
                    <div className="mood-item mood-one" style={{width: `${moodOne * 100 / totalUsers}%`}}
                         data-tip={tooltip1}
                         data-for={`veryBad-mood-tooltip`}
                    />
                   <ReactTooltip place="bottom"  effect="float"  type="warning" className={"tooltip-orange"} id={`veryBad-mood-tooltip`}/>
                   <ReactTooltip place="bottom"  effect="float"  type="warning" className={"tooltip-orange"} id={`bad-mood-tooltip`}/>
                   <ReactTooltip place="bottom"  effect="float"  type="warning" className={"tooltip-orange"} id={`regular-mood-tooltip`}/>
                   <ReactTooltip place="bottom"  effect="float"  type="warning" className={"tooltip-orange"} id={`good-mood-tooltip`}/>
                   <ReactTooltip place="bottom"  effect="float"  type="warning" className={"tooltip-orange"} id={`excellent-mood-tooltip`}/>
                </div>
        );
    }
}

export default MoodTrend;
