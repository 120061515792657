import React, {Component} from 'react';
import {Guest, LocaleType, PollType} from "../../../types/Types";
import {Row, Col, Alert} from "react-bootstrap";
import "./votingResult.scss";
import Util from "../../util/Util";
import ReactTooltip from 'react-tooltip';
import Translator from "../../util/Locale";
import TeamRadar from "../teamRadar/TeamRadar";
import CollectVote from "../collectVote/CollectVote";

type Props = {
    poll: PollType | null;
    user: Guest | null;
    locale: LocaleType;
    guestList: any,
};
type State = {};

class VotingResult extends Component<Props, State> {
    render() {

        const {
            props: { poll , user, guestList, locale }
        } = this;

        if(poll === null){
            return null;
        }

        if(!poll.votes && poll.config.pollStatus === "FINISHED"){
            return  <div style={{padding: "10px"}}>
                {Translator.translate(`No votes for this poll`, locale, "VOTING")}
            </div>;
        }

        let rowVotes = Object.keys(poll.votes || {} ).map(vote => poll.votes[vote] ).sort(( a: any, b: any ) => a && b && a.vote && b.vote && a.vote.sortValue - b.vote.sortValue );
        let totalVotes = rowVotes.length;
        let totalGuest = poll.config.totalVoters ? poll.config.totalVoters : (guestList && Object.keys(guestList).length) ? Object.keys(guestList).length : 0;

        if((!poll.config.isRealtimePreview && (user && !user.isAdmin))){
            if(totalVotes < totalGuest && poll.config.pollStatus !== "FINISHED" ){
                return null;
            }
        }

        if(totalVotes === 0){
            return null
        }

        let groupVotes: any = [];

        if(poll.config.selectedDeckName === "TEAM_RADAR" || poll.config.selectedDeckName === "COLLECT_VOTE"){
            groupVotes = [];
        }else{
            groupVotes = rowVotes.reduce((groups, item) => {
                const group = (groups[item.vote.id || item.vote] || []);
                group.push(item);
                groups[item.vote.id? item.vote.id: item.vote] = group;
                return groups;
            }, {});
        }


        const winner = Object.keys(groupVotes).length > 0 ? Math.max(...Object.keys(groupVotes).map(g => groupVotes[g].length )): 0;



        return (
            <Row className={`results-wrapper voting-v-bars ${(poll.config.pollStatus !== "FINISHED" && !poll.config.isRealtimePreview && user && user.isAdmin && totalVotes < totalGuest)? "vote-anonymous": ""}`}>
                <Col>
                    <Row noGutters>
                        <Col className="voting-info">
                            {user && poll.config.pollStatus === "RUNNING" && <h5>{Translator.translate("Results", locale, "VOTING")}</h5>}
                            {user && poll.config.pollStatus === "FINISHED" && poll.config.question !== "" &&<h5>{Util.decodeCharacters(poll.config.question)}</h5>}
                            <span>{Translator.translate(`Vote${totalVotes > 1 ? "s":""}`, locale, "VOTING")}: <b>{totalVotes} / {totalVotes > totalGuest ? totalVotes: totalGuest} </b></span>
                        </Col>
                    </Row>

                    {poll.config.overRuleVote &&
                        <>
                            <Row>
                                <Col> {Translator.translate("Final decision:", locale, "VOTING")}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Alert variant="success" className={"overrule-wrapper"}>
                                        {poll.config.overRuleVote.image &&
                                            <>
                                                <img width={40} src={require(`../../../assets/cards/${poll.config.overRuleVote.image}`).default} alt="" draggable={false}/>&nbsp;
                                                {poll.config.overRuleVote.label}
                                            </>
                                        }

                                        {!poll.config.overRuleVote.image &&
                                            <div>{Util.decodeCharacters(poll.config.overRuleVote)}</div>
                                        }
                                    </Alert>
                                </Col>
                            </Row>
                        </>
                    }
                    { !poll.config.overRuleVote && poll.config.pollStatus === "FINISHED" &&
                        poll.config.selectedDeckName !== "TEAM_RADAR" &&
                        poll.config.selectedDeckName !== "COLLECT_VOTE" &&
                        <>
                            <Row>
                                <Col> {Translator.translate("Final decision:", locale, "VOTING")}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Alert variant="success" className={"overrule-wrapper"}>
                                        {Object.keys(groupVotes).map((g: any, index: number )=>
                                                {
                                                    return groupVotes[g].length !== winner ? null:
                                                    groupVotes[g][0].vote.id ?
                                                    <img key={index} width={40} src={Util.imageActionCardDispatcher(groupVotes[g][0].vote.id)} alt="" draggable={false}/>:
                                                    <div>{Util.decodeCharacters(groupVotes[g][0].vote)}</div>
                                                }
                                            )
                                        }
                                    </Alert>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className={poll.config.overRuleVote ? "vote-overruled": ""} noGutters>
                        <Col style={{position: "relative"}}>
                            {
                                poll.config.pollStatus !== "FINISHED" && !poll.config.isRealtimePreview && totalVotes > 0 && user && user.isAdmin && user.isAdmin && totalVotes < totalGuest &&
                                <div className="hidden-message">
                                    {Translator.translate("Only you can see this preview", locale, "VOTING")}
                                </div>
                            }
                            {poll.config.overRuleVote &&
                                <div className={"overrule-message"}>
                                    <div>{Translator.translate("This poll's result has been overwritten!", locale, "VOTING")}</div>
                                </div>
                            }

                            <div className={"vote-item-wrapper"}>
                                { Object.keys(groupVotes).map( (item, index) =>
                                    <div key={item} style={{ width: `${100 / Object.keys(groupVotes).length}%` }} className={"vote-item"}>
                                        <div className="score-wrapper">
                                            <div className="score" style={{background: Util.colorActionCardDispatcher(item, index), color: Util.colorActionCardDispatcher(item, index), height: `${(groupVotes[item].length * 100 / totalVotes)}%`}}>
                                                <div className="stats-wrapper">
                                                    <span className="percentage" style={{color: Util.colorActionCardDispatcher(item, index)}}>{(groupVotes[item].length * 100 / totalVotes).toFixed(0)}%</span>
                                                    <span className="total-votes" style={{color: Util.colorActionCardDispatcher(item, index)}}>{groupVotes[item].length} {Translator.translate(`Vote${groupVotes[item].length > 1 ? "s":""}`, locale, "VOTING")}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-wrapper">
                                            {groupVotes[item][0].vote.id ?
                                                <img src={Util.imageActionCardDispatcher(item)} alt="" draggable={false}/>:
                                                <div className={"tagValue"}>{Util.decodeCharacters(groupVotes[item][0].vote)}</div>

                                            }
                                            {groupVotes[item][0].vote.label && groupVotes[item][0].vote.label !== "" &&
                                                <>
                                                    <i className="fas fa-tag" data-tip={Util.decodeCharacters(groupVotes[item][0].vote.label)} data-for={item}/>
                                                    <ReactTooltip place="top" effect="solid" type="warning" className={"tooltip-orange"} id={item}/>
                                                </>
                                            }
                                        </div>
                                            { !poll.config.isVotingAnonymous &&
                                            <div className={"voter-wrapper"}>
                                                {
                                                    groupVotes[item].map((voter: any)=>
                                                        <div key={voter.name}>
                                                            <img
                                                                className={"voter-avatar"}
                                                                src={require(`../../../assets/avatar/${voter.avatar}`).default}
                                                                alt=""
                                                                draggable={false}
                                                                data-tip={voter.name}
                                                            />
                                                            <ReactTooltip place="right" effect="solid" type="warning" className={"tooltip-orange"} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            }
                                    </div>
                                )}

                                { poll.config.selectedDeckName === "TEAM_RADAR" &&
                                    <TeamRadar poll={poll} user={user} locale={locale} guestList={guestList}/>
                                }

                                { poll.config.selectedDeckName === "COLLECT_VOTE" &&
                                    <CollectVote poll={poll} user={user} locale={locale} guestList={guestList}/>
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default VotingResult;
