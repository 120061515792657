import React, {Component} from 'react';
import './pollList.scss';
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormControl, InputGroup,
    Modal,
    OverlayTrigger,
    Popover,
    Row
} from "react-bootstrap";
import Translator from "../../util/Locale";
import {Action, Guest, LocaleType, PollType} from "../../../types/Types";
import {Dropdown} from "react-bootstrap";
import Util from "../../util/Util";
import firebase from 'firebase/app';
import 'firebase/database';

import VotingResult from "../votingResult/VotingResult";
import Decks from "../poll/Decks";
import Pagination from "../pagination/Pagination";
import ReactTooltip from 'react-tooltip';
import Cloud from "../cloud/Cloud";
import TeamRadar from "../teamRadar/TeamRadar";
import FreePoker from "../freePoker/FreePoker";
import PollCloud from "../pollCloud/PollCloud";
import PollCollectVote from "../pollCollectVote/PollCollectVote";
import CollectVote from "../collectVote/CollectVote";

//import SCVFileReader from "./SCVFileReader";

const moment = require('moment');

type Props = {
    locale: LocaleType;
    room: string | null;
    user: Guest;
    show: boolean;
    onHide: Function;
    guestList: Array<Guest>;
    runningPoll: PollType | null;
};

type State = {
    pollList: any,
    currentPage: number,
    totalPages: number,
};

class PollList extends Component<Props, State> {
    state: State = {
        pollList: {},
        currentPage: 1,
        totalPages: 0,
    };

    componentDidMount(): void {
        firebase.database().ref(`rooms/${this.props.room}/pollList`).on('value', (snapshot: any) => {
            this.setState({
                pollList: snapshot.val() ? snapshot.val() :{},
                totalPages: snapshot.val() ? Math.ceil(Object.keys(snapshot.val()).length / 5): 0
            });
        });
    }

    onPageChanged = (data: any) => {
        const { currentPage, totalPages } = data;
        this.setState({
            currentPage: currentPage ,
            totalPages: totalPages
        });
    };

    handleOnHide = () => {
        this.props.onHide();
    };

    handleAddPoll = () => {
        let pollData = {
            config: {
                selectedDeckName: "POKER",
                isVotingAnonymous: false,
                isRealtimePreview: false,
                hasSuperVotingPower: false,
                pollStatus: "CREATED",
                options: Decks.pokerDeck,
                totalVoters: Object.keys(this.props.guestList ? this.props.guestList : {} ).length,
            },
        };
        firebase.database().ref(`rooms/${this.props.room}/pollList`).push(pollData).then(()=> {
            this.setState({
                currentPage: Math.ceil(Object.keys(this.state.pollList).length / 5)
            });
        });
    };

    render() {
        const {
            props: { show, locale, runningPoll/*, room, guestList*/},
            state: { pollList, totalPages, currentPage }
        } = this;

        const offset = (currentPage - 1) * 5;
        const currentPagePolls = Object.keys(pollList).slice(offset, offset + 5);

        if(currentPagePolls.length === 0 && Object.keys(pollList).length > 0 && currentPage > 1){
            this.setState({
                currentPage: currentPage > 1 ? currentPage -1: 0
            });
        }

        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"poll-list-wrapper"}
                dialogClassName="modal-90w"
                key={"modal-poll-list"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("Poll Manager", locale, "VOTING")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                       {/* { Object.keys(pollList).length === 0 &&
                            <Row>
                                <Col>
                                    <p className={"description"}>
                                        {Translator.translate("poll-widget-description", locale, "VOTING")}
                                    </p>
                                    <SCVFileReader room={room} guestList={guestList} locale={locale}/>
                                </Col>
                            </Row>
                        }*/}

                        {Object.keys(pollList).length > 0  && !runningPoll &&
                            <Row>
                                <Col>
                                    <div className={"pagination-header"}>
                                        <div className="poll-count">
                                            <span>{Object.keys(pollList).length}</span>&nbsp;
                                            {
                                                Object.keys(pollList).length === 1 ?
                                                    Translator.translate("poll", locale, "VOTING"):
                                                    Translator.translate("polls", locale, "VOTING")
                                            }
                                        </div>
                                        {   totalPages > 1 &&
                                            <div className="page-detail">
                                                {Translator.translate("page", locale, "VOTING")}&nbsp;<span>{currentPage}/{totalPages}</span>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        }

                        { Object.keys(pollList).map( (poll: any) => {
                                if(runningPoll && (runningPoll.config.id === pollList[poll].config.id)){
                                    return <PollItem key={poll} poll={pollList[poll]} {...this.props} id={poll} pollList={pollList}/>;
                                }else return null;
                            })
                        }

                        { currentPagePolls.map( (poll: any) => {
                                if(!runningPoll){
                                    return <PollItem key={poll} poll={pollList[poll]} {...this.props} id={poll} pollList={pollList}/>;
                                }else return null;
                            })
                        }
                        <div style={{opacity: runningPoll? 0 : 1, height: runningPoll? 0 : "auto"}}>
                            <Pagination onPageChanged={this.onPageChanged} totalRecords={Object.keys(pollList).length} pageLimit={5} currentPage={currentPage}/>
                        </div>

                    </Container>
                </Modal.Body>

                { !runningPoll &&
                    <Modal.Footer>
                        <Button variant={"outline-secondary"} onClick={this.handleAddPoll} style={{margin: "auto auto auto 0"}}>
                            <i className="fas fa-plus"/>&nbsp;{Translator.translate("Add poll", locale, "VOTING")}
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        );
    }
}

export default PollList;


type PollItemProps = {
    poll: PollType;
    pollList: any;
    room: string | null;
    locale: LocaleType;
    guestList: Array<Guest>;
    onHide: Function;
    id: any;
    user: Guest;
    runningPoll: PollType | null;
}

type PollItemState = {
    selectedDeckName: "POKER" | "ROTI" | "ABC" | "123" | "CLOUD" | "COLLECT_VOTE" | "FREE_POKER" | "TEAM_RADAR";
    selectedDeck: Array<Action>;
    pokerDeck:Array<Action>;
    rotiDeck:Array<Action>;
    _123Deck:Array<Action>;
    abcDeck:Array<Action>;
    radarDeck: Array<Action>;
    cloud: Array<Action>;
    collectVote: Array<Action>;
    freePoker: Array<Action>;
    question: string;
    pollId: number;
    isPollRunning: boolean;
    showResults: boolean;
    finalPoll: PollType| null;
    isSetBehaviourCompleted: boolean;
    overRuleText: string;
}

let browserLocale = navigator.language.toUpperCase().substr(0, 2);
let locale = (browserLocale === "DE" || browserLocale === "EN" || browserLocale === "ES") ? browserLocale : "EN";
locale = localStorage.getItem("teamprove.locale")  || locale;

class PollItem extends Component<PollItemProps, PollItemState> {

    pokerDeck: Array<Action> = [
        {
            label: "",
            image: "zero.svg",
            description: "",
            id: "PK_0",
            sortValue: 0,
            checked: true
        },
        {
            label: "",
            image: "half.svg",
            description: "",
            id: "PK_1-2",
            sortValue: 0.5,
            checked: true
        },
        {
            label: "",
            image: "one.svg",
            description: "",
            id: "PK_1",
            sortValue: 1,
            checked: true
        },
        {
            label: "",
            image: "two.svg",
            description: "",
            id: "PK_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "three.svg",
            description: "",
            id: "PK_3",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "five.svg",
            description: "",
            id: "PK_5",
            sortValue: 5,
            checked: true
        },
        {
            label: "",
            image: "eight.svg",
            description: "",
            id: "PK_8",
            sortValue: 8,
            checked: true
        },
        {
            label: "",
            image: "thirteen.svg",
            description: "",
            id: "PK_13",
            sortValue: 13,
            checked: true
        },
        {
            label: "",
            image: "twenty.svg",
            description: "",
            id: "PK_20",
            sortValue: 20,
            checked: true
        },
        {
            label: "",
            image: "forty.svg",
            description: "",
            id: "PK_40",
            sortValue: 40,
            checked: true
        },
        {
            label: "",
            image: "hundred.svg",
            description: "",
            id: "PK_100",
            sortValue: 100,
            checked: true
        },
        {
            label: "",
            image: "question.svg",
            description: "",
            id: "PK_Q",
            sortValue: 1000,
            checked: true
        }
    ];

    rotiDeck: Array<Action> = [
        {
            label: Translator.translate("roti-1", locale as LocaleType, "VOTING"),
            image: "one.svg",
            description: "",
            id: "ROTI_1",
            sortValue: 1,
            checked: true
        },

        {
            label: Translator.translate("roti-2", locale as LocaleType, "VOTING"),
            image: "two.svg",
            description: "",
            id: "ROTI_2",
            sortValue: 2,
            checked: true
        },
        {
            label: Translator.translate("roti-3", locale as LocaleType, "VOTING"),
            image: "three.svg",
            description: "",
            id: "ROTI_3",
            sortValue: 3,
            checked: true
        },
        {
            label: Translator.translate("roti-4", locale as LocaleType, "VOTING"),
            image: "four.svg",
            description: "",
            id: "ROTI_4",
            sortValue: 4,
            checked: true
        },
        {
            label: Translator.translate("roti-5", locale as LocaleType, "VOTING"),
            image: "five.svg",
            description: "",
            id: "ROTI_5",
            sortValue: 5,
            checked: true
        },
    ];



    _123Deck: Array<Action> = [
        {
            label: "",
            image: "one.svg",
            description: "",
            id: "123_1",
            sortValue: 1,
            checked: true
        },

        {
            label: "",
            image: "two.svg",
            description: "",
            id: "123_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "three.svg",
            description: "",
            id: "123_3",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "four.svg",
            description: "",
            id: "123_4",
            sortValue: 4,
            checked: true
        },
        {
            label: "",
            image: "five.svg",
            description: "",
            id: "123_5",
            sortValue: 5,
            checked: true
        },
    ];

    abcDeck: Array<Action> = [
        {
            label: "",
            image: "a.svg",
            description: "",
            id: "ABC_A",
            sortValue: 1,
            checked: true
        },

        {
            label: "",
            image: "b.svg",
            description: "",
            id: "ABC_B",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "c.svg",
            description: "",
            id: "ABC_C",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "d.svg",
            description: "",
            id: "ABC_D",
            sortValue: 4,
            checked: true
        },
        {
            label: "",
            image: "e.svg",
            description: "",
            id: "ABC_E",
            sortValue: 5,
            checked: true
        },
    ];


    radarDeck: Array<Action> = [
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_1",
            sortValue: 1,
            checked: true
        },

        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_2",
            sortValue: 2,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_3",
            sortValue: 3,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_4",
            sortValue: 4,
            checked: true
        },
        {
            label: "",
            image: "",
            description: "",
            id: "RADAR_5",
            sortValue: 5,
            checked: true
        }
    ];

    state: PollItemState = {
        selectedDeckName: "POKER",
        selectedDeck: this.pokerDeck,
        rotiDeck: this.rotiDeck,
        _123Deck: this._123Deck,
        abcDeck: this.abcDeck,
        pokerDeck: this.pokerDeck,
        radarDeck: this.radarDeck,
        freePoker: [],
        cloud: [],
        collectVote: [],
        question: "",
        pollId: this.props.poll && this.props.poll.config.id ? this.props.poll.config.id: moment().valueOf(),
        isPollRunning: false,
        showResults: false,
        finalPoll: null,
        isSetBehaviourCompleted: false,
        overRuleText: ""
    };

    componentDidMount(): void {
        if(this.props.poll.config.id){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.poll.config.id}`).on('value', (snapshot: any) => {
                if(snapshot && snapshot.val()){
                    this.setState({finalPoll:snapshot.val()})
                }
            });
        }

        if(this.props.poll.config){
            let config = this.props.poll.config;
            this.setState({
                question: Util.decodeCharacters(config.question),
                selectedDeck: config.options,
                selectedDeckName: config.selectedDeckName ? config.selectedDeckName: "POKER"
            });

            switch (config.selectedDeckName) {
                case "POKER":   this.setState({pokerDeck: config.options}); break;
                case "ROTI":    this.setState({rotiDeck: config.options}); break;
                case "123":     this.setState({_123Deck: config.options}); break;
                case "ABC":     this.setState({abcDeck: config.options}); break;
                case "CLOUD":   this.setState({cloud: config.options}); break;
                case "COLLECT_VOTE":   this.setState({collectVote: config.options}); break;
                case "TEAM_RADAR":   this.setState({radarDeck: config.options}); break;
                case "FREE_POKER":   this.setState({freePoker: config.options}); break;
            }
        }
    }

    deletePoll = () => {
        firebase.database().ref(`rooms/${this.props.room}/pollList/`).update( {[this.props.id]: null}).then();
    };

    getDeckTitle = (deck: string) => {
        switch (deck) {
            case "POKER": return Translator.translate("Planning Poker (Fibonacci Cards)", this.props.locale, "VOTING");
            case "ROTI":  return Translator.translate("ROTI (Return On Time Invested)", this.props.locale, "VOTING");
            case "123":   return Translator.translate("Multiple choice 123..", this.props.locale, "VOTING");
            case "ABC":   return Translator.translate("Multiple choice ABC..", this.props.locale, "VOTING");
            case "CLOUD":   return Translator.translate("Word Cloud", this.props.locale, "VOTING");
            case "COLLECT_VOTE":  return Translator.translate("Collect Votes", this.props.locale, "VOTING");
            case "TEAM_RADAR":   return Translator.translate("Team Radar", this.props.locale, "VOTING");
            case "FREE_POKER":   return Translator.translate("Planning Poker (Free)", this.props.locale, "VOTING");
        }
        return "";
    };

    handleOnDeckChange = (selectedDeckName: "POKER" | "ROTI" | "ABC" | "123" | "CLOUD" | "COLLECT_VOTE" | "FREE_POKER" | "TEAM_RADAR" ) =>{
        this.setState({selectedDeckName: selectedDeckName});
        switch (selectedDeckName) {
            case "POKER":   this.setState({selectedDeck: this.pokerDeck});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(false,false);
                            }, 120);
                            break;
            case "ROTI":    this.setState({selectedDeck: this.rotiDeck});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(true,false);
                            }, 120);
                            break;
            case "123":     this.setState({selectedDeck: this._123Deck});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(false,true);
                            }, 120);
                            break;
            case "ABC":     this.setState({selectedDeck: this.abcDeck});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(false,true);
                            }, 120);
                            break;
            case "CLOUD":     this.setState({selectedDeck: []});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(false,true);
                            }, 120);
                            break;
            case "COLLECT_VOTE":
                            this.setState({selectedDeck: []});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(true,false);
                            }, 120);
                            break;
            case "FREE_POKER":     this.setState({selectedDeck: []});
                            setTimeout(()=>  {
                                this.setPollConfigBehaviour(false,false);
                            }, 120);
                            break;
            case "TEAM_RADAR":     this.setState({selectedDeck: this.radarDeck});
                setTimeout(()=>  {
                    this.setPollConfigBehaviour(false,false);
                }, 120);
                break;
        }
        setTimeout(()=>  this.onPollChange(), 100);

    };

    setPollConfigBehaviour = (isVotingAnonymous: boolean, isRealtimePreview: any)=> {
        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/config/`).update( {isVotingAnonymous: isVotingAnonymous}).then(()=> {});
        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/config/`).update( {isRealtimePreview: isRealtimePreview}).then(()=> {});
    };

    handleOnQuestionChange = (e: any) => {
        this.setState({question: e.target.value});
        setTimeout(()=>  this.onPollChange(), 100);
    };

    onPollChange  = () => {
        if(this.state.selectedDeck){
            let encodedOptions = this.state.selectedDeck.map((opt: any)=>{
                opt.label = Util.encodeCharacters(opt.label, true);
                opt.description = Util.encodeCharacters(opt.description, true);
                return opt;
            });

            let pollData = {
                config: {
                    timer: 0,
                    isVotingAnonymous: this.props.poll.config.isVotingAnonymous,
                    isRealtimePreview: this.props.poll.config.isRealtimePreview,
                    hasSuperVotingPower: this.props.poll.config.hasSuperVotingPower,
                    pollStatus: "CREATED",
                    totalVoters: Object.keys(this.props.guestList ? this.props.guestList : {} ).length,
                    options: encodedOptions,
                    question: Util.encodeCharacters(this.state.question),
                    selectedDeckName: this.state.selectedDeckName
                },
            };
            firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/`).update( pollData).then(()=> {});
        }
    };

    runPoll = () => {
            let pollId = moment().valueOf();
            const selectedDeck = this.state.selectedDeck || [];
            let encodedOptions = selectedDeck.map((opt: any)=>{
                opt.label = opt.label ? Util.encodeCharacters(opt.label): "";
                opt.description = opt.description ? Util.encodeCharacters(opt.description): "";
                return opt;
            });
            this.setState({isPollRunning: true, pollId: pollId});

            let pollData = {
                config: {
                    timer: 0,
                    isVotingAnonymous: this.props.poll.config.isVotingAnonymous,
                    isRealtimePreview: this.props.poll.config.isRealtimePreview,
                    hasSuperVotingPower: this.props.poll.config.hasSuperVotingPower,
                    pollStatus: "RUNNING",
                    totalVoters: Object.keys(this.props.guestList ? this.props.guestList : {} ).length,
                    options: encodedOptions,
                    id: pollId,
                    question: Util.encodeCharacters(this.state.question),
                    selectedDeckName: this.state.selectedDeckName
                },
            };

        firebase.database().ref(`rooms/${this.props.room}/polls/${pollId}`).on('value', (snapshot: any) => {
            if(snapshot && snapshot.val()){
                this.setState({finalPoll:snapshot.val()})
            }
        });


        firebase.database().ref(`rooms/${this.props.room}/polls/${pollId}`).update( pollData).then(()=> {
                firebase.analytics().logEvent("create-poll", {room: this.props.room, type: this.state.selectedDeckName});
        });

            firebase.database().ref(`rooms/${this.props.room}/messages/${pollId}`).update({
                    text: Util.encodeCharacters(this.state.question),
                    type: "POLL",
                    timestamp: parseInt(pollId),
                    data: pollData
                }
            ).then();

        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/`).update( pollData).then(()=> {});
    };


    finishPoll = () => {

        firebase.database().ref(`rooms/${this.props.room}/polls/${this.state.pollId}/config/`).update(
            {pollStatus: "FINISHED"}
        ).then();

        firebase.database().ref(`rooms/${this.props.room}/messages/${this.state.pollId}/data/config/`).update({
            pollStatus: "FINISHED"
        }).then();

        if(this.state.finalPoll && this.state.finalPoll.votes){
            firebase.database().ref(`rooms/${this.props.room}/messages/${this.state.pollId}/data/`).update({
                    votes:   this.state.finalPoll.votes
                }
            ).then();
        }

        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/config`).update( {
                pollStatus: "FINISHED"
        }).then(()=> {});
        this.setState({isPollRunning: false});

    };

    showResults = ()=> {
        this.setState(prevState =>({showResults: !prevState.showResults}));
    };

    handleOnVotingAnonymousChange = ()=> {
        localStorage.setItem("teamprove.poll.isVotingAnonymous", (!this.props.poll.config.isVotingAnonymous).toString());
        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/config/`).update(
            {isVotingAnonymous: !this.props.poll.config.isVotingAnonymous}
        ).then();
    };

    handleOnRealtimeChange = ()=> {
        localStorage.setItem("teamprove.poll.isRealtimePreview", (!this.props.poll.config.isRealtimePreview).toString());
        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/config/`).update(
            {isRealtimePreview: !this.props.poll.config.isRealtimePreview}
        ).then();
    };

    submitVote = (vote: any)=> {
        if(this.props.runningPoll !== null && this.props.user !== null){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.runningPoll.config.id}/votes/${this.props.user.id}`).update({
                    vote:  vote,
                    avatar: this.props.user.avatar,
                    name: this.props.user.name,
                    timestamp: moment().valueOf()
                }
            ).then();
        }
    };

    submitVoteRadar = (key: any, vote: any)=> {
        if(this.props.runningPoll !== null && this.props.user !== null){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.runningPoll.config.id}/votes/${this.props.user.id}`).update({
                    avatar: this.props.user.avatar,
                    name: this.props.user.name,
                    timestamp: moment().valueOf()
                }
            ).then(()=>{
                if(this.props.runningPoll !== null && this.props.user !== null){
                    firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.runningPoll.config.id}/votes/${this.props.user.id}/vote/${key}`).update(vote
                    ).then();
                }

            });
        }
    };

    handleUpdateLabelOption =(e: any, index: number)=>{
        let selectedDeck = this.state.selectedDeck;
        let pokerDeck = this.state.pokerDeck;
        let rotiDeck = this.state.rotiDeck;
        let _123Deck = this.state._123Deck;
        let abcDeck = this.state.abcDeck;
        let radarDeck = this.state.radarDeck;

        if(selectedDeck[index]){
            selectedDeck[index].label = e.target.value;
            this.setState({
                selectedDeck: selectedDeck
            });
            switch (this.state.selectedDeckName) {
                case "POKER":   pokerDeck[index].label = e.target.value; this.setState({pokerDeck: pokerDeck}); break;
                case "ROTI":    rotiDeck[index].label = e.target.value; this.setState({rotiDeck: rotiDeck}); break;
                case "123":     _123Deck[index].label = e.target.value; this.setState({_123Deck: _123Deck}); break;
                case "ABC":     abcDeck[index].label = e.target.value; this.setState({abcDeck: abcDeck}); break;
                case "TEAM_RADAR": radarDeck[index].label = e.target.value; this.setState({radarDeck: radarDeck}); break;
            }
        }
        setTimeout(()=>  this.onPollChange(), 100);
    };

    handleCheckChange = ( value: any, index: number)=> {
        let selectedDeck = this.state.selectedDeck;
        let pokerDeck = this.state.pokerDeck;
        let rotiDeck = this.state.rotiDeck;
        let _123Deck = this.state._123Deck;
        let abcDeck = this.state.abcDeck;
        let radarDeck = this.state.radarDeck;


        if(selectedDeck[index]){
            selectedDeck[index].checked = (value === "on");
            this.setState({
                selectedDeck: selectedDeck
            });
            switch (this.state.selectedDeckName) {
                case "POKER":   pokerDeck[index].checked = !pokerDeck[index].checked ; this.setState({pokerDeck: pokerDeck}); break;
                case "ROTI":    rotiDeck[index].checked = !rotiDeck[index].checked ; this.setState({rotiDeck: rotiDeck}); break;
                case "123":     _123Deck[index].checked = !_123Deck[index].checked  ; this.setState({_123Deck: _123Deck}); break;
                case "ABC":     abcDeck[index].checked = !abcDeck[index].checked ; this.setState({abcDeck: abcDeck}); break;
                case "TEAM_RADAR": radarDeck[index].checked = !radarDeck[index].checked ; this.setState({radarDeck: radarDeck}); break;
            }
        }
        setTimeout(()=>  this.onPollChange(), 100);
    };

    handleOnSetPollBehaviour = () => {
      let pollList = this.props.pollList;

      Object.keys(pollList).forEach( (poll: string) => {
          firebase.database().ref(`rooms/${this.props.room}/pollList/${poll}/config/`).update(
              {isRealtimePreview: this.props.poll.config.isRealtimePreview}
          ).then();
          firebase.database().ref(`rooms/${this.props.room}/pollList/${poll}/config/`).update(
              {isVotingAnonymous: this.props.poll.config.isVotingAnonymous}
          ).then();
          firebase.database().ref(`rooms/${this.props.room}/pollList/${poll}/config/`).update(
              {hasSuperVotingPower: this.props.poll.config.hasSuperVotingPower}
          ).then(()=>{
              setTimeout(()=> this.setState({isSetBehaviourCompleted: true}), 200);
              setTimeout(()=> this.setState({isSetBehaviourCompleted: false}), 5000);
          });
      });
    };

    containsDuplicates = (array: Array<string>) => {
        const result = array.some(element => {
            return array.indexOf(element) !== array.lastIndexOf(element);
        });
        return result;
    }

    arePollFieldsCompleted = () => {
        const {props: {  poll }} = this;
        if(poll && poll.config.selectedDeckName === "TEAM_RADAR"){

            let values = poll.config.options.filter(o => o.checked && o.label !== "").map((o)=> o.label );
            return  values.length >= 3 && !this.containsDuplicates(values);
        }
        return true;
    }

    toggleShowResults = () =>{
        if(this.state.finalPoll){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.state.pollId}/config/`).update(
                {isRealtimePreview: !this.state.finalPoll.config.isRealtimePreview}
            ).then();
        }
    };

    toggleShowVoting = () =>{
        if(this.state.finalPoll){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.state.pollId}/config/`).update(
                {isVotingVisible: !this.state.finalPoll.config.isVotingVisible}
            ).then();
        }
    };

    toggleFinialVotingResults = () =>{
        if(this.state.finalPoll){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.state.pollId}/config/`).update(
                {showFinialVotingResults: !this.state.finalPoll.config.showFinialVotingResults}
            ).then();
        }
    };

    handleOverruleVote = (overrule: any) => {
        //{id: overrule.id, label: overrule.label, image: overrule.image}

        firebase.database().ref(`rooms/${this.props.room}/polls/${this.state.pollId}/config/`).update(
            {overRuleVote: overrule}
        ).then();

        firebase.database().ref(`rooms/${this.props.room}/pollList/${this.props.id}/config`).update(
            {overRuleVote: overrule}
        ).then(()=> {});

        firebase.database().ref(`rooms/${this.props.room}/messages/${this.state.pollId}/data/config/`).update(
         {overRuleVote: overrule}
        ).then();
    };

    getVoteCount(): number {
        const {
            state: {  finalPoll }
        } = this;

        let user = this.props.user? this.props.user.id: null;
        let poll = finalPoll || null;
        let totalVotes: any = [];
        if(!user || !poll || !poll.votes || poll.config.selectedDeckName !== "COLLECT_VOTE" ){
            return 0;
        }
        Object.keys(poll.votes).forEach((v:any)=> {
            if(poll && poll.votes[v].vote){
                totalVotes = [...totalVotes,  ...poll.votes[v].vote]
            }
        });
        return totalVotes.length;
    }

    render (){
        const {
            state: { selectedDeckName, isPollRunning,  showResults, finalPoll, question, isSetBehaviourCompleted, selectedDeck, overRuleText},
            props: { locale, poll, guestList, user, id, room }
        } = this;



        let runningPoll = this.props.runningPoll;
        if(runningPoll && runningPoll.config && !runningPoll.config.options)  {
            runningPoll.config.options = []
        }

        let hisVote = Util.findNestedValue(runningPoll,"votes", user.id !== null ? user.id : "", "vote", "id");
        let deckSizeClass = runningPoll !== null && runningPoll.config.options && runningPoll.config.options.length > 6 ? "small-tiles" : "";
        let hisVoteRadar = Util.findNestedValue(runningPoll,"votes", user.id !== null ? user.id : "", "vote");

        //counter collect_votes
        let totalVoters  = runningPoll && (guestList.length > runningPoll.config.totalVoters)? guestList.length: (runningPoll && runningPoll.config.totalVoters || 0);
        let allHaveProposed = runningPoll && runningPoll.votes && Object.keys(runningPoll.votes).length === totalVoters;

        let hasSomeVoteInCollectIdeas = finalPoll && finalPoll.votes;

        return (
            <>
            <Row>
                <Col>
                    <div className={`poll-item ${poll.config.pollStatus === "RUNNING"? "poll-active": ""}`}>
                        <Form>
                            <Form.Row>
                                {finalPoll &&  finalPoll.config.pollStatus === "RUNNING" && (Object.keys(finalPoll.votes || {}).length < finalPoll.config.totalVoters) &&  runningPoll && runningPoll.config.selectedDeckName !== "COLLECT_VOTE" &&
                                    <Button variant="outline-secondary" onClick={this.toggleShowResults} className={"d-block d-sm-block d-md-none sm-100"} style={{margin: "0 5px 5px"}}>
                                        {finalPoll.config.isRealtimePreview ? <i className="fas fa-eye"/>:<i className="fas fa-eye-slash"/>}&nbsp;
                                        {finalPoll.config.isRealtimePreview ? Translator.translate("Results are visible", locale, "VOTING"): Translator.translate("Results are hidden", locale, "VOTING")}
                                    </Button>
                                }

                                {finalPoll &&  finalPoll.config.pollStatus === "RUNNING" &&  runningPoll && runningPoll.config.selectedDeckName === "COLLECT_VOTE" && hasSomeVoteInCollectIdeas && !finalPoll.config.showFinialVotingResults &&
                                    <Button variant="outline-secondary" onClick={this.toggleShowVoting} className={"d-block d-sm-block d-md-none sm-100"} style={{margin: "0 5px 5px"}}>
                                        {finalPoll.config.isVotingVisible ? Translator.translate("Go back to gathering ideas", locale, "VOTING"): Translator.translate("Finish gathering ideas", locale, "VOTING")}
                                    </Button>
                                }


                                {   finalPoll &&
                                    finalPoll.config.pollStatus === "RUNNING" &&
                                    runningPoll &&
                                    runningPoll.config.selectedDeckName === "COLLECT_VOTE" &&
                                    (runningPoll.config.isVotingVisible) &&
                                    runningPoll.votes &&
                                    <Button variant="outline-secondary" onClick={this.toggleFinialVotingResults} className={"d-block d-sm-block d-md-none sm-100"} style={{margin: "0 5px 5px"}}>
                                        {!finalPoll.config.showFinialVotingResults ? Translator.translate("Show results", locale, "VOTING"): Translator.translate("Hide results", locale, "VOTING")}
                                    </Button>

                                }

                                { poll.config.pollStatus !== "RUNNING" &&
                                    <Col  className={"delete-poll-wrapper d-block d-sm-block d-md-none"}>
                                        <Button className={"poll-btn-delete"} variant={"light"} onClick={this.deletePoll} >
                                            <i className="fas fa-times"/>
                                        </Button>
                                    </Col>
                                }
                                <Col className={"d-block d-sm-block d-md-none"}>
                                    {poll && poll.config.pollStatus === "CREATED" &&
                                    <Button
                                        variant={"success"}
                                        disabled={!this.arePollFieldsCompleted()}
                                        onClick={this.runPoll}
                                        style={{float: "right"}}>
                                        <i className="fas fa-play"/>&nbsp;{Translator.translate("Run Poll", locale, "VOTING")}
                                    </Button>
                                    }

                                    {poll &&  poll.config.pollStatus === "RUNNING" &&
                                    <Button variant={"primary"} onClick={this.finishPoll} style={{float: "right"}} className={"sm-100"}>
                                        <i className="fas fa-box"/>&nbsp;{Translator.translate("Close Poll", locale, "VOTING")}
                                    </Button>
                                    }

                                    {poll &&  poll.config.pollStatus === "FINISHED" &&
                                        <Button variant={"secondary"} onClick={this.showResults} style={{float: "right"}}>
                                            <i className="fas fa-chart-bar"/>&nbsp;
                                            {  !showResults ?
                                                Translator.translate("Show Results", locale, "VOTING"):
                                                Translator.translate("Hide Results", locale, "VOTING")
                                            }
                                        </Button>
                                    }
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                { poll.config.pollStatus !== "RUNNING" &&
                                    <Col  className={"delete-poll-wrapper d-none d-sm-none d-md-block"}>
                                        <Button className={"poll-btn-delete"} variant={"light"} onClick={this.deletePoll}>
                                             <i className="fas fa-times"/>
                                        </Button>
                                    </Col>
                                }
                                <Col>
                                    <Form.Row>
                                        { poll.config.pollStatus !== "RUNNING" &&
                                            <Col xs={12} sm={6} md={4} lg={4}>
                                                <Dropdown
                                                    as={ButtonGroup}
                                                    rootCloseEvent="mousedown"
                                                    variant={"light"}
                                                    className={"select-deck-dropdown"}>
                                                    <Dropdown.Toggle id={id} disabled={poll.config.pollStatus !== "CREATED"}>
                                                        <span className={"drop-active-name"}>{this.getDeckTitle(selectedDeckName)}</span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="super-colors">
                                                        <Dropdown.Item eventKey="POKER"  active={selectedDeckName === "POKER"} onClick={()=> this.handleOnDeckChange( "POKER")}>
                                                            {this.getDeckTitle("POKER")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="FREE_POKER"  active={selectedDeckName === "FREE_POKER"} onClick={()=> this.handleOnDeckChange( "FREE_POKER")}>
                                                            {this.getDeckTitle("FREE_POKER")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="ROTI"  active={selectedDeckName === "ROTI"} onClick={()=> this.handleOnDeckChange("ROTI")}>
                                                            {this.getDeckTitle("ROTI")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="123"  active={selectedDeckName === "123"} onClick={()=> this.handleOnDeckChange( "123")}>
                                                            {this.getDeckTitle("123")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="ABC"  active={selectedDeckName === "ABC"} onClick={()=> this.handleOnDeckChange("ABC")}>
                                                            {this.getDeckTitle("ABC")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="CLOUD"  active={selectedDeckName === "CLOUD"} onClick={()=> this.handleOnDeckChange("CLOUD")}>
                                                            {this.getDeckTitle("CLOUD")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="TEAM_RADAR"  active={selectedDeckName === "TEAM_RADAR"} onClick={()=> this.handleOnDeckChange("TEAM_RADAR")}>
                                                            {this.getDeckTitle("TEAM_RADAR")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="COLLECT_VOTE"  active={selectedDeckName === "COLLECT_VOTE"} onClick={()=> this.handleOnDeckChange("COLLECT_VOTE")}>
                                                            {this.getDeckTitle("COLLECT_VOTE")}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        }
                                        { poll.config.pollStatus !== "RUNNING" &&
                                            <Col xs={12} sm={6} md={4} lg={5}>
                                                <div style={{display: "flex"}}>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        disabled={poll.config.pollStatus !== "CREATED"}
                                                        onChange={this.handleOnQuestionChange}
                                                        value={question}
                                                        placeholder = {poll.config.pollStatus ==="CREATED" ? Translator.translate("Formulate a question", locale, "VOTING"): ""}
                                                    />
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        placement={"bottom"}
                                                        rootClose={true}
                                                        rootCloseEvent={'click'}
                                                        overlay={
                                                            <Popover id={`popover-positioned-bottom`}>
                                                                <Popover.Title as="h3">{Translator.translate("Poll behaviour", locale, "VOTING")}</Popover.Title>
                                                                <Popover.Content>
                                                                    <div className={"popup-poll-config-wrapper"}>
                                                                        <Row noGutters>
                                                                            <Form.Group>
                                                                                <Form.Check
                                                                                    type={"checkbox"}
                                                                                    label={Translator.translate("Voting Anonymous", locale, "VOTING")}
                                                                                    checked={poll.config.isVotingAnonymous}
                                                                                    disabled={isPollRunning || poll.config.selectedDeckName === "TEAM_RADAR"}
                                                                                    onChange={this.handleOnVotingAnonymousChange}
                                                                                />
                                                                                <Form.Text className="text-muted">
                                                                                    {Translator.translate("No one can see who voted what", locale, "VOTING")}
                                                                                </Form.Text>
                                                                                <br/>
                                                                                <Form.Check
                                                                                    type={"checkbox"}
                                                                                    label={Translator.translate("Realtime Results", locale, "VOTING")}
                                                                                    checked={poll.config.isRealtimePreview}
                                                                                    disabled={isPollRunning}
                                                                                    onChange={this.handleOnRealtimeChange}
                                                                                />
                                                                                <Form.Text className="text-muted">
                                                                                    {Translator.translate("Allow participants to see realtime results after have voted", locale, "VOTING")}
                                                                                </Form.Text>
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <Row noGutters>
                                                                            <Col style={{padding: "0" , margin: "10px 0 0 0"}}>
                                                                                <Button variant={"outline-secondary"} onClick={this.handleOnSetPollBehaviour}>
                                                                                    {Translator.translate("Set this behaviour to all polls", locale, "VOTING")}
                                                                                </Button>
                                                                                { isSetBehaviourCompleted &&
                                                                                <span className={"behaviour-ready"}>
                                                                                        <i className="fas fa-check"/>
                                                                                    {Translator.translate("Ready", locale, "VOTING")}
                                                                                    </span>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Popover.Content>
                                                            </Popover>
                                                        }
                                                    >
                                                        <div className={"config-wrapper"} style={{pointerEvents: poll.config.pollStatus !== "CREATED" ? "none" : "all"}}>
                                                            <Button className={"poll-btn-config"} variant={"light"} disabled={poll.config.pollStatus !== "CREATED"}>
                                                                <i className="fas fa-cog" />
                                                            </Button>
                                                            { poll && poll.config.isVotingAnonymous &&
                                                            <>
                                                                <i className="attr fas fa-user-secret" data-tip={Translator.translate("Voting Anonymous", locale, "VOTING")}/>
                                                                <ReactTooltip place="top" effect="solid" type="warning" className={"tooltip-orange"} />
                                                            </>
                                                            }
                                                            { poll && poll.config.isRealtimePreview &&
                                                            <>
                                                                <i className="attr fas fa-user-clock" data-tip={Translator.translate("Realtime Results", locale, "VOTING")}/>
                                                                <ReactTooltip place="top" effect="solid" type="warning" className={"tooltip-orange"} />
                                                            </>
                                                            }
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </Col>
                                        }

                                        <Col xs={12} sm={12} md={finalPoll && finalPoll.config.pollStatus === "RUNNING"? 12: 4} lg={finalPoll && finalPoll.config.pollStatus === "RUNNING"? 12: 3} className={"d-none d-sm-none d-md-block"}>

                                            {finalPoll &&  finalPoll.config.pollStatus === "RUNNING" && Object.keys(finalPoll.votes || {}).length < finalPoll.config.totalVoters && runningPoll && runningPoll.config.selectedDeckName !== "COLLECT_VOTE" &&
                                                <Button variant="outline-secondary" onClick={this.toggleShowResults}>
                                                    {finalPoll.config.isRealtimePreview ? <i className="fas fa-eye"/>:<i className="fas fa-eye-slash"/>}&nbsp;
                                                    {finalPoll.config.isRealtimePreview ? Translator.translate("Results are visible", locale, "VOTING"): Translator.translate("Results are hidden", locale, "VOTING")}
                                                </Button>
                                            }

                                            {   finalPoll &&
                                                finalPoll.config.pollStatus === "RUNNING" &&
                                                runningPoll &&
                                                runningPoll.config.selectedDeckName === "COLLECT_VOTE" &&
                                                hasSomeVoteInCollectIdeas &&
                                                !finalPoll.config.showFinialVotingResults &&
                                                <Button variant="outline-secondary" onClick={this.toggleShowVoting} style={{
                                                    marginRight: "10px"
                                                }}>
                                                    {!finalPoll.config.isVotingVisible ? Translator.translate("Finish gathering ideas", locale, "VOTING"): Translator.translate("Go back to gathering ideas", locale, "VOTING")}
                                                </Button>
                                            }

                                            {
                                                finalPoll &&
                                                finalPoll.config.pollStatus === "RUNNING" &&
                                                runningPoll &&
                                                runningPoll.config.selectedDeckName === "COLLECT_VOTE" &&
                                                (runningPoll.config.isVotingVisible) &&
                                                runningPoll.votes &&

                                                <Button variant="outline-secondary" onClick={this.toggleFinialVotingResults} style={{
                                                    marginRight: "10px"
                                                }}>
                                                    {!finalPoll.config.showFinialVotingResults ? Translator.translate("Show results", locale, "VOTING"): Translator.translate("Hide results", locale, "VOTING")}
                                                </Button>

                                            }


                                            {poll && poll.config.pollStatus === "CREATED" &&
                                                <Button
                                                    variant={"success"}
                                                    onClick={this.runPoll}
                                                    style={{float: "right"}}
                                                    disabled={!this.arePollFieldsCompleted()}
                                                >
                                                    <i className="fas fa-play"/>&nbsp;{Translator.translate("Run Poll", locale, "VOTING")}
                                                </Button>
                                            }

                                            {poll &&  poll.config.pollStatus === "RUNNING" &&
                                            <Button variant={"primary"} onClick={this.finishPoll} style={{float: "right"}} >
                                                <i className="fas fa-box"/>&nbsp;{Translator.translate("Close Poll", locale, "VOTING")}
                                            </Button>
                                            }

                                            {poll &&  poll.config.pollStatus === "FINISHED" &&
                                                <Button variant={"secondary"} onClick={this.showResults} style={{float: "right"}}>
                                                    <i className="fas fa-chart-bar"/>&nbsp;
                                                    {  !showResults ?
                                                        Translator.translate("Show Results", locale, "VOTING"):
                                                        Translator.translate("Hide Results", locale, "VOTING")
                                                    }
                                                </Button>
                                            }
                                        </Col>
                                    </Form.Row>
                                    { finalPoll &&  (finalPoll.config.pollStatus === "FINISHED") && finalPoll.config.options && finalPoll.config.selectedDeckName !== "TEAM_RADAR" &&
                                        <Form.Row>
                                            <Col>
                                                <Dropdown
                                                    as={ButtonGroup}
                                                    rootCloseEvent="mousedown"
                                                    variant={"light"}
                                                    className={"select-deck-dropdown"}>
                                                    <Dropdown.Toggle id={id} disabled={poll.config.pollStatus !== "FINISHED"}>
                                                        { finalPoll.config.overRuleVote && finalPoll.config.overRuleVote.image && finalPoll.config.overRuleVote.image !=="" ?
                                                            <>
                                                                <img width={30} src={require(`../../../assets/cards/${finalPoll.config.overRuleVote.image}`).default} alt="" draggable={false}/>&nbsp;
                                                                {finalPoll.config.overRuleVote.label}
                                                            </>
                                                            :
                                                            <span className={"drop-active-name"}>{Translator.translate("Override result (Optional)", locale, "VOTING")}</span>
                                                        }
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="super-colors">
                                                        {finalPoll.config.options.map((opt: any)=>
                                                            <Dropdown.Item
                                                                key={opt.id}
                                                                eventKey={opt.id}
                                                                active={finalPoll.config.overRuleVote && opt.id === finalPoll.config.overRuleVote.id}
                                                                onClick={()=> this.handleOverruleVote(opt)}
                                                            >
                                                                {opt.image !== "" && <img width={40} src={require(`../../../assets/cards/${opt.image}`).default} alt="" draggable={false}/>}
                                                                {opt.label}
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        </Form.Row>
                                    }
                                    { finalPoll && (finalPoll.config.pollStatus === "FINISHED") && !finalPoll.config.options && finalPoll.config.selectedDeckName === "FREE_POKER" &&
                                        <Form.Row>
                                            <Col>
                                                <InputGroup>
                                                    <InputGroup.Append>
                                                        <div style={{
                                                            borderRadius: "5px",
                                                            border: "1px solid #ced4da",
                                                            marginRight: "10px",
                                                            display: "flex",
                                                            padding: "0 10px",
                                                            color: "#495057",
                                                            background: "#e9ecef",
                                                            minWidth: "60px"
                                                        }}>
                                                            <span style={{ margin: "auto" }}>{Util.decodeCharacters(finalPoll.config.overRuleVote)}</span>
                                                        </div>
                                                    </InputGroup.Append>
                                                    <FormControl
                                                        type={"text"}
                                                        value={overRuleText}
                                                        onKeyPress={(e: any)=> {
                                                            if(e.key === 'Enter'){
                                                                this.setState({overRuleText: ""});
                                                                this.handleOverruleVote(Util.encodeCharacters(overRuleText));
                                                            }
                                                        }}
                                                        onChange={(e: any)=> this.setState({overRuleText: e.target.value.substr(0, 4)})}
                                                        placeholder={Translator.translate("Type in the new result", locale, "VOTING")}
                                                        style={{paddingLeft: "30px", borderRadius: "5px 0 0 5px"}}
                                                    />
                                                    <InputGroup.Append style={{position: "absolute", zIndex: 100, top: "10px", left: "80px"}}>
                                                        <Badge variant={ ((4 - overRuleText.length) > 0) ? "success": "danger"}>
                                                            {4 - overRuleText.length}
                                                        </Badge>
                                                    </InputGroup.Append>
                                                    <InputGroup.Append>
                                                        <Button
                                                            variant={"primary"}
                                                            onClick={()=>{
                                                                this.setState({overRuleText: ""});
                                                                this.handleOverruleVote(Util.encodeCharacters(overRuleText));
                                                            }}
                                                        >
                                                            <span>{Translator.translate("Override result", locale, "VOTING")}</span>
                                                        </Button>
                                                    </InputGroup.Append>
                                                </InputGroup>

                                            </Col>
                                        </Form.Row>
                                    }
                                </Col>
                            </Form.Row>
                        </Form>
                    </div>
                </Col>
            </Row>

            { (selectedDeckName === "123" || selectedDeckName === "ABC") && poll.config.pollStatus === "CREATED" && poll.config.options && selectedDeck &&
                <Row noGutters>
                    <Col className = {"preview-deck-wrapper"}>
                {poll.config.options.map( (card: Action, index: number) =>
                        <div key={index+"-"+selectedDeckName}
                             className={`card-preview ${!card.checked? "no-selected": ""}`}
                        >
                            { index > 1 &&
                                <input type="checkbox"
                                       onChange={(e: any)=> this.handleCheckChange(e.target.value, index)}
                                       value={card.checked ? "on": "off"}
                                       checked={card.checked}
                                />
                            }
                            {card.image !== "" &&
                                <img
                                    onClick={() => index > 1 ? this.handleCheckChange(card.checked? "on" : "off", index): {}}
                                    src={require(`../../../assets/cards/${card.image}`).default} alt="" draggable={false}/>
                            }
                            <input type="text"
                                   className={"form-control"}
                                   disabled={isPollRunning}
                                   placeholder={Translator.translate("Define label", locale, "VOTING")}
                                   value={selectedDeck[index] ? Util.decodeCharacters(selectedDeck[index].label, true): ""}
                                   onChange={(e: any)=> this.handleUpdateLabelOption(e, index)}/>
                        </div>)}
                    </Col>
                </Row>
            }

            { (selectedDeckName === "TEAM_RADAR") && poll.config.pollStatus === "CREATED" && poll.config.options && selectedDeck &&
                <Row noGutters>
                    <Col className = {"preview-deck-wrapper"}>
                        {poll.config.options.map( (card: Action, index: number) =>
                            <div key={index+"-"+selectedDeckName}
                                 className={`card-preview ${!card.checked? "no-selected": ""}`}
                            >

                                <input type="text"
                                       className={"form-control"}
                                       disabled={isPollRunning}
                                       placeholder={Translator.translate("Define label", locale, "VOTING")}
                                       value={selectedDeck[index] ? Util.decodeCharacters(selectedDeck[index].label, true): ""}
                                       onChange={(e: any)=> this.handleUpdateLabelOption(e, index)}/>
                            </div>)}
                    </Col>
                </Row>
            }

            {runningPoll && runningPoll.config.id === poll.config.id &&
            runningPoll.config.selectedDeckName !== "CLOUD" &&
            runningPoll.config.selectedDeckName !== "COLLECT_VOTE" &&
            runningPoll.config.selectedDeckName !== "FREE_POKER" &&
            runningPoll.config.selectedDeckName !== "TEAM_RADAR" &&
                <Row className={"results-poll-wrapper"}>
                    <Col>
                        <Row noGutters>
                            <Col className={"voting-wrapper"}>
                                {runningPoll && runningPoll.config.question !== "" &&
                                <Row>
                                    <Col>
                                        <h5 className={"question"}>{Util.decodeCharacters(runningPoll.config.question || "")}</h5>
                                    </Col>
                                </Row>
                                }
                                <Row className = {`voting-deck-wrapper ${deckSizeClass}`} noGutters>
                                    {runningPoll && runningPoll.config.options.filter(o => o.checked).map( (card: Action, index: number) =>
                                        <div key={index} className={`voting-card ${ hisVote === card.id? "active": "" }` } onClick={()=> this.submitVote({id: card.id, label: card.label, sortValue: card.sortValue})}>
                                            <img
                                                alt=""
                                                draggable={false}
                                                src={require(`../../../assets/cards/${card.image}`).default}
                                                style={{
                                                    height: card.label === ""? "calc(100% - 5px)": "calc(100% - 30px)",
                                                    padding: card.label === ""? "3px": "5px"
                                                }}
                                            />
                                            { card.label !== "" && <label>{Util.decodeCharacters(card.label)}</label> }
                                        </div>
                                    )}

                                </Row>
                            </Col>
                        </Row>
                        { Object.keys(runningPoll.votes ? runningPoll.votes : {} ).length > 0 && <br/> }
                        <VotingResult poll={runningPoll} user={user} locale={locale} guestList={guestList}/>
                    </Col>
                </Row>
            }

            {runningPoll && runningPoll.config.id === poll.config.id &&
                runningPoll.config.selectedDeckName === "FREE_POKER" &&
               <>
                   <div style={{
                       borderRadius: "0 0 5px 5px",
                       padding: "15px 10px",
                       border: "1px solid #e6e6e6",
                       marginBottom: "15px"
                   }}>
                       <FreePoker
                           locale={locale}
                           poll={runningPoll}
                           user={user}
                           room={room}
                       />
                   </div>
                   <VotingResult poll={runningPoll} user={user} locale={locale} guestList={guestList}/>
               </>
            }

            {runningPoll && runningPoll.config.id === poll.config.id &&
                runningPoll.config.selectedDeckName === "CLOUD" &&
                <Row className={"results-poll-wrapper"}>
                    <Col>
                        <Row noGutters>
                            <Col className={"voting-wrapper"}>
                                {runningPoll && runningPoll.config.question !== "" &&
                                <Row>
                                    <Col>
                                        <h4 className={"question"}>{Util.decodeCharacters(runningPoll.config.question || "")}</h4>
                                    </Col>
                                </Row>
                                }
                            </Col>
                        </Row>
                        <PollCloud
                            user={user}
                            locale={locale}
                            room={room}
                            guestList={guestList}
                            poll={finalPoll} />
                    </Col>
                </Row>
            }

            {runningPoll && runningPoll.config.id === poll.config.id &&
                runningPoll.config.selectedDeckName === "COLLECT_VOTE" &&
                <Row className={"results-poll-wrapper"}>
                    <Col>
                        <Row noGutters>
                            <Col className={"voting-wrapper"}>
                                {runningPoll && runningPoll.config.question !== "" &&
                                    <Row>
                                        <Col>
                                            <h4 className={"question"}>{Util.decodeCharacters(runningPoll.config.question || "")}</h4>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                        <PollCollectVote
                            user={user}
                            locale={locale}
                            room={room}
                            guestList={guestList}
                            poll={finalPoll} />
                    </Col>
                </Row>
            }

            {runningPoll && runningPoll.config.id === poll.config.id &&
                runningPoll.config.selectedDeckName === "TEAM_RADAR" &&
                <Row className={"results-poll-wrapper"}>
                    <Col>
                        <Row noGutters>
                            <Col className={"voting-wrapper"}>
                                {runningPoll && runningPoll.config.question !== "" &&
                                    <Row>
                                        <Col>
                                            <h5 className={"question"}>{Util.decodeCharacters(runningPoll.config.question || "")}</h5>
                                        </Col>
                                    </Row>
                                }
                                <Row className = {`voting-deck-wrapper ${deckSizeClass}`} noGutters>
                                    {runningPoll && runningPoll.config.options.filter(o => o.checked && o.label !== "").map( (card: Action, index: number) =>
                                        <div
                                            key={index}
                                            className={`voting-card ${(hisVoteRadar && hisVoteRadar[card.id] && hisVoteRadar[card.id].score > 0) ? "slider-voted": ""}` }

                                        >

                                            { card.label !== "" &&
                                                <label>
                                                    {Util.decodeCharacters(card.label)}&nbsp;
                                                    {(hisVoteRadar && hisVoteRadar[card.id] && hisVoteRadar[card.id].score > 0) ?
                                                        <i style={{color: "#28a745", float: "right"}} className="fas fa-check-circle"/>:
                                                        <i style={{color: "#fa5462", float: "right"}} className="fas fa-exclamation-circle"/>}
                                                </label>
                                            }
                                            <input
                                                type="range"
                                                min="0"
                                                max="5"
                                                style={{height: "20px"}}
                                                value={hisVoteRadar && hisVoteRadar[card.id] ? hisVoteRadar[card.id].score : 0}
                                                onChange={(event: any) => this.submitVoteRadar(card.id,{label: card.label, sortValue: card.sortValue, score: event.target.value})}/>
                                        </div>
                                    )}
                                </Row>
                                <Row>
                                    <Col>
                                        <TeamRadar
                                            user={user}
                                            locale={locale}
                                            room={room}
                                            guestList={guestList}
                                            poll={finalPoll} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }

            {showResults &&
                <Row className={"results-poll-wrapper"}>
                    <Col>
                        { poll &&   poll.config.selectedDeckName !== "CLOUD" &&
                                    poll.config.selectedDeckName !== "TEAM_RADAR" &&

                            <VotingResult poll={finalPoll} user={user} locale={locale} guestList={guestList}/>}

                        { poll && poll.config.selectedDeckName === "CLOUD" &&
                            <Cloud poll={finalPoll} user={user} locale={locale} guestList={guestList} room={room}/>}

                        { poll && poll.config.selectedDeckName === "TEAM_RADAR" &&
                            <TeamRadar
                                user={user}
                                locale={locale}
                                room={room}
                                guestList={guestList}
                                poll={finalPoll} />
                        }
                    </Col>
                </Row>
            }

        </>
        );
    }
}
