import React, {Component} from 'react';
import { Col, Container,  Nav, Navbar, Row} from "react-bootstrap";
import "./dashboard.scss";
import Feedback from "../feedback/Feedback";
import Rooms from "../rooms/Rooms";
import Recovery from "../recovery/Recovery";

type Props = {
    collapsed: boolean;
    toggled: boolean;
    handleToggleSidebar: Function;
    handleCollapsedChange: Function;
    dashboard: string;
};

type State = {

};

class Dashboard extends Component<Props, State> {
    dashboardDispatcher = ( dashboard: string)=> {
        switch (dashboard) {
            case "feedback": return <Feedback/>;
            case "rooms": return <Rooms/>;
            case "recovery": return <Recovery/>;
            default: return <div>Dashboard not found</div>
        }
    };

    render() {
        const {
            props: {toggled, handleToggleSidebar, dashboard}
        }= this;

        return (
            <main className={"dashboard-main-wrapper"}>
                <Container fluid style={{padding: "0 0 0 0", height: "100%"}}>
                    <Row noGutters className={"h-100"}>
                        <Col className={"dashboard-viewer"}>
                            <Row noGutters>
                                <Col className={"navbar-wrapper"}>
                                    <Navbar bg="light" variant="light">
                                        <Navbar.Toggle aria-controls="global-navbar-nav" />
                                        <Navbar.Collapse id="global-navbar-nav">
                                            <Nav className="mr-auto">
                                                {!toggled &&
                                                    <Nav.Link className="d-block d-sm-block d-md-none">
                                                        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                                                            <i className="fas fa-bars"/>
                                                        </div>
                                                    </Nav.Link>
                                                }
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </Col>
                            </Row>
                            <Row noGutters style={{height: "100%"}}>
                                <Col style={{display: "flex", flexDirection:"column", paddingBottom: "50px" }}>
                                    {this.dashboardDispatcher( dashboard)}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}

export default Dashboard;
