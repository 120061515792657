import React, {Component} from 'react';
import './deck.scss';
import {Action,  Guest, LocaleType} from "../../../types/Types";
import ActionCard from "../actionCard/actionCard";
import {Row} from "react-bootstrap";
import Util from "../../util/Util";

type Props = {
    room: string | null;
    locale: LocaleType;
    user: Guest;
    isCollapsed: boolean;
}

class Deck extends Component<Props, {}> {
    render() {
        const {props:{ room, user, locale, isCollapsed}}= this;
        const deck: Array<Action> = [
            {
                label: "Coffee",
                image: Util.imageActionCardDispatcher("COFFEE"),
                description: "",
                id: "COFFEE",
                sortValue: 1,
                checked: true,
            },
            {
                label: "I'll be back",
                image:  Util.imageActionCardDispatcher("BE_BACK"),
                description: "",
                id: "BE_BACK",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Let's focus",
                image:  Util.imageActionCardDispatcher("ELMO"),
                description: "",
                id: "ELMO",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Yes, and...",
                image:  Util.imageActionCardDispatcher("FEEDBACK"),
                description: "",
                id: "FEEDBACK",
                sortValue: 1,
                checked: true,
            },
            {
                label: "I have to leave",
                image:   Util.imageActionCardDispatcher("LEAVE"),
                description: "",
                id: "LEAVE",
                sortValue: 1,
                checked: true,

            },
            {
                label: "Turn on Webcams",
                image:  Util.imageActionCardDispatcher("WEB_CAM"),
                description: "",
                id: "WEB_CAM",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Time's up",
                image:  Util.imageActionCardDispatcher("TIME_UP"),
                description: "",
                id: "TIME_UP",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Please, Mute",
                image:  Util.imageActionCardDispatcher("MUTE"),
                description: "",
                id: "MUTE",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Show the screen",
                image:  Util.imageActionCardDispatcher("NO_SCREEN"),
                description: "",
                id: "NO_SCREEN",
                sortValue: 1,
                checked: true,
            },
            {
                label: "I can't hear you",
                image:  Util.imageActionCardDispatcher("NO_AUDIO"),
                description: "",
                id: "NO_AUDIO",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Yes",
                image:  Util.imageActionCardDispatcher("YES"),
                description: "",
                id: "YES",
                sortValue: 1,
                checked: true,
            },
            {
                label: "No",
                image:  Util.imageActionCardDispatcher("NO"),
                description: "",
                id: "NO",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Heart",
                image:  Util.imageActionCardDispatcher("HEART"),
                description: "",
                id: "HEART",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Bravo",
                image:  Util.imageActionCardDispatcher("BRAVO"),
                description: "",
                id: "BRAVO",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Party",
                image:  Util.imageActionCardDispatcher("PARTY"),
                description: "",
                id: "PARTY",
                sortValue: 1,
                checked: true,
            },
            {
                label: "Lol",
                image:  Util.imageActionCardDispatcher("LOL"),
                description: "",
                id: "LOL",
                sortValue: 1,
                checked: true,
            },
        ];

        return (
            <Row className="deck-wrapper" noGutters>
                {deck.map((card: Action, index: number) =>
                    <ActionCard key={index} activeCard={user.activeCard} room={room} user={user} locale={locale} isCollapsed={isCollapsed} {...card}/>
                )}
            </Row>
        );
    }
}

export default Deck;
