import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import {Container, Row, Col, Button} from "react-bootstrap";

type Props = {
    room: string,
    user: string,
}

type State = {
    image: string | null,
    isStreaming: boolean;
}

class Webcam extends Component<Props, State> {
    private interval:any = setInterval(()=>{});
    private  imageCapture: any;

    state: State = {
        image: "",
        isStreaming: false
    };


    streamCamVideo = () => {
        let constraints = { audio: true, video: { width: 100, height: 100 } };
        this.setState({isStreaming: true});

       navigator.mediaDevices
            .getUserMedia(constraints)
            .then((mediaStream) =>{
                let video = document.querySelector("video");
                if(video){

                    const track = mediaStream.getVideoTracks()[0];
                    // @ts-ignore
                    this.imageCapture = new ImageCapture(track);
                    this.interval = setInterval(()=> this.grabFrame(), 200);

                    video.srcObject = mediaStream;
                    video.onloadedmetadata = function(e) {
                        if(video){
                            video.play();
                        }
                    };

                }

            })
            .catch(function(err) {
                console.log(err.name + ": " + err.message);
            }); // always check for errors at the end.
    };

    grabFrame = () => {
        this.imageCapture.takePhoto()
            .then((blob:any) => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    let base64data = reader.result;
                    firebase.database()
                        .ref(`rooms/${this.props.room}/users/${this.props.user}`)
                        .update({stream: base64data }
                        ).then(()=> {})
                }
            })
            .catch((error: any) => console.log(error));
    };

    stopStreaming = ()=>{
        this.setState({isStreaming: false});
        clearInterval(this.interval);
    };

    render() {
        const {
            state: {isStreaming},
            props: {
                room, user
            }
        } = this;
        return (
            <Container style={{
                border: "1px solid #e6e6e6",
                borderRadius: "5px",
                display: "flex",
                margin: "50px auto",
                flexDirection: "column",
                maxWidth: "500px"
            }}>
                <br/>
                <Row>
                    <Col>
                        Room: {room} <br/>
                        User: {user}
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <div style={{
                                width: "300px",
                                height: "300px",
                                display: "flex",
                                overflow: "hidden",
                                borderRadius: "100%",

                                background: "black",
                                margin: "50px auto",
                            }}>
                            <video
                                width={"100%"}
                                height={"299px"}
                                autoPlay={true}
                                style={{
                                    margin: "auto"
                                }}
                                id="videoElement"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            variant={!isStreaming ? "success" : "danger"}
                            style={{margin: "auto",  display: "flex"}}
                            onClick={isStreaming ? this.stopStreaming: this.streamCamVideo}>{isStreaming? "Stop": "Start"} Streaming
                        </Button>
                    </Col>
                </Row>
                <br/>

            </Container>
        );
    }
}

export default Webcam;


