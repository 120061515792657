import React, {Component} from 'react';
import {Guest, LocaleType} from "../../../types/Types";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import Translator from "../../util/Locale";
import firebase from 'firebase/app';
import 'firebase/database';
import './logoutModal.scss';
import FeedbackModal from "../feedback/FeedbackModal";
import Util from "../../util/Util";

type Props = {
    onHide: Function;
    locale: LocaleType;
    show: boolean;
    room: string;
    roomName: string,
    user: Guest;
    sendFeedback: Function;
};

type State = {
    modalFeedbackShow: boolean;
}

class LogoutModal extends Component<Props, State> {

    state: State = {
        modalFeedbackShow: false
    };

    handleOnHide = () => {
        this.props.onHide();
    };

    handleDeleteSession = () => {
        localStorage.removeItem("teamprove.userName");
        localStorage.removeItem("teamprove.avatar");
        localStorage.removeItem("teamprove.mood");
        //remove users
        firebase.database().ref(`rooms`).update({[this.props.room]: null }
        ).then();

        firebase.analytics().logEvent("delete_session", {"room": this.props.room});
    };

    handleLogoutAdmin = () => {
        localStorage.removeItem("teamprove.userName");
        localStorage.removeItem("teamprove.avatar");
        localStorage.removeItem("teamprove.mood");
            firebase.database().ref(`rooms/${this.props.room}/users/${this.props.user.id}`).update({isLogin: false}
        ).then(()=>{
                window.location.href = `/`;
            });
        firebase.analytics().logEvent("admin_logout", {"room": this.props.room});
    };

    downloadRecoveryLink = () => {
        const {
            props: {user, room, roomName, locale}
        } = this;
        Util.downloadRecoveryLink(user, room, roomName, locale );
    };

    render() {
        const {
            props: {locale, show , user, sendFeedback },
            state: { modalFeedbackShow}
        } = this;
        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"logout-wrapper"}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("Logout", locale, "FEEDBACK")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Container>
                            <Row>
                                <Col className={"text-description"}>
                                    <h3>
                                        {Translator.translate("Hey", locale, "FEEDBACK")}&nbsp;{user.name},&nbsp;
                                        {Translator.translate("leaving now?", locale, "FEEDBACK")}
                                    </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="option-tile">
                                        <span>
                                            {Translator.translate("Click here to logout and keep this current session open, you will still be the owner", locale, "FEEDBACK")}
                                        </span>
                                        <Button
                                            style={{
                                                margin: "5px 10px 20px 10px",
                                                borderRadius: "5px"
                                            }}
                                            variant="outline-success"
                                            onClick={this.downloadRecoveryLink}>
                                            <i className="fas fa-sign-out-alt"/>&nbsp; {Translator.translate("Download session access", locale, "LIST")}
                                        </Button>



                                        <Button  onClick={this.handleLogoutAdmin}>
                                            <i className="fas fa-sign-out-alt"/>&nbsp; {Translator.translate("Logout", locale, "FEEDBACK")}
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="option-tile">
                                        <span>
                                            <p>{Translator.translate("Click here to reset the session, all users including yourself will be removed.", locale, "FEEDBACK")}</p>
                                            <p>{Translator.translate("CAUTION: this action is irreversible!", locale, "FEEDBACK")}</p>
                                        </span>

                                        <Button variant={"secondary"} onClick={this.handleDeleteSession}>
                                            <i className="far fa-trash-alt"/>&nbsp;
                                            {Translator.translate("Delete session", locale, "FEEDBACK")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"light"} onClick={() => { this.setState({modalFeedbackShow: true})}} style={{margin: "auto auto auto 0"}}>
                        <i className="fas fa-comment-dots"/>&nbsp; {Translator.translate("Give us feedback!", locale, "FEEDBACK")}
                    </Button>
                    <FeedbackModal
                        onHide={() => { this.setState({modalFeedbackShow: false})}}
                        onSubmit={sendFeedback}
                        onQuit={() => { this.setState({modalFeedbackShow: false})}}
                        show={modalFeedbackShow}
                        locale={locale}
                        user={user}
                        isTriggerByUser={true}/>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LogoutModal;
