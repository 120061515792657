import React, {Component} from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    Container, Form, Alert
} from "react-bootstrap";
import Translator from "../../util/Locale";
import {Guest, LocaleType} from "../../../types/Types";
import './feedbackModal.scss';

type Props = {
    onHide: Function,
    onSubmit: Function,
    onQuit: Function
    show: boolean,
    locale: LocaleType,
    user: Guest,
    isTriggerByUser: boolean,
};

type State = {
    feedback: string,
    score: number,
    contact: string,
    sent: boolean
}

class FeedbackModal extends Component<Props, State> {

    state: State = {
        feedback: "",
        score: 0,
        contact: "",
        sent: false,
    };

    handleOnFeedbackChange = (e: any)=> {
         let feedback = e.target.value;
        this.setState({feedback: feedback});
    };

    handleOnContactChange = (e: any)=> {
        let contact = e.target.value;
        this.setState({contact: contact});
    };

    handleOnScoreChange = (score: any)=> {
        this.setState({score: score});
    };

    handleOnSubmit = () => {
        let finalFeedback = this.state.feedback.trim() === "" && this.state.contact.trim() === "" ? "":
            `${this.state.feedback} ${this.state.contact.trim() !== "" ? `\n ${this.state.contact}`: ""}`;

        if(finalFeedback !== "" || this.state.score !== 0){
            this.setState({sent: true});
            setTimeout(()=> {
                this.props.onSubmit(this.state.score, finalFeedback );
                this.setState({feedback: "", score: 0, sent: false, contact: ""});
                this.props.onHide();
            }, 3500);
        }else{
            if(!this.props.isTriggerByUser){
                //it means he/she wants to leave
                localStorage.removeItem("teamprove.userName");
                localStorage.removeItem("teamprove.avatar");
                localStorage.removeItem("teamprove.mood");
                this.props.onQuit();
            }
            this.props.onHide();
        }
    };

    handleOnHide = () => {
        this.setState({feedback: "", score: 0, contact: "", sent: false,});
        this.props.onHide();
    };

    render() {
        const {
            props: {locale, show, isTriggerByUser, user  },
            state:{feedback, score, contact, sent}
        } = this;

        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"feedback-wrapper"}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("User satisfaction", locale, "FEEDBACK")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Container>
                            <Row style={{overflow: "hidden", maxHeight: `${sent ? "1000px": "0"}`, transition: "all 0.5s linear" }}>
                                <Col>
                                    {sent &&
                                        <div className={"countDownWrapper"}>
                                            <div className={"countDown"} />
                                        </div>
                                    }
                                    <Alert variant={"success"} className={"alert-feedback"}>
                                        <Alert.Heading>
                                            {Translator.translate("Thank you for the feedback", locale, "FEEDBACK")}
                                        </Alert.Heading>
                                        <hr />
                                        <p>
                                            {Translator.translate("Your feedback has been submitted successfully", locale, "FEEDBACK")}
                                        </p>
                                    </Alert>

                                </Col>
                            </Row>
                            <Row>
                                <Col className={"text-description"}>
                                    <h3>
                                        {Translator.translate("Hi", locale, "FEEDBACK")}
                                        &nbsp;{user.name},&nbsp;
                                        {!isTriggerByUser && Translator.translate("Before you logout!", locale, "FEEDBACK")}
                                    </h3>
                                    <p>
                                        {Translator.translate("We want to keep improving Teamprove Meet for you, so we would love to hear your feedback.", locale, "FEEDBACK")}
                                    </p>
                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col>
                                   <Row noGutters className={"score-wrapper"}>
                                       <Col>
                                           <div className={`score-tile ${score === 5 ? "active": ""}`} onClick ={()=> this.setState({score: 5})}>
                                               <img src={require("../../../assets/feedback/excellent.svg").default} alt="" onClick={()=> this.handleOnScoreChange(5)} draggable={false}/>
                                               <span>
                                                   {Translator.translate("Excellent", locale, "FEEDBACK")}
                                               </span>
                                           </div>
                                       </Col>
                                       <Col>
                                           <div className={`score-tile ${score === 4 ? "active": ""}`} onClick ={()=> this.setState({score: 4})}>
                                               <img src={require("../../../assets/feedback/good.svg").default} alt="" onClick={()=> this.handleOnScoreChange(4)} draggable={false}/>
                                               <span>
                                                   {Translator.translate("Good", locale, "FEEDBACK")}
                                               </span>
                                           </div>
                                       </Col>
                                       <Col>
                                           <div className={`score-tile ${score === 3 ? "active": ""}`} onClick ={()=> this.setState({score: 3})}>
                                               <img src={require("../../../assets/feedback/regular.svg").default} alt="" onClick={()=> this.handleOnScoreChange(3)} draggable={false}/>
                                               <span>
                                                   {Translator.translate("Regular", locale, "FEEDBACK")}
                                               </span>
                                           </div>
                                       </Col>
                                       <Col>
                                           <div className={`score-tile ${score === 2 ? "active": ""}`} onClick ={()=> this.setState({score: 2})}>
                                               <img src={require("../../../assets/feedback/sufficient.svg").default} alt="" onClick={()=> this.handleOnScoreChange(2)} draggable={false}/>
                                               <span>
                                                   {Translator.translate("Sufficient", locale, "FEEDBACK")}
                                               </span>
                                           </div>
                                       </Col>
                                       <Col>
                                           <div className={`score-tile ${score === 1 ? "active": ""}`} onClick ={()=> this.setState({score: 1})} draggable={false}>
                                               <img src={require("../../../assets/feedback/bad.svg").default} alt="" onClick={()=> this.handleOnScoreChange(1)} draggable={false}/>
                                               <span>
                                                   {Translator.translate("Bad", locale, "FEEDBACK")}
                                               </span>
                                           </div>
                                       </Col>
                                   </Row>
                                    <img
                                        className={`tile-selector ${score ===0 ? "inactive": ""}`}
                                        src={require("../../../assets/feedback/selector.svg").default}
                                        alt=""
                                        style={{right: `calc(-10px + (20% * ${score -1} + ${score === 0 ? "-25px": "0px"}))`}}
                                        draggable={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="feedback">
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            onChange={this.handleOnFeedbackChange}
                                            value={feedback}
                                            placeholder={Translator.translate("How was your experience using Teamprove Meet?", locale, "FEEDBACK")}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="contact">
                                        <Form.Label column={false}>
                                            {Translator.translate("feedback_contact", locale, "FEEDBACK")}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={this.handleOnContactChange}
                                            value={contact}
                                            placeholder={Translator.translate("feedback_contact_placeholder", locale, "FEEDBACK")}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleOnSubmit} disabled={sent || (isTriggerByUser && !(feedback !== "" || score !== 0)) }>
                        {!isTriggerByUser &&
                            <i className="fas fa-sign-out-alt"/>
                        }
                        {isTriggerByUser &&
                            <i className="far fa-paper-plane"/>
                        }&nbsp;

                        {!isTriggerByUser && Translator.translate("Logout", locale, "FEEDBACK")}
                        {isTriggerByUser && Translator.translate("Send feedback", locale, "FEEDBACK")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default FeedbackModal;
