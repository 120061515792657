import React, {Component} from 'react';
import {Guest, LocaleType} from "../../../types/Types";
import {Col, Container, Modal, Row, Button, Badge} from "react-bootstrap";
import Translator from "../../util/Locale";
import './linkSharing.scss';

import firebase from 'firebase/app';
import 'firebase/database';
import Util from "../../util/Util";

type Props = {
    user: Guest | null;
    locale: LocaleType;
    room: string;
    show: boolean;
    onHide: Function;
    roomName: string
}

type State = {
    isCopied: boolean;
    hasCopied: boolean;
    isDownloaded: boolean;
}

class LinkSharing extends Component<Props, State> {

    state: State = {
        isCopied: false,
        hasCopied: false,
        isDownloaded: false
    };

    handleOnHide = () => {
        this.props.onHide();
    };

    copyToClipboard = (link: string) => {
        const el = document.createElement('textarea');
        el.value = link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        const self = this;

        self.setState({isCopied: true, hasCopied: true},
            ()=> setTimeout(() => {
                self.setState({isCopied: false});
                if (self.props.user && self.props.user.mood && this.state.isDownloaded) {
                    self.props.onHide();
                }
            }, 1500)
        );


    };

    setMood = (mood: string) => {
        if (this.props.user && mood !== this.props.user.mood) {
            localStorage.setItem("teamprove.mood", mood);
        } else {
            localStorage.removeItem("teamprove.mood");
        }

        if (this.props.user) {
            firebase.database().ref(`rooms/${this.props.room}/users/${this.props.user.id}`).update({mood: mood === this.props.user.mood ? null : mood}
            ).then();
            firebase.analytics().logEvent("set_mood", {"mood": mood});
        }

        if (this.state.hasCopied && this.state.isDownloaded) {
            this.props.onHide();
        }


    };

    handleOnDownload = ()=>{
        const {
            props: {user, room, roomName, locale}
        } = this;
        Util.downloadRecoveryLink(user, room, roomName, locale );

        this.setState({
            isDownloaded: true
        });

        if (this.props.user && this.props.user.mood && this.state.hasCopied) {
            this.props.onHide();
        }
    };


    render() {
        const url = new URL(window.location.href);
        const hostname = url.hostname;
        const port = url.port;
        const protocol = url.protocol;
        const room = this.props.room;
        let linkSharing = `${protocol}//${hostname}${port ? `:${port}` : ""}/join/${room.replace(/ /g, "%20")}`;
        const {props: {show, locale, user}, state: {isCopied}} = this;

        return (
            <Modal
                show={show}
                size="lg"
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"link-sharing-wrapper"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("Add others", locale, "LIST")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row noGutters>
                            <Col className={"step-sharing"}>
                                <div><span>1</span></div>
                            </Col>
                            <Col style={{paddingRight: "15px"}}>
                                <Row>
                                    <Col>
                                        <h5>
                                            <Badge variant="success" className={"new-badge"} >
                                                {Translator.translate("New", locale, "LIST")}
                                            </Badge> {Translator.translate("Store this item to recover access to your session.", locale, "LIST")}
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button variant="outline-success"  className={"download-blob"} onClick={this.handleOnDownload}>
                                            <i className="fas fa-download"/> {Translator.translate("Download session access", locale, "LIST")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr/>
                        <Row noGutters>
                            <Col className={"step-sharing"}>
                               <div> <span>2</span></div>
                            </Col>
                            <Col style={{paddingRight: "15px"}}>
                                <Row>
                                    <Col>
                                        <h5>
                                            {Translator.translate("Share this info with people you want in the meeting", locale, "LIST")}
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={`link-sharing ${isCopied ? "copied" : ""}`}
                                             onClick={() => this.copyToClipboard(linkSharing)}>
                                                <input type="text" className={"form-control"} readOnly={true}
                                                   value={linkSharing}/>
                                                {!isCopied ?
                                                    <i className="fas fa-clone"/> :
                                                    <span className={"i-copied"}>
                                                        <i className="fas fa-check"/> {Translator.translate("Copied!", locale, "TOOLBAR")}
                                                    </span>
                                                }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        {user && !user.mood &&
                            <>
                                <hr/>
                                <Row noGutters>
                                    <Col className={"step-sharing"}>
                                       <div><span>3</span></div>
                                    </Col>
                                    <Col style={{paddingRight: "15px"}}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <h5>
                                                        {Translator.translate("How are you feeling?", locale, "TOOLBAR")}
                                                    </h5>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className={"moodModal-wrapper"}>

                                                    <Row noGutters className={"score-wrapper"} style={{maxWidth: "250px"}}>
                                                        <Col>
                                                            <div
                                                                className={`score-tile ${user && user.mood === "5.svg" ? "active" : ""}`}
                                                                onClick={() => this.setMood("5.svg")}>
                                                                <img src={require("../../../assets/feedback/excellent.svg").default}
                                                                     alt=""
                                                                     draggable={false}/>
                                                                <img
                                                                    className={`tile-selector`}
                                                                    src={require("../../../assets/feedback/selector.svg").default}
                                                                    alt=""
                                                                    draggable={false}
                                                                />
                                                                <span>{Translator.translate("exited", locale, "MOOD_CHART")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div
                                                                className={`score-tile ${user && user.mood === "4.svg" ? "active" : ""}`}
                                                                onClick={() => this.setMood("4.svg")}>
                                                                <img src={require("../../../assets/feedback/good.svg").default} alt=""
                                                                     draggable={false}/>
                                                                <img
                                                                    className={`tile-selector`}
                                                                    src={require("../../../assets/feedback/selector.svg").default}
                                                                    alt=""
                                                                    draggable={false}
                                                                />
                                                                <span>{Translator.translate("happy", locale, "MOOD_CHART")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div
                                                                className={`score-tile ${user && user.mood === "3.svg" ? "active" : ""}`}
                                                                onClick={() => this.setMood("3.svg")}>
                                                                <img src={require("../../../assets/feedback/regular.svg").default}
                                                                     alt=""
                                                                     draggable={false}/>
                                                                <img
                                                                    className={`tile-selector`}
                                                                    src={require("../../../assets/feedback/selector.svg").default}
                                                                    alt=""
                                                                    draggable={false}
                                                                />
                                                                <span>{Translator.translate("puzzled", locale, "MOOD_CHART")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div
                                                                className={`score-tile ${user && user.mood === "2.svg" ? "active" : ""}`}
                                                                onClick={() => this.setMood("2.svg")}>
                                                                <img src={require("../../../assets/feedback/sufficient.svg").default}
                                                                     alt=""
                                                                     draggable={false}/>
                                                                <img
                                                                    className={`tile-selector`}
                                                                    src={require("../../../assets/feedback/selector.svg").default}
                                                                    alt=""
                                                                    draggable={false}
                                                                />
                                                                <span>{Translator.translate("bored", locale, "MOOD_CHART")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div
                                                                className={`score-tile ${user && user.mood === "1.svg" ? "active" : ""}`}
                                                                onClick={() => this.setMood("1.svg")}>
                                                                <img src={require("../../../assets/feedback/bad.svg").default} alt=""
                                                                     draggable={false}/>
                                                                <img
                                                                    className={`tile-selector`}
                                                                    src={require("../../../assets/feedback/selector.svg").default}
                                                                    alt=""
                                                                    draggable={false}
                                                                />
                                                                <span>{Translator.translate("angry", locale, "MOOD_CHART")}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                    </Container>
                </Modal.Body>
            </Modal>

        );
    }
}

export default LinkSharing;
