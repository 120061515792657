import React, {Component} from 'react';
import Translator from "../../util/Locale";
import {Button, ButtonGroup, Col, Container, DropdownButton, Nav, Row} from "react-bootstrap";
import {LocaleType} from "../../../types/Types";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import './home.scss';

type Props = {

};
type State = {
    locale: LocaleType;

};


let browserLocale = navigator.language.toUpperCase().substr(0, 2);
let locale = (browserLocale === "DE" || browserLocale === "EN" || browserLocale === "ES") ? browserLocale : "EN";

class Home extends Component<Props, State> {

    state: State = {
        locale: localStorage.getItem("teamprove.locale") as LocaleType || locale,
    };
    handleOnLocaleChange = (locale: LocaleType) => {
        this.setState({
            locale: locale
        }, ()=> localStorage.setItem("teamprove.locale", locale));
    };


    render() {

        const videos: any = {
            "one": {
                "DE": "https://www.teamprove.de/images/video/teamprove_meet/what_is_Teamprove_Meet_en.mp4",
                "EN": "https://www.teamprove.de/images/video/teamprove_meet/what_is_Teamprove_Meet_en.mp4",
                "ES": "https://www.teamprove.de/images/video/teamprove_meet/what_is_Teamprove_Meet_en.mp4",
            },
            "two": {
                "DE": "https://www.teamprove.de/images/video/teamprove_meet/how_to_use_Teamprove_Meet_en.mp4",
                "EN": "https://www.teamprove.de/images/video/teamprove_meet/how_to_use_Teamprove_Meet_en.mp4",
                "ES": "https://www.teamprove.de/images/video/teamprove_meet/how_to_use_Teamprove_Meet_en.mp4",
            },
            "three": {
                "DE": "https://www.teamprove.de/images/video/teamprove_meet/Teamprove_Meet_for_moderators_en.mp4",
                "EN": "https://www.teamprove.de/images/video/teamprove_meet/Teamprove_Meet_for_moderators_en.mp4",
                "ES": "https://www.teamprove.de/images/video/teamprove_meet/Teamprove_Meet_for_moderators_en.mp4",
            }

        };

        const locale = this.state.locale;

        return (
            <Container fluid className={"home-wrapper"}>
                <Row noGutters>
                    <Col>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Navbar className="login-navbar">
                                        <Navbar.Brand href={"https://www.teamprove.de/"} target={"_blank"}>
                                            <img
                                                alt=""
                                                src={require("../../../assets/logoCondensed.svg").default}
                                                width="30"
                                                height="30"
                                                className="d-inline-block align-top"
                                            />
                                        </Navbar.Brand>
                                        <Navbar.Collapse className="justify-content-end">
                                            <Nav>
                                                <DropdownButton
                                                    alignRight
                                                    as={ButtonGroup}
                                                    id={"locale"}
                                                    title={locale}
                                                    className={"btn-locale"}
                                                >
                                                    <Dropdown.Item eventKey="1" onClick={() => this.handleOnLocaleChange("DE")}>
                                                        <img className={"flag"} src={require(`../../../assets/flag/DE.svg`).default} alt=""/>
                                                        {Translator.translate("German", locale, "LOGIN")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item eventKey="2" onClick={() => this.handleOnLocaleChange("EN")}>
                                                        <img className={"flag"} src={require(`../../../assets/flag/EN.svg`).default} alt=""/>
                                                        {Translator.translate("English", locale, "LOGIN")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item eventKey="3" onClick={() => this.handleOnLocaleChange("ES")}>
                                                        <img className={"flag"} src={require(`../../../assets/flag/ES.svg`).default} alt=""/>
                                                        {Translator.translate("Spanish", locale, "LOGIN")}
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Container>
                        <Row>
                            <Col>
                                <h2>
                                    {Translator.translate("How does Teamprove Meet work?", locale, "HOME")}
                                </h2>
                                <br/>
                                <h4>
                                    {Translator.translate("Quite simple:", locale, "HOME")}
                                </h4>
                                <ul>
                                    <li>
                                        {Translator.translate("Teamprove Meet is a companion for your video or phone call. It doesn't replace a good video conferencing software. It just helps to make online meetings more effective and fun.", locale, "HOME")}
                                    </li>
                                    <li>
                                        {Translator.translate("You don't need to register, schedule or pay.", locale, "HOME")}
                                    </li>
                                    <li>
                                        {Translator.translate("Just enter your name or an alias, give your session a name and invite other participants.", locale, "HOME")}
                                    </li>
                                    <li>
                                        {Translator.translate("You can create as many sessions as you want, whenever you need them.", locale, "HOME")}
                                    </li>
                                </ul>

                                <br/>
                                <h4>
                                    {Translator.translate("About your data:", locale, "HOME")}
                                </h4>
                                <ul>
                                    <li>
                                        {Translator.translate("Your sessions will live for 21 days. Then, they will be deleted - this is to protect your data.", locale, "HOME")}
                                    </li>
                                    <li>
                                        {Translator.translate("Beyond that period, we will not store any of your data, with exception of anonymized Google Analytics data which we use to improve our service.", locale, "HOME")}
                                    </li>
                                    <li>
                                        {Translator.translate("And of course, we don't share your data with anyone!", locale, "HOME")}
                                    </li>

                                </ul>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs={12} lg={4}>
                                <video width="100%"  controls>
                                    <source src={videos.one[locale]} type="video/mp4" />
                                </video>
                            </Col>
                            <Col xs={12} lg={4}>
                                <video width="100%"   controls>
                                    <source src={videos.two[locale]} type="video/mp4" />
                                </video>
                            </Col>
                            <Col xs={12} lg={4}>
                                <video width="100%"  controls>
                                    <source src={videos.three[locale]} type="video/mp4" />
                                </video>
                            </Col>
                        </Row>

                        <Row className={"btn-start"}>
                            <Col>
                                <Button href={"/"}>
                                    {Translator.translate("Start now!", locale, "HOME")}
                                </Button>
                            </Col>
                        </Row>
                </Container>



            </Container>
        );
    }
}

export default Home;
