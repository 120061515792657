import React, {Component} from 'react';
import Translator from "../../util/Locale";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Action, Guest, LocaleType, PollType} from "../../../types/Types";
import "./votingModal.scss";
import Util from "../../util/Util";
import firebase from 'firebase/app';
import 'firebase/database';
import VotingResult from "../votingResult/VotingResult";
import FreePoker from "../freePoker/FreePoker";
import PollCloud from "../pollCloud/PollCloud";
import PollTeamRadar from "../pollTeamRadar/PollTeamRadar";
import PollCollectVote from "../pollCollectVote/PollCollectVote";
const moment = require('moment');

type Props ={
    show:boolean;
    locale: LocaleType;
    onHide: Function;
    poll: PollType | null;
    room: string | null;
    user: Guest | null;
    guestList: any,
};

type State ={
    freePoker: string;
};


class VotingModal extends Component<Props, State> {
    private inputCloudTag = React.createRef<any>();
    state: State = {
        freePoker: ""
    };

    handleOnHide = () => {
        this.props.onHide();
    };

    submitVote = (vote: any)=> {
        if(this.props.poll !== null && this.props.user !== null){
            firebase.database().ref(`rooms/${this.props.room}/polls/${this.props.poll.config.id}/votes/${this.props.user.id}`).update({
                        vote:  vote,
                        avatar: this.props.user.avatar,
                        name: this.props.user.name,
                        timestamp: moment().valueOf()
                }
            ).then();
        }
    };

    render() {
        let {
            props: {show, locale, poll = null, user, guestList, room},
        } = this;

        if(poll === null || user === null){
            return null;
        }

        if(poll && poll.config && !poll.config.options)  {
            poll.config.options = []
        }

        let hisVote = Util.findNestedValue(poll,"votes", user.id !== null ? user.id : "", "vote", "id");
        let deckSizeClass = poll.config.options.length > 6 ? "small-tiles" : "";

        return (
            <Modal
                show={show}
                backdrop={"static"}
                style={{display: "contents"}}
                centered
                onHide={this.handleOnHide}
                className={"voting-wrapper"}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {Translator.translate("Voting Time!", locale, "VOTING")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {poll.config.question !== "" &&
                            <>
                                <Row>
                                    <Col>
                                        <h5 className={"question"}>{Util.decodeCharacters(poll.config.question || "")}</h5>
                                    </Col>
                                </Row>
                            </>
                        }


                        {   poll.config.selectedDeckName !== "CLOUD" &&
                            poll.config.selectedDeckName !== "COLLECT_VOTE" &&
                            poll.config.selectedDeckName !== "FREE_POKER" &&
                            poll.config.selectedDeckName !== "TEAM_RADAR" &&

                            <Row className = {`voting-deck-wrapper ${deckSizeClass}`} noGutters>
                                 {poll.config.options.filter(o => o.checked).map( (card: Action, index: number) =>
                                     <div key={index} className={`voting-card ${ hisVote === card.id? "active": "" }` } onClick={()=> this.submitVote({id: card.id, label: card.label, sortValue: card.sortValue})}>
                                         <img
                                             alt=""
                                             draggable={false}
                                             src={require(`../../../assets/cards/${card.image}`).default}
                                             style={{
                                                 height: card.label === ""? "calc(100% - 5px)": "calc(100% - 30px)",
                                                 padding: card.label === ""? "3px": "5px"
                                             }}
                                         />
                                         { card.label !== "" && <label>{Util.decodeCharacters(card.label)}</label> }
                                    </div>
                                 )}
                            </Row>
                        }

                        {poll.config.selectedDeckName === "FREE_POKER" &&
                            <FreePoker
                                locale={locale}
                                poll={poll}
                                user={user}
                                room={room}
                            />
                        }

                        {poll.config.selectedDeckName === "CLOUD" &&
                            <PollCloud
                                user={user}
                                locale={locale}
                                room={room}
                                guestList={guestList}
                                poll={poll} />
                        }

                        {poll.config.selectedDeckName === "COLLECT_VOTE" &&
                            <PollCollectVote
                                user={user}
                                locale={locale}
                                room={room}
                                guestList={guestList}
                                poll={poll} />
                        }

                        {poll.config.selectedDeckName === "TEAM_RADAR" &&
                            <PollTeamRadar
                                user={user}
                                locale={locale}
                                room={room}
                                guestList={guestList}
                                poll={poll} />
                        }

                        {   poll.config.selectedDeckName !== "CLOUD" &&
                            poll.config.selectedDeckName !== "TEAM_RADAR" &&
                            poll.config.selectedDeckName !== "COLLECT_VOTE" &&
                            <VotingResult poll={poll} user={user} locale={locale} guestList={guestList}/>}

                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

export default VotingModal;
