import React, {Component} from 'react';
import {CardType, Guest, LocaleType} from "../../../types/Types";
import {Row, Col} from "react-bootstrap";
import './actionCard.scss';
import Translator from "../../util/Locale";
import firebase from 'firebase/app';
import 'firebase/database';
import ReactTooltip from 'react-tooltip';
import * as moment from 'moment';

type Props = {
    activeCard: string | null,
    label: string,
    image: string,
    description: string,
    id: CardType,
    room: string | null,
    user: Guest,
    locale: LocaleType;
    isCollapsed: boolean;
};

class ActionCard extends Component<Props, {}> {
    isCardActive = () => {
        return this.props.activeCard === this.props.id ? " active": ""
    };

    setActionCard(card: string | null) {
        let { props:{ room, user } } = this;
        firebase.analytics().logEvent("set_action_card", {"card": card});
        firebase.database().ref(`rooms/${room}/users/${user.id}`).update({activeCard: card, lastUpdate: moment.now() }).then();
    }

    render() {
        let { props:{  locale, label, image, id, activeCard, isCollapsed } } = this;
        return (
            <Col xs={isCollapsed ? 12: 6} className={`action-card-wrapper ${this.isCardActive()} ${isCollapsed? "condensed": ""}`}>
                <div className={"action-card"} onClick={()=> this.setActionCard(id === activeCard? null: id )} id={id}>
                    <Row noGutters>
                        <Col className={"image-action-wrapper"}>
                           {!isCollapsed && <img src={image} alt="" draggable={false}/>}
                            {isCollapsed &&
                                <>
                                    <img src={image} alt=""
                                            data-tip={Translator.translate(label, locale, "DECK")}
                                            data-for={`${id}-tooltip`}
                                            draggable={false}
                                    />
                                    <ReactTooltip place="right"  effect="solid"  type="warning" className={"tooltip-orange"} id={`${id}-tooltip`}/>
                                </>
                            }

                        </Col>
                    </Row>
                    <Row noGutters>
                        <Col>
                            <span className="action-label"> {Translator.translate(label, locale, "DECK")} </span>
                        </Col>
                    </Row>
                </div>
            </Col>
        );
    }
}

export default ActionCard;
