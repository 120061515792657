import React, {Component} from 'react';
import {Guest, LocaleType} from "../../../types/Types";
import Util from "../../util/Util";
import './guestCard.scss';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import Translator from "../../util/Locale";
import firebase from 'firebase/app';
import 'firebase/database';
import ReactResizeDetector from 'react-resize-detector';
import moment from 'moment';

type Props = {
    participant: Guest,
    room: string | null,
    isManagedByAdmin: boolean | null;
    locale: LocaleType;
    globalUserName: string | null,
    tileSize: string,
    cardPriority: Array<any>
};


type State = {
    modalShow: boolean;
    width: any;
}

class GuestCard extends Component<Props, State> {

    state: State = {
        modalShow: false,
        width: 0
    };

    removeActionCardFromUser = (userId: string)=> {
        if(this.props.isManagedByAdmin){
            firebase.database().ref(`rooms/${this.props.room}/users/${userId}`).update({activeCard: null });
            firebase.analytics().logEvent("remove_action_card_from_user");
        }
    };

    removeUser = (userId: string| null)=> {
        if(this.props.isManagedByAdmin && userId !== null ){
           /* firebase.database().ref(`rooms/${this.props.room}/users/${userId}`).update({isLogin: false });*/
            firebase.database().ref(`rooms/${this.props.room}/users/${userId}`).update({
                isLogin: false,
                isRemovedByAdmin: true
            }).then(()=>{
                if(userId){
                    firebase.database().ref(`rooms/${this.props.room}/users`).update({[userId]: null });
                }
            });
            firebase.analytics().logEvent("remove_user");
        }
    };

    render() {
        const {
            participant: {id, name, avatar= null, mood = null, activeCard, isAdmin, isCardSecret = null, stream},
            isManagedByAdmin,
            locale,
            globalUserName,
            tileSize,
            cardPriority
        } = this.props;

        let priority = cardPriority.findIndex((g: any) => g.id === id);



        const {modalShow} = this.state;

        let tooltipDeleteMessage = (locale === "DE") ? `${name} ${Translator.translate("Delete", locale, "LIST")}?`
            :  `${Translator.translate("Delete", locale, "LIST")} ${name}?`;


        return (
            <div className={`tile-wrapper ${tileSize}`}>
                <div className={`guest-card-wrapper ${activeCard ? "active": ""} ${!isManagedByAdmin ? "isParticipant": ""} `}>
                    { mood && <img className="mood" src={require( `../../../assets/mood/${mood}`).default} alt="" draggable={false}/> }

                    { name === globalUserName &&
                            <div className={"you"}>{Translator.translate("You", locale, "LIST")}</div>
                    }


                    { (priority > -1) &&
                        <div className="oder-badge" style={{
                            right: this.state.width < 40 ? "5px" : mood ? "36px": "5px",
                            top: this.state.width < 40 ? "50px" : mood ? "5px": "5px"
                        }}>{priority + 1}</div>
                    }

                    <div className={"front-face"}>
                        <ReactResizeDetector handleWidth onResize={(width: any) => this.setState({width: width})}>
                            {({ width }: any) =>
                                <div key ="wrapper" className={`avatar-wrapper ${name === globalUserName? "your-avatar": ""}`} style={{height: `${width} !important` }}>
                                    { isAdmin && <img className="crown" src={require( `../../../assets/icon/crown.svg`).default} alt="" draggable={false}/>}
                                    { !isAdmin && moment().format('M') === "12" && <img className="santa" src={require( `../../../assets/icon/santa.svg`).default} alt="" draggable={false}/>}
                                    { stream? <img className={`avatar-image`}
                                        src={stream}
                                        style={{
                                        height: width,
                                        objectFit: "cover"
                                    }}
                                        alt="" draggable={false}/> :
                                        <img className={`avatar-image`} src={require( `../../../assets/avatar/${!avatar? "1.svg": avatar}`).default} alt="" draggable={false}/>
                                    }
                                </div>
                            }
                        </ReactResizeDetector>

                        <div className={"action-card"}>
                            { activeCard && !isCardSecret && <img  className="active-action-card" src={ Util.imageActionCardDispatcher(activeCard)} alt="" draggable={false}/> }
                            { activeCard && isCardSecret && <img  className="active-action-card" src={ require('../../../assets/cards/incognito.svg').default} alt="" draggable={false}/> }
                        </div>

                        <div className="name">
                            {Util.decodeCharacters(name)}
                        </div>
                    </div>
                    { !isAdmin &&
                        <div className={"back-face"}>
                            <Button onClick={()=> this.setState({modalShow: true})}
                                    data-tip={tooltipDeleteMessage}
                                    data-for='user'
                            >
                                <i className="fas fa-user-times"/>
                            </Button>
                            {activeCard &&
                                <>
                                    <Button onClick={()=> this.removeActionCardFromUser(id)}
                                            data-tip={Translator.translate("Remove Action Card", locale, "LIST")}
                                            data-for='card'
                                    >
                                        <i className="fas fa-minus-square"/>
                                    </Button>
                                    <ReactTooltip place="bottom"  effect="solid"  type="warning" className={"tooltip-orange"} id='card'/>
                                </>
                            }
                            <ReactTooltip place="bottom"  effect="solid"  type="warning" className={"tooltip-orange"} id='user'/>

                        </div>
                    }
                </div>
                <Modal show={modalShow} onHide={() => this.setState({modalShow: false})} >
                    <Modal.Body>
                        <Container>
                            <Row className="show-grid">
                                <Col>
                                    <p>
                                        {Translator.translate(tooltipDeleteMessage, locale, "TOOLBAR")}
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={() => this.setState({modalShow: false})}> {Translator.translate("Cancel", locale, "TOOLBAR")}</Button>
                        <Button variant="danger" onClick={()=> this.removeUser(id)}> {Translator.translate("Confirm", locale, "TOOLBAR")}</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default GuestCard;
