import React, {Component} from 'react';
import './guestList.scss';
import GuestCard from "../guestCard/GuestCard";
import {Guest, LocaleType, SortBy} from "../../../types/Types";
import StackAreaChart from "../stackAreaChart/StackAreaChart";
import ReactResizeDetector from 'react-resize-detector';
import Chat from "../chat/Chat";

type Props = {
    room: string | null;
    locale: LocaleType;
    user: Guest;
    guestList: any;
    sortedBy: SortBy;
    moodTrend: Array<any>;
    isSidebarOpen: boolean;
    showChat: boolean;
    toggleChat: Function;
}

type State = {
    isStackAreaExpanded: boolean,
    isChatExpanded: boolean;
}

class GuestList extends Component<Props, State> {
    private roomRef = React.createRef<HTMLDivElement>();
    state:State = {
        isStackAreaExpanded: false,
        isChatExpanded: false
    };

    handleExpandChat = ()=>{
        this.setState( prevState => ({isChatExpanded: !prevState.isChatExpanded}));
    };

    getCardPriority = (guestList: any) =>{
        let cardPriority: any = []
        guestList.forEach((g: Guest)=> {
            if(g.activeCard === "FEEDBACK"){
                cardPriority.push({
                    id: g.id,
                    lastUpdate: g.lastUpdate
                });
            }
        });
        return cardPriority.sort((a: any,b: any) => a.lastUpdate - b.lastUpdate);
    }

    render() {
        const {
            props: {room, user, locale, guestList, sortedBy, moodTrend, showChat, toggleChat},
            state:{ isStackAreaExpanded, isChatExpanded}
        } = this;

        const cardPriority: any = this.getCardPriority(guestList);
        const guestListLength = Object.keys(guestList).length;
        const tileSize = guestListLength < 13 ? "medium-tile" : "small-tile";
        let list = Object.keys(guestList).map((participant: any) => guestList[participant]).sort((a: any, b: any) => a.avatar - b.avatar);

        let sortedList: any = sortedBy === "LAST_UPDATE" ?
            list.sort((a: any, b: any) => {
                return b.lastUpdate - a.lastUpdate
            }) : sortedBy === "CREATION" ?
                list.sort((a: any, b: any) => {
                    return a.creation - b.creation
                })
                : list;

        sortedList = sortedList.filter((guest: any) => guest.isLogin !== false);

        return (
            <ReactResizeDetector handleWidth key={"moodchart"}>
                {({ width }: any) =>
                    <div style={{width: "100%", height: "100%", position: "relative"}} ref={this.roomRef} >
                        {room &&
                            <StackAreaChart
                                moodTrend={moodTrend}
                                width={width}
                                height={isStackAreaExpanded ? 120 : 5}
                                onClick={()=> this.setState(prevState => ({isStackAreaExpanded: !prevState.isStackAreaExpanded}))}
                                isExpanded={isStackAreaExpanded}
                                locale={locale}
                                room={room}
                                user={user}
                                guestList={guestList}
                            />
                        }

                        <div className={`guest-list-wrapper ${tileSize}`}
                             style={{
                                 height: isStackAreaExpanded ? "calc(100% - 120px)": "calc(100% - 5px)",
                                 width: "100%",
                                 paddingRight: isChatExpanded && showChat ? "360px": "15px"
                             }}>
                            {sortedList.map((participant: any) =>
                                <GuestCard
                                    key={`${participant.id}-${participant.creation}`}
                                    participant={participant}
                                    isManagedByAdmin={user.isAdmin}
                                    room={room}
                                    locale={locale}
                                    globalUserName={user.id}
                                    tileSize={tileSize}
                                    cardPriority={cardPriority}
                                />)}
                        </div>

                        <Chat guestList={guestList}
                              room={room}
                              locale={locale}
                              showChat={showChat}
                              toggleChat={toggleChat}
                              isChatExpanded={isChatExpanded}
                              expandChat={this.handleExpandChat}
                              user={user}/>
                    </div>
                }
            </ReactResizeDetector>
        );
    }
}

export default GuestList;
