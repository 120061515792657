import React, {Component} from 'react';
import {Guest, LocaleType, PollType} from "../../../types/Types";
import Util from "../../util/Util";
import "./cloud.scss";
import ReactResizeDetector from 'react-resize-detector';
import Translator from "../../util/Locale";
import cloud from 'd3-cloud';


type Props = {
    poll: PollType | null;
    user: Guest | null;
    locale: LocaleType;
    guestList: any,
    room?: string | null;
};
type State = {
    nodes: Array<any>,
    width: number,
    height: number,
    bounce: any,
    loading: boolean,

};

class Cloud extends Component<Props, State> {

    private layout = cloud();
    private nextProps = [];
    private cloudWrapper = React.createRef<HTMLDivElement>();
    private timeout = setTimeout(()=>{}, 0);

    state: State = {
        loading: false,
        nodes: [],
        height: 400,
        width: 400,
        bounce: {
            0: {x: 0, y: 0},
            1: {x: 400, y: 400}
        }
    };

    componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): void {
        if((JSON.stringify(this.getWords(this.props.poll)) !== JSON.stringify(this.getWords(nextProps.poll)))){
            this.layout.stop();
            this.setState({loading: true});
            const w = this.getWords(nextProps.poll);
            this.layout
                .size([this.state.width, this.state.height])
                .words(w)
                .font(()=> "Roboto-Bold")
                .random(()=> 0.45)
                .padding(5)
                .fontSize((d: any) =>  (16 + (this.getFontSizes(w).indexOf(d.size)* 5 )) )
                .rotate((a: any, i: any)=> (i % 2) === 1? 0: 90);
            this.nextProps = this.getWords(nextProps.poll);
            this.layout.start();
        }
    }

    getWords = (poll: any) => {
        if(!poll){
            return [];
        }

        let words: any = [];
        Object.keys(poll.votes || [])
            .forEach(p => {
                let votes = poll.votes[p].vote;
                if(Array.isArray(votes)){
                    votes.forEach((v: any)=> words.push({text: Util.decodeCharacters(v), size: 1}))
                }
            });

        words = words.reduce((acc: any, curr: any) => {
            let findTagIndex = acc.findIndex((item: any) => item.text.toLowerCase() === curr.text.toLowerCase());
            if (findTagIndex === -1) {
                acc.push(curr)
            } else {
                acc[findTagIndex].size += curr.size;
            }
            return acc;
        }, []);

        return words;
    };


    componentDidMount(): void {
        this.setState({loading: true});
        this.buildCloud(400, 400);
    }


    getFontSizes = (w: any) => w.map((f: any) => f.size ).sort().filter((item: any, pos: any, self: any) =>{
        return self.indexOf(item) === pos;
    });

    buildCloud = (width: number, height: number) => {
        const w = this.getWords(this.props.poll);
        this.layout = cloud()
            .size([width, height])
            .words(w)
            .font(()=> "Roboto-Bold")
            .padding(5)
            .random(()=> 0.45)
            .rotate((a: any, i: any)=> (i % 2) === 1? 0: 90)
            .fontSize((d: any) =>  (20 + (this.getFontSizes(w).indexOf(d.size)* 5 )) )
            .on("end", (nodes: any, bounce: any)=> {
                this.setState({
                    nodes: nodes,
                    bounce: bounce? bounce: {
                        0: {x: 0, y: 0},
                        1: {x: width, y: height}
                    },
                    loading: false,
                });
            });
    };

    handleComponentResizing = (width: any, height: any)=>{
        clearTimeout(this.timeout);

        this.timeout = setTimeout(()=>{
            if(width && height){
                this.setState({loading: true});
                const w = this.getWords(this.props.poll);
                this.setState({width: width, height: height}, ()=> {
                    this.layout.stop();
                    this.layout
                        .size([width, height])
                        .words(w)
                        .font(()=> "Roboto-Bold")
                        .padding(5)
                        .random(()=> 0.45)
                        .rotate((a: any, i: any)=> (i % 2) === 1? 0: 90)
                        .fontSize((d: any) =>  (20 + (this.getFontSizes(w).indexOf(d.size)* 5 )) )
                    ;
                    this.layout.start();
                });
            }
        }, 2000);
    };

    render() {
        const {
            props: { poll, locale },
            state: { nodes, width, height, bounce, loading }
        } = this;

        if(!poll || poll.config.selectedDeckName !== "CLOUD"){
            return null;
        }

        if(poll && !poll.votes && poll.config.pollStatus === "FINISHED"){
            return  <div style={{padding: "10px"}}>
                {Translator.translate(`No words so far`, locale, "VOTING")}
            </div>;
        }

        if(poll && !poll.config.isRealtimePreview && poll.config.pollStatus === "RUNNING" ){
           return (
               <div style={{ width: "100%", height: "200px"}} className={"cloud-tag-wrapper"}>
                   {Translator.translate(`You will see the results once this poll is finished`, locale, "VOTING")}
               </div>
           )
        }

        const colors= ["#d4da26", "#ef477a", "#ffc52f", "#0090d0", "#ed1e24", "#f5c33a", "#f5a6c3", "#16aab4", "#662d73"];

        return (
            <ReactResizeDetector handleWidth key={"cloud-tag-wrapper"}
                                 onResize={(w: any, h: any)=> this.handleComponentResizing(Math.floor(w), Math.floor(h) )}>
                {() =>
                    <div style={{ width: "100%"}} key="1" className={"cloud-tag-wrapper"} ref={this.cloudWrapper}>
                        { loading &&
                            <div className={"team-loader small"}>
                                <img src={require('../../../assets/logoCondensed.svg').default} width={20} height={20} alt=""/>
                            </div>
                        }
                        {!loading &&
                            <svg width={width} height={height} viewBox={`${bounce[0].x} ${bounce[0].y} ${bounce[1].x} ${bounce[1].y}`}>
                                {nodes.map((node: any, index: number) =>
                                    <g key={node.text} transform={`translate(${width/2}, ${height/2})`}>
                                        <text
                                            cursor="default"
                                            fill={colors[ index % colors.length  ]}
                                            fontFamily={node.font}
                                            fontStyle="normal"
                                            fontWeight="normal"
                                            textAnchor="middle"
                                            transform={`translate(${[node.x + (bounce[0].x/2), node.y + (bounce[0].y/2)]})rotate(${node.rotate})`}
                                            fontSize={`${node.size}px`}>
                                            {node.text}
                                        </text>
                                    </g>
                                )}
                            </svg>
                        }
                    </div>
                }
            </ReactResizeDetector>
        );
    }
}

export default Cloud;
